export var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["MANAGER"] = "manager";
    FeatureFlag["HELP_CENTER"] = "help_center";
    FeatureFlag["PLAYER_PLAYLIST"] = "playlist";
    FeatureFlag["COMMENTS"] = "comments";
    FeatureFlag["ARTWORK_PROXY"] = "artwork_proxy";
    FeatureFlag["LANGUAGE_SELECTION"] = "language_selection";
    FeatureFlag["PODCAST_SEARCH"] = "podcast_search";
    FeatureFlag["MANAGER_RECORDING"] = "manager_recording";
    FeatureFlag["MANAGER_HOSTING"] = "manager_hosting";
})(FeatureFlag || (FeatureFlag = {}));
