import { Duration } from '@nimey/units';
import { BaseOutput } from './base';
import { IdbStorage } from '@/modules/idb-file-storage/util/idb-storage';
import { IdbFileWriter } from '@/modules/idb-file-storage/util/idb-file-writer';
import { ExternalState } from '../external-state';

const sleep = (duration: Duration) => new Promise(resolve => setTimeout(resolve, +duration));

export class RecorderOutput extends BaseOutput {
  protected initPromise?: Promise<void>;
  protected analyser: AnalyserNode;
  protected destination: MediaStreamAudioDestinationNode;
  mediaRecorder: MediaRecorder;
  protected chunks: Blob[] = [];
  protected writer!: IdbFileWriter;
  protected timeout?: ReturnType<typeof setTimeout>;

  public readonly isRecording = new ExternalState<boolean>(false);

  constructor(
    protected name: string,
    protected audioContext: AudioContext,
    protected idb: IdbStorage,
    public readonly startDate: Date,
  ) {
    super(name, audioContext);
    this.destination = this.audioContext.createMediaStreamDestination();
    this.analyser = this.audioContext.createAnalyser();

    const analyserInput = this.audioContext.createMediaStreamSource(this.destination.stream);
    analyserInput.connect(this.analyser);

    this.mediaRecorder = new MediaRecorder(this.destination.stream);

    this.mediaRecorder.ondataavailable = (e) => {
      this.writer.addChunk(e.data);
      sleep(Duration.seconds(5)).then(() => {
        if(this.mediaRecorder.state === 'recording') this.mediaRecorder.requestData();
      })
    }

    this.mediaRecorder.onstart = (e) => {
      this.isRecording.set(true);
    }

    this.mediaRecorder.onstop = (e) => {
      this.isRecording.set(false)
    }


    this.timeout = setTimeout(() => {
      this.start();
    }, this.startDate.getTime() - Date.now());

    this.init()
  }

  protected async init() {
    this.initPromise = this.initPromise || (async () => {
      this.writer = await this.idb.getFileWriter(this.name);
    })()

    return this.initPromise;
  }

  destroy() {
    if(this.timeout) clearTimeout(this.timeout);
  }
  start() {
    this.mediaRecorder.start();
    setTimeout(() => {
      if (this.mediaRecorder.state === 'recording') this.mediaRecorder.requestData();
    }, +Duration.seconds(5));
  }

  stop() {
    if(this.mediaRecorder.state === 'recording') this.mediaRecorder.stop();
  }

  async connectCanvas(canvas: HTMLCanvasElement) {
    await this.init();
    const canvasContext = canvas.getContext('2d')!;
    const analyser = this.analyser;
    analyser.fftSize = 32;
    let frequencyData = new Uint8Array(analyser.frequencyBinCount);
    const update = () => {
      requestAnimationFrame(update);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      analyser.getByteFrequencyData(frequencyData);

      let barLength = frequencyData.reduce((acc, cur) => acc + cur, 0) / frequencyData.length;
      canvasContext.fillStyle = 'rgb(' + (barLength-50) + ',126,0)';
      canvasContext.fillRect(0, 0, barLength / 255 * canvas.width, canvas.height);
    }

    update();
  }

  getDestination() {
    return this.destination;
  }
}