import { QuestionType, TreeEntry } from "@/modules/global/components/assistant/types";

export const addPodcastAssistantTree : TreeEntry = {
  name: 'add-podcast',
  questions: [
    {
      name: 'hosting_managed_selection',
      label: 'Soll der Podcast bei @nimey podcast gehosted werden oder hast du bereits einen Anbieter?',
      type: QuestionType.SELECTION,
      options: {
        'hosting': 'Hosting',
        'listing': 'Listing'
      }
    }
  ],
  children: [
    {
      condition: (v) => v['add-podcast.hosting_managed_selection'] === 'hosting',
      tree: {
        name: 'hosting',
        questions: [
          {
            name: 'mode',
            label: 'Möchtest du einen vorhandenen Podcast umziehen oder einen neuen erstellen?',
            type: QuestionType.SELECTION,
            options: {
              'relocation': 'Umzug',
              'creation': 'Neuen Podcast erstellen',
            }
          }
        ],
        children: [
          {
            condition: (v) => v['add-podcast.hosting.mode'] === 'relocation',
            tree: {
              name: 'relocation',
              questions: [
                {
                  name: 'feedUrl',
                  label: 'FeedURL?',
                  type: QuestionType.TEXT_INPUT,
                }
              ],
              children: [],
            }
          },
          {
            condition: (v) => v['add-podcast.hosting.mode'] === 'creation',
            tree: {
              name: 'creation',
              questions: [
                {
                  name: 'title',
                  label: 'Wie heißt dein Podcast?',
                  type: QuestionType.TEXT_INPUT,
                }
              ],
              children: [],
            }
          }
        ],
      }
    },
    {
      condition: (v) => v['add-podcast.hosting_managed_selection'] === 'listing',
      tree: {
        name: 'listing',
        questions: [
          {
            name: 'feed_url',
            label: 'Feed URL des podcasts finden?',
            type: QuestionType.FEED_URL
          },
          {
            name: 'claim_ownershop',
            label: 'Möchtest du die Inhaberschaft übernehmen?',
            type: QuestionType.YES_NO
          }
        ],
        children: [],
      }
    },
  ],
}