import { useEffect, useRef, useState } from 'react'
import { CheckProps, CheckStatus } from './main'
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdDone, MdErrorOutline } from 'react-icons/md';
import { Button } from '@nimey/react-ui';

class Warning {
  constructor(public readonly message: string) {}
}

export const CheckSpeaker = (props: CheckProps) => {
  const {check, setCheckStatus} = props;
  const [ manualPass, setManualPass ] = useState<boolean | undefined>(undefined);
  const [ error, setError ] = useState<string>('');
  const [ messages, setMessages ] = useState<Array<string>>([]);
  const [ deviceStatus, setDeviceStatus] = useState<Array<{label: string, passed: boolean}>>([]);
  const started = useRef(false);

  useEffect(() => {
    if(check.status === CheckStatus.RUNNING) {
      if(started.current) return;
      started.current = true;
      const addMesage = (m: string) => setMessages(ms => [...ms, m]);
      const runCheck = async () => {
        await navigator.mediaDevices.getUserMedia({audio: true})
        const devices = (await navigator.mediaDevices.enumerateDevices()).filter(d => d.kind === 'audiooutput');
        addMesage('Zugriff auf Geräte erlaubt')
        if(devices.length === 0) {
          setManualPass(false)
          throw new Warning('Dein Gerät erlaubt nicht die Auflistung von Ausgabegeräten')
        }

        const elem = new Audio();
        if(!('setSinkId' in elem)) {
          setManualPass(false)
          throw new Warning('Dein Gerät erlaubt nicht die Steuerung von Ausgabegeräten')
        }

        setDeviceStatus(devices.map(d => ({label: d.label, passed: true})))
      }

      runCheck()
        .then(() => {
          setCheckStatus(CheckStatus.PASSED)
        })
        .catch((e) => {
          if(e instanceof Warning) {
            setError(e.message)
            return;
          }
          if(e instanceof Error) {
            if(e.name === 'NotAllowedError') {
              setError('Keine Berechtigung für den Zugriff auf deine Geräte');
              setCheckStatus(CheckStatus.FAILED);
              return;
            }
          }
          setError(String(e))
          setCheckStatus(CheckStatus.FAILED);
        })

    }
  })

  return (
    <>
      {check.status === CheckStatus.RUNNING ? <p>Deine Lautsprecher werden getestet</p> : ''}
      <div style={{fontSize: 'smaller', marginLeft: '1em', maxHeight: check.status === CheckStatus.RUNNING ? 1000 : 0, transition: 'max-height .2s ease', overflow: 'hidden'}}>
        {messages.map((m, i) => <p style={{margin: 0}} key={i}>{m}</p>)}
      </div>
      <ul>
        {deviceStatus.map(ds => (
          <li key={ds.label}><FlexRow center spaceBetween>
            <span>{ds.label}</span>
            {ds.passed ? <MdDone /> : <span style={{color: 'red'}}><MdErrorOutline /></span>}
          </FlexRow></li>
        ))}
      </ul>
      <p style={{color: 'red'}}>{error}</p>
      {manualPass === false ? (
        <FlexRow center spaceBetween>
          <p>Dein Gerät erlaubt es nicht zu steuern, über welches Gerät Ton ausgeben werden soll. Möchtest du trotzdem mit diesem Gerät fortfahren?</p>
          <Button onClick={() => {
            setManualPass(true);
            setCheckStatus(CheckStatus.PASSED)
          }}>Weiter</Button>
        </FlexRow>
      ) : ''}
    </>
  )
}