import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { CheckMicrophone } from './microphone';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdDone, MdError, MdOutlinePending, MdOutlinePendingActions, MdPending } from 'react-icons/md';
import { Spinner } from '@/modules/layout/components/spinner';
import { CheckSpeaker } from './speaker';
import { CheckIdb } from './idb';
import { Button } from '@nimey/react-ui';

export enum CheckStatus {
  PASSED = 'passed',
  FAILED = 'failed',
  RUNNING = 'running',
  PENDING = 'pending',
}

export type Check = {
  title: string;
  description: string;
  Component: React.FC<CheckProps>;
  status: CheckStatus
}

export type CheckProps = {
  check: Check,
  setCheckStatus: (s: CheckStatus) => void
}

const defaultChecks: Array<Check> = [
  {
    title: 'Mikrofon',
    description: 'Überprüfung deiner Mikrofone',
    Component: CheckMicrophone,
    status: CheckStatus.PENDING
  },
  {
    title: 'Lautsprecher',
    description: 'Überprüfung deiner Lautsprecher',
    Component: CheckSpeaker,
    status: CheckStatus.PENDING
  },
  {
    title: 'Datenbank',
    description: 'Überprüfung deiner Datenbank',
    Component: CheckIdb,
    status: CheckStatus.PENDING
  },
]


export const CheckRenderer = (props: CheckProps) => {
  const { Component, title, status } = props.check;

  return (
    <div>
      <FlexRow center spaceBetween>
        <strong>{title}</strong>
        <div>
          { status === CheckStatus.FAILED ? (<span style={{color: 'red'}}><MdError /></span>) : '' }
          { status === CheckStatus.PASSED ? (<span style={{color: 'green'}}><MdDone /></span>) : '' }
          { status === CheckStatus.RUNNING ? (<Spinner size={'1em'} />) : '' }
          { status === CheckStatus.PENDING ? (<MdOutlinePending />) : '' }
        </div>
      </FlexRow>
      {status !== CheckStatus.PENDING ? <Component {...props} /> : ''}
    </div>
  );
}

export const SystemCheck = (props: PropsWithChildren) => {
  const [passed, setPassed] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [checks, setChecks] = useState<Array<Check>>(defaultChecks);

  useEffect(() => {
    const isRunning = checks.find(c => c.status === CheckStatus.RUNNING);
    if(isRunning) return;

    for(const check of checks) {
      if(check.status === CheckStatus.PENDING) {
        setChecks(cs => ([...cs.map(c => c === check ? {...c, status: CheckStatus.RUNNING} : c)]))
        return;
      }
    }

    const notPassed = checks.find(c => c.status !== CheckStatus.PASSED);
    if(!notPassed) {
      setPassed(true);
    }
  }, [checks])

  if (hidden) return <>{props.children}</>

  return (
    <div>
      <ul style={{width: 500}}>
        {checks.map(check => (
          <li key={check.title}>
            <CheckRenderer {...{check, setCheckStatus: (s: CheckStatus) => setChecks(cs => ([...cs.map(c => c === check ? {...c, status: s} : c)]))}} />
          </li>
        ))}
      </ul>
      {true ? (
        <FlexRow center spaceBetween>
          <span />
          <Button primary onClick={() => {
            setHidden(true);
          }}>Weiter zur Aufnahme</Button>
        </FlexRow>
      ) : ''}
      
    </div>
  )

}