import { BaseMessage } from './base.js';
export class SdpOfferMessage extends BaseMessage {
    payload;
    constructor(payload) {
        super(payload);
        this.payload = payload;
    }
    get senderId() {
        return this.payload.senderId;
    }
    get senderName() {
        return this.payload.senderName;
    }
    get sdp() {
        return this.payload.sdp;
    }
    get type() {
        return this.payload.type;
    }
}
