import { PropsWithChildren, useContext, useMemo, useState } from 'react';

import { DeviceInputProviderProps } from '@/modules/audio-recorder/components/device-input';
import * as DeviceInput from '@/modules/audio-recorder/components/device-input'

import styles from '../recording.module.scss';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { useOutputList } from '@/modules/audio-recorder/hooks/use-output-list';
import { Modal } from '@/modules/layout/components/modal';
import { MdCompareArrows, MdMic } from 'react-icons/md';
import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import { DeviceInputContext } from '@/modules/audio-recorder/context/device-input-context';
import { ConnectionType } from '../viewer';
import { BaseInput } from '@/modules/audio-recorder/util/input/base';

const ModalContent: typeof InputDeviceRenderer = (props) => {
  const { input } = useContext(DeviceInputContext);
  const outputs = useOutputList()
  const connectedOutputs: BaseOutput[] = useMemo(() => props.connections.filter(([i, o]) => i.getName() === props.name).map(([i, o]) => o), [props.connections])

  if(!input) return <></>

  return (
    <>
      <ul>
        {outputs.map((o, idx) => (
          <li key={`${o.getName()}-${idx}`}>
            <label>
              <input type="checkbox" checked={!!connectedOutputs.find(c => c.getName() === o.getName())} onChange={(e) => {
                if(e.currentTarget.checked) props.addConnection(input, o)
                else props.removeConnection(input, o)
              }} />
              {o.getName()}
            </label>
          </li>
        ))}
      </ul>
    </>
  );
}

export const InputDeviceRenderer = (props: DeviceInputProviderProps & {connections: Array<ConnectionType>, addConnection: (i: BaseInput, o: BaseOutput) => void, removeConnection: (i: BaseInput, o: BaseOutput) => void}) => {
  const {...passProps} = props;
  const [showConnectionModal, setShowConnectionModal] = useState<boolean>(false);
  return (
    <div className={styles.inputRenderer}>
      <DeviceInput.Provider {...passProps}>
        <FlexRow center gap='1em'>
          <div className={styles.icon}><MdMic /></div>
          <div className={styles.content}>
            <FlexRow spaceBetween center className={styles.info}>
              <DeviceInput.Name />
              <button className={styles.connectionModal} onClick={() => setShowConnectionModal(true)}><MdCompareArrows /></button>
            </FlexRow>
            
            <FlexRow center gap='.25em'><div className={styles.mute}><DeviceInput.ToogleMute /></div><DeviceInput.Visualizer width={500} height={5} /></FlexRow>
            <Modal isOpen={showConnectionModal} close={() => setShowConnectionModal(false)}>
              <ModalContent {...props} />
            </Modal>
          </div>
        </FlexRow>
      </DeviceInput.Provider>
    </div>
  );
}