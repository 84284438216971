import { Organization, RecordingSession, User } from '@nimey/podcast-global-entity';
import { createContext } from 'react';

export type RecordingGuest = {
  id: string;
  name: string;
  email: string;
}

export type RecordingEntitiesContextType = {
  org: Organization;
  user: User | RecordingGuest;
  recordingSession: RecordingSession;
  isGuest: boolean;
  sessionToken?: string;
}

// @ts-ignore
export const RecordingEntitiesContext = createContext<RecordingEntitiesContextType>();