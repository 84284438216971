import { useCallback, useContext, useState } from 'react';
import { BackendDispatchContext } from '../context/backend-context';

export const useHostingEpisodeMedia = (orgId?: string, podcastId?: string, episodeId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext);
  const [ buffer, setBuffer ] = useState<ArrayBuffer>();
  const [ dataUrl, setDataUrl ] = useState<string>();

  const load = async (orgId: string, podcastId: string, episodeId: string) => {
    try {
      const result = await asyncActions!.loadHostingEpisodeMedia(orgId, podcastId, episodeId);
      setBuffer(result.response);
      if(result.headers?.get('content-type')) {
        let contentType = result.headers.get('content-type') || '';

        var blob = new Blob( [ result.response ], { type: contentType } );
        const mediaUrl = URL.createObjectURL(blob)
        setDataUrl(mediaUrl);
      }
    } catch(e) {
      console.error(e);
    }
  }

  return {
    load: useCallback(() => orgId && podcastId && episodeId && load(orgId, podcastId, episodeId), [orgId, podcastId, episodeId]),
    save: useCallback((buffer: ArrayBuffer) => orgId && podcastId && episodeId && asyncActions!.saveHostingEpisodeMedia(orgId, podcastId, episodeId, buffer), [orgId, podcastId]),
    buffer,
    dataUrl,
  }
}