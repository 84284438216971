import { ExternalState } from '../external-state';
import { BaseInput } from '../input/base';
import { BaseOutput } from './base';

export class DeviceOutput extends BaseOutput {
  protected initPromise?: Promise<void>;
  protected analyser: AnalyserNode;
  public audioElement: HTMLAudioElement;
  protected destination: MediaStreamAudioDestinationNode;

  public readonly playing = new ExternalState(false);
  public readonly muted = new ExternalState(false);
  public readonly streamState = new ExternalState<Record<string, any>>({});
  public readonly isMuted = new ExternalState<boolean>(false);

  constructor(
    protected name: string,
    protected audioContext: AudioContext,
    protected device: MediaDeviceInfo,
  ) {
    super(name, audioContext);
    this.analyser = this.audioContext.createAnalyser();
    this.audioElement = new Audio();

    this.destination = this.audioContext.createMediaStreamDestination();

    this.audioElement.srcObject = this.destination.stream;
    this.audioElement.addEventListener('canplaythrough', () => {
      this.audioElement.play();
    })
    this.audioElement.muted = false;
    this.isMuted.set(this.audioElement.muted);


    const analyserSource = audioContext.createMediaStreamSource(this.destination.stream);
    analyserSource.connect(this.analyser);
    this.init()
  }

  toggleMute() {
    if(!this.audioElement.muted) {
      this.audioElement.muted = true
      this.isMuted.set(true);
    }
    else {
      this.audioElement.muted = false;
      this.isMuted.set(false);
    }
  }

  protected async init() {
    if(!this.initPromise) this.initPromise = (async () => {
      if('setSinkId' in this.audioElement && this.device.deviceId !== 'rec_default') {
        // @ts-ignore
        await this.audioElement.setSinkId(this.device.deviceId)
      }

      
      setInterval(() => {
        if(this.muted.get() !== this.audioElement.muted) this.muted.set(this.audioElement.muted);
        const isPlaying = !this.audioElement.paused;
        if(this.playing.get() !== isPlaying) this.playing.set(isPlaying);
        this.streamState.set({
          active: this.destination.stream.active,
          f: this.destination.stream.getTracks().map(tr => tr.muted)
        })
      }, 1000)
    })()

    return this.initPromise;
  }

  toggle() {
    try {
      this.audioElement.srcObject = this.destination.stream;
      if(this.audioElement.paused) this.audioElement.play();
      else this.audioElement.pause();

      this.audioElement.muted = false;
    } catch(e) {
      // @ts-ignore
      alert(e.toString())
    }
    

  }

  async connectCanvas(canvas: HTMLCanvasElement) {
    await this.init();
    const canvasContext = canvas.getContext('2d')!;
    const analyser = this.analyser;
    analyser.fftSize = 32;
    let frequencyData = new Uint8Array(analyser.frequencyBinCount);
    const update = () => {
      requestAnimationFrame(update);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      analyser.getByteFrequencyData(frequencyData);

      let barLength = frequencyData.reduce((acc, cur) => acc + cur, 0) / frequencyData.length;
      canvasContext.fillStyle = 'rgb(' + (barLength-50) + ',126,0)';
      canvasContext.fillRect(0, 0, barLength / 255 * canvas.width, canvas.height);
    }

    update();
  }

  getDevice() {
    return this.device;
  }

  getDestination() {
    return this.destination;
  }

  finishConnection(input: BaseInput) {
    return;
  }
}