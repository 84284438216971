import { PodcastEpisodeListType } from '@nimey/podcast-global-entity';
import styles from './list.module.scss';
import { ArtworkWrapper } from '@/modules/global/components/podcast/artwork';
import { Link } from 'react-router-dom';
import {  List, Tiles } from '@/modules/global/components/list';
import { EpisodeProvider, useLocalEpisode } from '@/modules/global/components/podcast/episode/episode-provider';
import { Hr } from '@/modules/layout/components/hr';
import { EpisodeDescription } from '@/modules/global/components/podcast/episode/description';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';
import { EpisodeArtwork } from '@/modules/global/components/podcast/episode/artwork';
import { EpisodePubDate } from '@/modules/global/components/podcast/episode/pub-date';
import { usePodcastEpisodeList } from '@/modules/backend/hooks/use-podcast-episode-list';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { PodcastTitle } from '@/modules/global/components/podcast/title';
import { EpisodeActions } from '../epispode-actions';

export enum ListLayout {
  CAROUSEL = 'carousel',
  TILES = 'tiles',
  LIST = 'list',
}
type EpisodeListProps = {
  layout: ListLayout,
  listType: PodcastEpisodeListType,
  title: string,
}

type EntryRendererProps = {
  id: string;
  hideDescription?: boolean;
  hidePubDate?: boolean;
}

const InnerEpisodeRendererList = (props: EntryRendererProps) => {
  const { episode } = useLocalEpisode()
  return (
    <div className={styles['episode-vertical']}>
      <Link to={`/player/pod/${episode?.podcastId}/${episode?.id}`}>
        {!props.hidePubDate ? (<p className={styles['pub-date']}><EpisodePubDate /></p>) : ''}
        <div className={styles['info-row']}>
          <ArtworkWrapper width={60}><EpisodeArtwork /></ArtworkWrapper>
          <div>
            {episode?.podcastId ? (
              <p className={styles['podcast-title']}><PodcastProvider podcastId={episode.podcastId}><PodcastTitle /></PodcastProvider></p>
            ) : ''}
            <p className={styles['title']}><EpisodeTitle maxLines={1} /></p>
          </div>
        </div>
        {!props.hideDescription ? (<div className={styles['description']}><EpisodeDescription maxLines={2} /></div>) : <div style={{height: '.75em'}} />}

      </Link>
      <div className={styles['action-bar']}>
        <EpisodeActions />
      </div>
      <Hr />
    </div>
  )
}
export const EpisodeRendererList = (props: EntryRendererProps) => {
  return (
    <EpisodeProvider episodeId={props.id}>
      <InnerEpisodeRendererList {...props} />
    </EpisodeProvider>
  )
}

export const EpisodeList = (props: EpisodeListProps) => {
  const passProps = usePodcastEpisodeList({}, props.listType);
  if(props.layout === ListLayout.LIST) return <List entryRenderer={EpisodeRendererList} {...passProps} {...props} />
  if(props.layout === ListLayout.TILES) return <Tiles entryRenderer={EpisodeRendererList} {...passProps} {...props} />
  
  return <></>
}