import { IdbFileReader } from './idb-file-reader';
import { IdbFileWriter } from './idb-file-writer';

export enum Store {
  FILE = 'file',
  CHUNK = 'chunk',
}
export class IdbStorage {
  protected initPromise?: Promise<void>;

  protected dbVersionNumber = 1;
  protected db!: IDBDatabase;

  constructor(protected dbName: string, protected prefix: string) {
    this.init();
  }

  init() {
    this.initPromise = this.initPromise || (async () => {
      this.db = await this._createDb();
    })()

    return this.initPromise;
  }

  protected _createDb() {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const dbOpenRequest = indexedDB.open(this.dbName, this.dbVersionNumber);
      dbOpenRequest.onupgradeneeded = () => {
        const db = dbOpenRequest.result;

        if(!db.objectStoreNames.contains(Store.FILE)) {
          db.createObjectStore(Store.FILE, { keyPath: 'name' });
        }

        if(!db.objectStoreNames.contains(Store.CHUNK)) {
          db.createObjectStore(Store.CHUNK, { keyPath: ['name', 'idx'], });
        }
      }

      dbOpenRequest.onsuccess = () => {
        resolve(dbOpenRequest.result);
      }

      dbOpenRequest.onerror = (err) => {
        reject(err);
      }
    })
  }

  async getFileWriter(name: string) {
    const writer = new IdbFileWriter([this.prefix, name].join(''), this);
    await writer.init();
    return writer;
  }

  async getFileReader(name: string) {
    const reader = new IdbFileReader([this.prefix, name].join(''), this);
    await reader.init();
    return reader;
  }

  async listFiles() {
    const fileList = await this.transaction(Store.FILE, 'readonly', (trx) => trx.objectStore(Store.FILE).getAllKeys()) as string[];
    const replaceRegex = new RegExp(`^${this.prefix}`);
    return fileList.filter(name => name.startsWith(this.prefix)).map(name => name.replace(replaceRegex, ''));
  }

  async transaction(stores: Store | Store[], mode: IDBTransactionMode, callback: (trx: IDBTransaction) => IDBRequest) {
    await this.init();
    const trx = this.db.transaction(stores, mode);
    return new Promise((resolve, reject) => {
      const request = callback(trx);
      request.onsuccess = () => {
        resolve(request.result);
      }
    })
  }
}