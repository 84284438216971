import { BaseInput } from '../input/base';

export class BaseOutput {
  constructor(
    protected name: string,
    protected audioContext: AudioContext,
  ) {}

  getName() {
    return this.name;
  }

  finishConnection(input: BaseInput) {
    return;
  }
}