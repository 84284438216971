import { useEffect } from 'react';
export const useWakeLock = () => {
  useEffect(() => {
    if(!('wakeLock' in navigator)) return;
    // @ts-ignore
    let wakeLock: WakeLockSentinel;
    const request = async () => {
      try {
        // @ts-ignore
        wakeLock = await navigator.wakeLock.request('screen');
      } catch(e) {
        console.error(e);
      }
    }
    request();

    return () => {
      if(wakeLock) wakeLock.release();
    }
  }, [])
}

export const WakeLock = () => {
  useWakeLock();
  return <></>;
}