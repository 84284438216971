import { useValueByPath } from '..';
import { QuestionProps } from '../types'


export const QuestionText = (props: QuestionProps) => {
  const [ value, setValue ] = useValueByPath(props.path);


  return (
    <div>
      <input type='text' value={value || ''} name={props.question.name} onChange={(e) => setValue(e.currentTarget.value)} placeholder={props.question.label} />
    </div>
  );
}
