export class ExternalState<T extends unknown> {
  protected changeListener: Set<(value: T) => void> = new Set();
  constructor(
    protected value: T
  ) {}

  set(value: T) {
    this.value = value;
    this.changeListener.forEach(c => c(value));
  }

  get() {
    return this.value;
  }

  addListener(callback: (value: T) => void) {
    this.changeListener.add(callback);
  }

  removeListener(callback: (value: T) => void) {
    this.changeListener.delete(callback);
  }
}