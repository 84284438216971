import { BaseMessage } from './base.js';
export class RegistrationMessage extends BaseMessage {
    payload;
    constructor(payload) {
        super(payload);
        this.payload = payload;
    }
    get token() {
        return this.payload.token;
    }
    get userId() {
        return this.payload.userId;
    }
    get userName() {
        return this.payload.userName;
    }
    get channelId() {
        return this.payload.channelId;
    }
}
