import { useCallback, useContext, useState } from 'react';
import { BackendDispatchContext } from '../context/backend-context';

export const useHostingPodcastImage = (orgId?: string, podcastId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext);
  const [ buffer, setBuffer ] = useState<ArrayBuffer>();
  const [ preview, setPreview ] = useState<string>();

  const load = async (orgId: string, podcastId: string) => {
    try {
      const result = await asyncActions!.loadHostingPodcastImage(orgId, podcastId);
      setBuffer(result.response);
      if(result.headers?.get('content-type')) {
        let contentType = result.headers.get('content-type') || '';
        switch(contentType) {
          case 'application/xml': contentType = 'image/svg+xml'; break;
        }

        console.log(contentType)
        var blob = new Blob( [ result.response ], { type: contentType } );
        const imageUrl = URL.createObjectURL(blob)
        setPreview(imageUrl);
      }
    } catch(e) {
      console.error(e);
    }
    
    
  }

  
  return {
    load: useCallback(() => orgId && podcastId && load(orgId, podcastId), [orgId, podcastId]),
    save: useCallback((buffer: ArrayBuffer) => orgId && podcastId && asyncActions!.saveHostingPodcastImage(orgId, podcastId, buffer), [orgId, podcastId]),
    buffer,
    preview
  }
}