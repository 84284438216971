import { RtcConnection } from '@/modules/audio-recorder/util/rtc/connection';
import {  MdCompareArrows, MdPerson, MdVolumeMute } from 'react-icons/md';
import * as RtcInput from '@/modules/audio-recorder/components/rtc-input'
import * as RtcOutput from '@/modules/audio-recorder/components/rtc-output'

import styles from '../recording.module.scss';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from '@/modules/layout/components/modal';
import { useOutputList } from '@/modules/audio-recorder/hooks/use-output-list';
import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import { BaseInput } from '@/modules/audio-recorder/util/input/base';
import { ConnectionType } from '../viewer';
import { RtcInputContext } from '@/modules/audio-recorder/context/rtc-input-context';
import { RtcOutputContext } from '@/modules/audio-recorder/context/rtc-output-context';
import { useInputList } from '@/modules/audio-recorder/hooks/use-input-list';
import { useExternalState } from '@/modules/audio-recorder/hooks/use-external-state';

const ModalContent: typeof RtcPeerRenderer = (props) => {
  const outputs = useOutputList();
  const inputs = useInputList();
  const {input: rtcInput} = useContext(RtcInputContext);
  const {output: rtcOutput} = useContext(RtcOutputContext);

  const connectedOutputs: BaseOutput[] = useMemo(() => props.connections.filter(([i, o]) => i.getName() === rtcInput?.getName()).map(([i, o]) => o), [props.connections])
  const connectedInputs: BaseInput[] = useMemo(() => props.connections.filter(([i, o]) => o.getName() === rtcOutput?.getName()).map(([i, o]) => i), [props.connections])

  
  if(!rtcInput || !rtcOutput) return <></>;

  return (
    <>
      <p>An {props.peer.peerUserName} senden</p>
      <ul>
        {inputs.map((i, idx) => (
          <li key={`${i.getName()}-${idx}`}>
            <label>
              <input type="checkbox" checked={!!connectedInputs.find(c => c.getName() === i.getName())} onChange={(e) => {
                if(e.currentTarget.checked) props.addConnection(i, rtcOutput);
                else props.removeConnection(i, rtcOutput);
              }} />
              {i.getName()}
            </label>
          </li>
        ))}
      </ul>
      <p>Von {props.peer.peerUserName} abspielen</p>
      <ul>
        {outputs.map((o, idx) => (
          <li key={`${o.getName()}-${idx}`}>
            <label>
              <input type="checkbox" checked={!!connectedOutputs.find(c => c.getName() === o.getName())} onChange={(e) => {
                if(e.currentTarget.checked) props.addConnection(rtcInput, o);
                else props.removeConnection(rtcInput, o);
              }} />
              {o.getName()}
            </label>
          </li>
        ))}
      </ul>
    </>
  )
}
const Content: typeof RtcPeerRenderer = (props) => {
  const { peer } = props;

  const [showConnectionModal, setShowConnectionModal] = useState<boolean>(false);
  const attention = useExternalState(peer.attention);
  return (
    <div className={`${styles.rtcRenderer} ${attention ? styles.attention : ''}`}>
      <FlexRow center gap='1em'>
        <div className={styles.icon}><MdPerson /></div>
        <div className={styles.content}>
          <FlexRow spaceBetween center className={styles.info}>
            <span title={peer.peerUserId}>{peer.peerUserName}</span>
            <button className={styles.connectionModal} onClick={() => setShowConnectionModal(true)}><MdCompareArrows /></button>
          </FlexRow>
          <div className={styles.visualizer}>
            <div className={styles.out}><RtcInput.ToogleMute /><span>OUT: </span><RtcInput.Visualizer width={150} height={3} /></div>
            <div className={styles.in}><RtcOutput.ToogleMute /><span>IN: </span><RtcOutput.Visualizer width={150} height={3} /></div>
          </div>
          <Modal isOpen={showConnectionModal} close={() => setShowConnectionModal(false)}>
            <ModalContent {...props} />
          </Modal>
        </div>
      </FlexRow>
    </div>
  );
}


export const RtcPeerRenderer = (props: {peer: RtcConnection, connections: Array<ConnectionType>, addConnection: (i: BaseInput, o: BaseOutput) => void, removeConnection: (i: BaseInput, o: BaseOutput) => void}) => {
  const { peer } = props;
  return (
    <RtcOutput.Provider name={peer.peerUserName} rtcConnection={peer}>
      <RtcInput.Provider name={peer.peerUserName} rtcConnection={peer}>
        <Content {...props} />
      </RtcInput.Provider>
    </RtcOutput.Provider>
  );
}