import * as home from './home'
import * as notFound from './not-found'

import * as helpHome from './help-home'

import * as managerHome from './manager-home'
import * as managerOrg from './manager-org'
import * as managerOrgPodcast from './manager-org-podcast'
import * as managerOrgHostingPodcast from './manager-org-hosting-podcast'
import * as managerOrgHostingEpisode from './manager-org-hosting-episode'
import * as managerOrgAddPodcast from './manager-org-add-podcast'
import * as managerRecordingSession from './manager-org-recording-session'
import * as managerOrgHostingList from './manager-org-hosting-list';
import * as managerOrgPodcastList from './manager-org-podcast-list';
import * as managerOrgRecordingList from './manager-org-recording-list';

import * as playerHome from './player-home'
import * as playerHistory from './player-history'
import * as playerPlaylist from './player-playlist'
import * as playerPodcastDetail from './player-podcast-detail';
import * as playerEpisodeDetail from './player-episode-detail';
import * as playerSettings from './player-settings';
import * as playerSearchResult from './player-search-result';
import * as playerAllPodcasts from './player-all-podcasts';
import * as playerPodcastCategory from './player-podcast-category';
import * as playerAllEpisodes from './player-all-episodes';
import * as playerMyAbos from './player-my-abos';

import * as supportHome from './support-home'
import * as supportUsers from './support-users';
import * as supportUser from './support-user';
import * as supportFeature from './support-feature';
import * as supportPodcastDetail from './support-podcast-detail';

import * as emailVerifyHome from './email-verify-home';

import * as flushPage from './flush';


export const allPages = [
  home,
  flushPage,
  
  helpHome,

  emailVerifyHome,

  managerHome,
  managerOrg,
  managerOrgPodcast,
  managerOrgHostingPodcast,
  managerOrgAddPodcast,
  managerOrgHostingEpisode,
  managerRecordingSession,
  managerOrgHostingList,
  managerOrgPodcastList,
  managerOrgRecordingList,
  
  playerHome,
  playerHistory,
  playerPlaylist,
  playerPodcastDetail,
  playerEpisodeDetail,
  // playerSettings,
  playerSearchResult,
  playerPodcastCategory,
  playerAllPodcasts,
  playerAllEpisodes,
  playerMyAbos,

  supportHome,
  supportUsers,
  supportUser,
  supportFeature,
  supportPodcastDetail,

  notFound,
]