import { HostingEpisode as THostingEpisode, Organization } from '@nimey/podcast-global-entity';
import { withCurrentOrg } from '../../hoc/with-current-org';
import { Link, useBlocker, useNavigate, useParams } from 'react-router-dom';
import * as HostingPodcast from '@/modules/global/components/podcast/hosting-podcast-provider';
import * as HostingEpisode from '@/modules/global/components/podcast/hosting-episode-provider';
import { Gutter } from '@/modules/layout/components/gutter';
import { Spinner } from '@/modules/layout/components/spinner';
import { Button } from '@nimey/react-ui';
import { MdArrowBack, MdAudioFile, MdDelete, MdPlayArrow } from 'react-icons/md';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import ReactSwitch from 'react-switch';
import { Fragment } from 'react/jsx-runtime';
import styles from './hosting-episode.module.scss';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Modal } from '@/modules/layout/components/modal';
import Markdown from 'react-markdown';
import { useHostingEpisode } from '@/modules/backend/hooks/use-hosting-episode';
import { Gap } from '@/modules/layout/components/grid/gap';
import { useHostingEpisodeMedia } from '@/modules/backend/hooks/use-hosting-podcast-episode-media';
import { Hr } from '@/modules/layout/components/hr';

const FormContext = createContext<{
  hasChanges: boolean,
  setHasChanges: Dispatch<SetStateAction<boolean>>,
  episode?: THostingEpisode,
  setEpisode: Dispatch<SetStateAction<THostingEpisode | undefined>>,
  media: {buffer?: ArrayBuffer, preview?: string} | undefined,
  setMedia: Dispatch<SetStateAction<{buffer?: ArrayBuffer, changed?: boolean} | undefined>>,


} | undefined>(undefined)

function useFormEpisodeField<T extends keyof THostingEpisode>(fieldName: T): [
  THostingEpisode[T] | undefined,
  (v: THostingEpisode[T]) => void
] {
  const ctx = useContext(FormContext);
  return [
    ctx?.episode ? ctx.episode[fieldName] : undefined,
    (newValue: THostingEpisode[T]) => {
      if(!ctx?.episode) return;
      ctx?.setEpisode(p => (p ? {...p, [fieldName]: newValue} : undefined));
      if(ctx.episode) ctx.setHasChanges(true);
    } 
  ]
}

const FieldWithEditor = (props: {fieldName: keyof THostingEpisode}) => {
  const [ value, setValue ] = useFormEpisodeField(props.fieldName);
  const [ edit, setEdit ] = useState<boolean>(false);

  if(typeof value !== 'string' && typeof value !== 'undefined') return <></>

  return (
    <Fragment>
      <span onClick={(e => setEdit(true))}>{value || 'Nicht angegeben'}</span>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
        fitContent={true}
      >
        <div style={{height: 100}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            setEdit(false);
          }}>
            <input
              className={styles['input-element']}
              autoFocus={true}
              type='text'
              value={value || ''}
              onChange={ e => setValue(e.currentTarget.value) }
            />
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

const Actions = () => {
  const { podcast } = HostingPodcast.useLocalHostingPodcast();
  const { episode } = HostingEpisode.useLocalHostingEpisode();

  const navigate = useNavigate();

  const { remove } = useHostingEpisode(podcast?.orgId, podcast?.id, episode?.id);

  const deleteEpisodeCallback = useCallback(() => {
    remove();
    navigate(`/manager/${podcast?.orgId}/hosting-podcast/${podcast?.id}`)
  }, [remove ])
  return (
    <div className={styles.actions}>
      <div className={styles.desc}>
        <p>Folge löschen?</p>
        <small>Du kannst dies nicht rückgängig machen!</small>
      </div>
      <div className={styles.action}><Button onClick={deleteEpisodeCallback}><MdDelete /> Löschen</Button></div>
    </div>
  );
}

const Description = () => {
  const [ edit, setEdit ] = useState(false);
  const [ value, setValue ] = useFormEpisodeField('description');
  return (
    <Fragment>
      <div onClick={_ => setEdit(true)}><Markdown>{value || 'Nicht angegeben'}</Markdown></div>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
        fitContent={false}
      >
        <div>
          <form onSubmit={(e) => {
            e.preventDefault();
            setEdit(false);
          }}>
            <textarea
              className={`${styles['input-element']} ${styles['textarea-element']}`}
              autoFocus={true}
              value={value || ''}
              onChange={ e => setValue(e.currentTarget.value) }
            />
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

const Media = () => {
  const ctx = useContext(FormContext);
  const { podcast } = HostingPodcast.useLocalHostingPodcast();
  const { episode } = HostingEpisode.useLocalHostingEpisode();
  const [selectedMedia, setSelectedMedia] = useState<File>();
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if(!ctx?.media?.buffer && selectedMedia) setSelectedMedia(undefined)
  }, [ctx?.media])

  const { load, dataUrl } = useHostingEpisodeMedia(podcast?.orgId, podcast?.id, episode?.id);
  const [loading, setLoading] = useState(false);

  const setFile = (file: File) => {
    if(!ctx) return;
    setLoading(true);
    const bufferPromise = new Promise<ArrayBuffer>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (data) => {
        resolve(reader.result as ArrayBuffer);
      }
      reader.readAsArrayBuffer(file);
    })

    bufferPromise.then((media) => {
      ctx.setMedia({
        buffer: media,
        changed: true,
      })
      ctx.setHasChanges(true);
      setLoading(false);
    })
  }
  return (
    <div className={styles.mediaWrapper}>
      <label className={styles.audioFileButton}>
        <div className={styles.button}><MdAudioFile /></div>
        <input accept='audio/mpeg' type='file' style={{visibility: 'hidden'}} onChange={(e) => {
          setError('');
          if (e.target.files?.length === 1) {
            const file = e.target.files[0];
            if(file.type !== 'audio/mpeg') {
              setError('Typ wird nicht unterstützt')
              setSelectedMedia(undefined);
              return;
            }
            setSelectedMedia(file);
            setFile(file);
          } else setSelectedMedia(undefined);
        }} />
        <div>
          { selectedMedia ? (selectedMedia.name) : '' }
          { error ? (error) : '' }
        </div>
      </label>
      {episode?.hasMedia && !selectedMedia ? (
        <label className={styles.player}>
          {loading ? (
            <div><Spinner /></div>
          ) : (
            <>
              {dataUrl ? (
                <audio
                  src={dataUrl}
                  controls
                  autoPlay
                />
              ) : <div className={styles.playButton} onClick={() => load()}><MdPlayArrow /></div>}
            </>
          )}
        </label>
      ) : ''}

      {!episode?.hasMedia && !selectedMedia ? (<span>Keine Medien</span>) : ''}
      
    </div>
  );
}

export const Published = () => {
  const [ value, setValue ] = useFormEpisodeField('published');
  return <ReactSwitch checked={value || false} onChange={(e) => {
    setValue(e)
  }} />
}

export const PubDate = () => {
  const ctx = useContext(FormContext);

  if(!ctx?.episode) return <></>

  const value = (new Date(ctx?.episode?.pubDate || Date.now()))

  const dateForDateTimeInputValue = (date: Date) => new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)

  return (
    <input className={styles.dateInput} type='datetime-local' value={dateForDateTimeInputValue(value)} onChange={((e) => {
      if(!ctx) return;
      ctx.setEpisode(v => ({...v!, pubDate: (new Date(e.target.value)).getTime() }))
      ctx.setHasChanges(true);
    })} />
  );
}

const Detail = () => {
  const { episode } = HostingEpisode.useLocalHostingEpisode();
  const { podcast } = HostingPodcast.useLocalHostingPodcast();
  const hostingEpisode = useHostingEpisode(podcast?.orgId, podcast?.id, episode?.id)

  const [ stateEpisode, setStateEpisode ] = useState<THostingEpisode | undefined>(episode);
  const [ stateEpisodeMedia, setStateEpisodeMedia ] = useState<{buffer?: ArrayBuffer, preview?: string, changed?: boolean} | undefined>();
  const [ hasChanges, setHasChanges ] = useState<boolean>(false);

  const { save: saveEpisodeMedia } = useHostingEpisodeMedia(podcast?.orgId, podcast?.id, episode?.id);

  useEffect(() => {
    setStateEpisode(episode?.id ? {...episode} : undefined);
  }, [episode?.id])

  const blocker = useBlocker(() => {
    return hasChanges;
  })

  const saveChanges = useCallback(async () => {
    if (!stateEpisode) return;

    if (stateEpisodeMedia?.buffer && stateEpisodeMedia.changed) {
      await saveEpisodeMedia(stateEpisodeMedia.buffer)

      setStateEpisodeMedia(undefined);
    }

    await hostingEpisode.update(stateEpisode);
    setHasChanges(false);

  }, [stateEpisode, hostingEpisode])


  return (
    <FormContext.Provider value={{hasChanges, setHasChanges, episode: stateEpisode, setEpisode: setStateEpisode, media: stateEpisodeMedia, setMedia: setStateEpisodeMedia}}>
      <FlexRow center spaceBetween>
        <div />
        <Button primary onClick={_ => saveChanges()} disabled={!hasChanges}>Speichern</Button>
      </FlexRow>
      <Gap v='1em' />
      <FlexRow spaceBetween>
        <h2><FieldWithEditor fieldName='title' /></h2>
        <FlexRow center gap='.5em' style={{justifyContent: 'flex-end', flexDirection: 'column'}}>
          <div>Published <Published /></div>
          <div><PubDate /></div>
        </FlexRow>
      </FlexRow>
      <Gap v='1em' />
      <div className={styles.description}><Description /></div>
      <Gap v='1em' />
      <div className={styles.media}><Media /></div>
      <Hr />
      <Actions />
      <Modal
        isOpen={blocker.state === 'blocked'}
        close={() => {
          if(blocker.state === 'blocked') blocker.reset()
        }}
        fitContent
      >
        <div style={{padding: '1em 0'}}>
        <p>Du hast nicht alle Änderungen gespeichert. Was möchtest du vor dem Verlassen der Seite tun?</p>
          <FlexRow center spaceBetween>
            {blocker.state === 'blocked' ? (
              <Fragment>
                <Button onClick={_ => blocker.proceed()}>Änderungen verwerfen</Button>
                <Button primary onClick={() => saveChanges().then(() => blocker.proceed())}>speichern</Button>
              </Fragment>
            ) : ''}
          </FlexRow>
        </div>
      </Modal>
    </FormContext.Provider>
  );
}

const OrgHostingEpisodeWithOrg = (props: {org: Organization}) => {
  const { podcastId, episodeId } = useParams();

  if(!props.org || !podcastId || !episodeId) return <Gutter><Spinner size='2em' /></Gutter>

  return (
    <Gutter>
      <HostingPodcast.HostingPodcastProvider orgId={props.org.id} podcastId={podcastId}>
        <HostingEpisode.HostingEpisodeProvider orgId={props.org.id} podcastId={podcastId} episodeId={episodeId}>
          <Link to={`/manager/${props.org.id}/hosting-podcast/${podcastId}`}>
            <h1>
              <FlexRow gap='1em'>
                <div><MdArrowBack /></div>
                <HostingPodcast.Title />
              </FlexRow>
            </h1>
          </Link>
          <Detail />  
      </HostingEpisode.HostingEpisodeProvider>
      </HostingPodcast.HostingPodcastProvider>
    </Gutter>
  )
}

export const OrgHostingEpisode = withCurrentOrg<{}>(OrgHostingEpisodeWithOrg);