import { RecordingServerMessage } from '@nimey/podcast-global-entity';
import { RtcConnection, RtcConnectionMode } from './connection';
import EventEmitter from 'events';
import { ExternalState } from '../external-state';
import { Duration } from '@nimey/units';

type WsSignalingOptions = {
  url: string | URL;
  channelId: string;
  userId: string;
  userName: string;
  token: string;
  audioContext: AudioContext;
}

export enum SignalingStatus {
  INITIAL = 'initial',
  CONNECTED = 'connected',
  CLOSED = 'closed',
  OPEN = 'open',
}

export class RtcSignaling extends EventEmitter {
  protected ws!: WebSocket;
  protected openPromise?: Promise<void>;

  protected connections: Map<string, RtcConnection> = new Map();
  public readonly nameMap: ExternalState<Record<string, string>> = new ExternalState({});
  public readonly connectionState = new ExternalState<SignalingStatus>(SignalingStatus.INITIAL);
  public readonly timeOffset = new ExternalState<number>(0);

  protected expectedClose: boolean = false;


  constructor(
    protected options: WsSignalingOptions
  ) {
    super();
    this._initConnection();
    console.log('rtc constructed', this.userId)
  }

  get userId() {
    return this.options.userId;
  }

  get userName() {
    return this.options.userName;
  }

  get audioContext() {
    return this.options.audioContext;
  }

  protected _initConnection() {
    if(this.ws && this.ws.OPEN) {
      this.expectedClose = true;
      this.ws.close();
    }

    if(this.openPromise) this.openPromise = undefined;
    try {
      const ws = new WebSocket(this.options.url);
      this.ws = ws;
      ws.addEventListener('error', (e) => {
        setTimeout(() => {
          this.reconnect();
        }, +Duration.seconds(10))
      })
      ws.addEventListener('close', () => {
        this.connectionState.set(SignalingStatus.CLOSED)
      })
      ws.addEventListener('open', () => {
        this.connectionState.set(SignalingStatus.CONNECTED)
      })
      this.openPromise = new Promise<void>((resolve) => {
        ws.addEventListener('open', () => {
          resolve()
          this.send(new RecordingServerMessage.RegistrationMessage({
            requestId: window.crypto.randomUUID(),
            channelId: this.options.channelId,
            token: this.options.token,
            userId: this.options.userId,
            userName: this.options.userName,
          }))
        });
      })

      ws.addEventListener('message', (e) => {
        const event = RecordingServerMessage.deserializeMessage(e.data);
        if(!(event instanceof RecordingServerMessage.BaseMessage)) return;
        const methodName = `_on${event.constructor.name}`;
        // @ts-ignore
        const method = this[methodName] ? this[methodName].bind(this)  : ((e: any) => {});
        method(event);
      })

      ws.addEventListener('close', () => {
        if(this.expectedClose) {
          this.expectedClose = false;
          return;
        }

        this.reconnect();
      })

      return ws;
    } catch(e) {
      console.error(e);
      setTimeout(() => {
        this._initConnection();
      }, +Duration.seconds(10))
    }
    
  }

  reconnect() {
    this._initConnection();
    return this.openPromise;
  }

  async send(event: RecordingServerMessage.BaseMessage) {
    if(this.openPromise) await this.openPromise;
    this.ws.send(JSON.stringify(event));
  }

  endSession() {
    return this.send(new RecordingServerMessage.EndSessionMessage({
      channelId: this.options.channelId,
      userId: this.options.userId,
      userName: this.options.userName,
      requestId: crypto.randomUUID(),
    }))
  }

  beginUpload(fileCount: number) {
    return this.send(new RecordingServerMessage.BeginUploadMessage({
      channelId: this.options.channelId,
      userId: this.options.userId,
      userName: this.options.userName,
      requestId: crypto.randomUUID(),
      fileCount
    }))
  }

  finishUpload() {
    return this.send(new RecordingServerMessage.FinishUploadMessage({
      channelId: this.options.channelId,
      userId: this.options.userId,
      userName: this.options.userName,
      requestId: crypto.randomUUID(),
    }))
  }

  startRecording() {
    return this.send(new RecordingServerMessage.StartRecordingMessage({
      channelId: this.options.channelId,
      userId: this.options.userId,
      userName: this.options.userName,
      startDate: Date.now() + +Duration.seconds(10),
      requestId: crypto.randomUUID(),
    }))
  }

  stopRecording() {
    return this.send(new RecordingServerMessage.EndRecordingMessage({
      channelId: this.options.channelId,
      userId: this.options.userId,
      userName: this.options.userName,
      requestId: crypto.randomUUID(),
    }))
  }

  requestAttention() {
    return this.send(new RecordingServerMessage.RequestAttentionMessage({
      channelId: this.options.channelId,
      userId: this.options.userId,
      userName: this.options.userName,
      requestId: crypto.randomUUID(),
    }))
  }

  _onPeerConnectIncitementMessage(event: RecordingServerMessage.PeerConnectIncitementMessage) {
    console.log('invitement', event.userName)
    if(this.connections.has(event.userId)) return;
    const connection = new RtcConnection(event.userId, event.userName, this, RtcConnectionMode.ACTIVE);
    this.emit('peer-connect', connection)
    connection.addListener('peer-disconnect', (...args) => {
      this.emit('peer-disconnect', ...args);
      this.connections.delete(event.userId);
    });
    this.connections.set(event.userId, connection);
  }

  _onRequestAttentionMessage(event: RecordingServerMessage.RequestAttentionMessage) {
    const conn = this.connections.get(event.userId);
    if(!conn) return;

    conn.attention.set(true);
    setTimeout(() => {
      conn.attention.set(false);
    }, +Duration.seconds(3));
  }

  _onSdpOfferMessage(event: RecordingServerMessage.SdpOfferMessage) {
    if(this.connections.has(event.senderId)) {
        const connection = this.connections.get(event.senderId);
        connection!.setSdpOffer(event.sdp, event.type)
    } else {
      console.log('on-sdp-offer', event)
      const connection = new RtcConnection(event.senderId, event.senderName, this, RtcConnectionMode.PASSIVE);
      this.connections.set(event.senderId, connection);
      this.emit('peer-connect', connection);
      connection.addListener('peer-disconnect', (...args) => {
        this.emit('peer-disconnect', ...args);
        this.connections.delete(event.senderId);
      });
      connection.setSdpOffer(event.sdp, event.type);
    }
  }

  _onSdpAnswerMessage(event: RecordingServerMessage.SdpAnswerMessage) {
    const con = this.connections.get(event.senderId);
    if(!con) return;
    con.setSdpAnswer(event.sdp, event.type);
  }

  _onIceCandidateMessage(event: RecordingServerMessage.IceCandidateMessage) {
    const con = this.connections.get(event.senderId);
    if(!con) return;
    con.addIceCandidate(JSON.parse(event.candidateJson));
  }

  _onPeerAnnounceUserInfoMessage(event: RecordingServerMessage.PeerAnnounceUserInfoMessage) {
    this.nameMap.set({...this.nameMap.get(), [event.payload.userId]: event.payload.name});
  }

  _onStartRecordingMessage(event: RecordingServerMessage.StartRecordingMessage) {
    window.dispatchEvent(new CustomEvent('pod-record-start', {
      detail: {
        startDateWithOffset: new Date(event.startDate - this.timeOffset.get()),
        startDate: new Date(event.startDate),
        initiator: event
      }
    }))
  }

  _onEndSessionMessage(event: RecordingServerMessage.EndSessionMessage) {
    this.emit('session-end', { initiator: { name: event.userName, id: event.userId }})
  }

  _onBeginUploadMessage(event: RecordingServerMessage.BeginUploadMessage) {
    this.emit('upload-begin', { initiator: { name: event.userName, id: event.userId }, fileCount: event.fileCount})
  }

  _onFinishUploadMessage(event: RecordingServerMessage.FinishUploadMessage) {
    this.emit('upload-finish', { initiator: { name: event.userName, id: event.userId }})
  }

  _onEndRecordingMessage(event: RecordingServerMessage.StartRecordingMessage) {
    window.dispatchEvent(new CustomEvent('pod-record-end', {
      detail: {
        startDate: new Date(event.startDate),
        initiator: event
      }
    }))
  }

  _onPingMessage(event: RecordingServerMessage.PingMessage) {
    this.timeOffset.set(event.currentOffset);
    this.send(new RecordingServerMessage.PongMessage({
      ...event.payload,
      clientTs: Date.now(),  
    }))

  }
}