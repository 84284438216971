import { AudioRecorderContext } from '@/modules/audio-recorder/context/audio-recorder-context'
import { useDeviceListInput } from '@/modules/audio-recorder/hooks/use-device-list'
import { Dispatch, SetStateAction, useEffect } from 'react'
import * as DeviceInput from '@/modules/audio-recorder/components/device-input'

export const InputWatcher = (props: {
  inputs: Array<DeviceInput.DeviceInputProviderProps>,
  setInputs: Dispatch<SetStateAction<Array<DeviceInput.DeviceInputProviderProps>>>,
}) => {
  const { loading, devices, reload } = useDeviceListInput()
  useEffect(() => {
    const newInputs: typeof props['inputs'] = [...props.inputs];
    for(const device of devices) {
      if(!props.inputs.find(i => i.device.deviceId === device.deviceId)) {
        newInputs.push({device, name: device.label})
      }
    }

    if(newInputs.map(i => i.name).join('.') !== props.inputs.map(i => i.name).join('.')) {
      props.setInputs(newInputs);
    }
  }, [devices])
  
  return <></>
}