import { useContext, useEffect, useState } from 'react'
import { IdbContext } from '../context/idb-context'

export const useIdbFileList = (): [string[], () => void] => {
  const idb = useContext(IdbContext);
  const [files, setFiles] = useState<string[]>([]);
  const reload = () => {idb.listFiles().then(setFiles)};
  useEffect(() => {
    reload();
  }, [setFiles])

  return [files, reload];
}