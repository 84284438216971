export class BaseMessage {
    payload;
    constructor(payload) {
        this.payload = payload;
    }
    toJSON() {
        return {
            __type: this.constructor.name,
            payload: this.payload,
        };
    }
    get requestId() {
        return this.payload.requestId;
    }
    get receiver() {
        return this.payload.receiver;
    }
}
