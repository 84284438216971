import { WrappedClientResponse } from '@nimey/podcast-client';
import { BackendAction, BackendActionKind } from '../../types/backend-action';
import { BackendState } from '../../types/backend-state';
import { BaseEntity, RecordingSession, RecordingSessionCreatePayload } from '@nimey/podcast-global-entity';

export const recordingSession = (state: BackendState, dispatch: React.Dispatch<BackendAction | BackendAction[]>) => {
  const dispatchFlushTags =(response: WrappedClientResponse<unknown>): Array<BackendAction> => {
    if(!response.flushCacheTags || response.flushCacheTags.size <= 0) return [];
    return [
      {type: BackendActionKind.FLUSH_TAGS, payload: {tags: response.flushCacheTags}}
    ];
  }
  const loadRecordingSession = async(orgId: string, sessionId: string) => {
    try {
      const entity = await state.controller!.hosting.getRecordingSessionById(orgId, sessionId)
      if(!entity || !entity.response) {
        console.error('session not found', orgId, sessionId);
        return;
      }
      dispatch([
        ...dispatchFlushTags(entity),
        {
          type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'recording-session',
          entity: entity.response,
          tags: entity.cacheTags,
        }}
      ])
    } catch(e) {
      console.error(e);
      return;
    }
  };
  

  const loadRecordingSessionList = async(orgId: string, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = orgId;
    const entityList = await state.controller!.hosting.listRecordingSessions(orgId, (pageNumber - 1) * pageSize, pageSize)

    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'recording-session',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'recording-session', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'recording-session', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }



  const createRecordingSession = async (session: RecordingSessionCreatePayload) => {
    const newEntity = await state.controller!.hosting.createRecordingSession(session.orgId, session);
    if(newEntity && newEntity.response) {
      dispatch([
        ...dispatchFlushTags(newEntity),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'recording-session', entity: newEntity.response, tags: newEntity.cacheTags }}
      ]);
    }
    
    return newEntity.response;
  };

  const updateRecordingSession = async (session: RecordingSession) => {
    const newEntity = await state.controller!.hosting.updateRecordingSession(session.orgId, session);
    if(newEntity && newEntity.response) {
      dispatch([
        ...dispatchFlushTags(newEntity),
        {type: BackendActionKind.ADD_ENTITY, payload: { type: 'recording-session', entity: newEntity.response, tags: newEntity.cacheTags }}
      ]);
    }
    
    return newEntity.response;
  };

  const deleteRecordingSession = async (orgId: string, sessionId: string) => {
    const newEntity = await state.controller!.hosting.deleteRecordingSession(orgId, sessionId);
    if(newEntity && newEntity.response) {
      dispatch([
        ...dispatchFlushTags(newEntity),
      ]);
    }
    
    return newEntity.response;
  };

  const saveRecordingMedia = async (orgId: string, sessionId: string, userId: string, userName: string, fileName: string, payload: ArrayBuffer, sessionToken?: string) => {
    const result = await state.controller!.hosting.uploadRecordingMedia(orgId, sessionId, userId, userName, fileName, payload, sessionToken);
    return result.response;
  }

  const loadRecordingSessionMediaList = async(orgId: string, sessionId: string, pageNumber: number = 1, pageSize: number = 6) => {
    const filterKey = JSON.stringify({orgId, sessionId});
    const entityList = await state.controller!.hosting.listRecordingSessionMedia(orgId, sessionId, (pageNumber - 1) * pageSize, pageSize)
    if(!entityList) return;

    const meta = {
      totalCount: entityList.response.metaData.totalCount || 0,
      totalPages: Math.ceil(entityList.response.metaData.totalCount / pageSize) || 0,
    }

    dispatch([
      ...dispatchFlushTags(entityList),
      ...(entityList.response.entities.map((entity): {type: BackendActionKind.ADD_ENTITY, payload: {type: string, entity: BaseEntity, tags?: Set<string>}} => ({
        type: BackendActionKind.ADD_ENTITY, payload: {
          type: 'recording-session-media',
          entity,
          tags: entityList.cacheTags
        }
      }))),
      {type: BackendActionKind.ADD_ENTITY_LIST, payload: { type: 'recording-session-media', filterKey, meta, tags: entityList.cacheTags}},
      {type: BackendActionKind.ADD_ENTITY_LIST_PAGE, payload: { type: 'recording-session-media', filterKey, pageNumber, ids: entityList.response.entities.map(e => e.id), tags: entityList.cacheTags }},
    ])
  }

  const downloadRecordingSessionMedia = async (orgId: string, sessionId: string, mediaId: string) => {
    const result = await state.controller!.hosting.downloadRecordingSessionMedia(orgId, sessionId, mediaId);
    return result;
  }
  

  return {
    loadRecordingSession,
    loadRecordingSessionList,
    createRecordingSession,
    updateRecordingSession,
    deleteRecordingSession,
    saveRecordingMedia,
    loadRecordingSessionMediaList,
    downloadRecordingSessionMedia
  }
}