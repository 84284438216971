import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { Duration } from '@nimey/units';
import { RecorderOutput } from '../util/output/recorder';
import { RecorderOutputContext } from '../context/recorder-output-context';
import { Button } from '@nimey/react-ui';
import { MdStart, MdStop } from 'react-icons/md';
import { IdbContext } from '@/modules/idb-file-storage/context/idb-context';
import { DateDuration } from '@/modules/global/components/date-duration';
import { IdbStorage } from '@/modules/idb-file-storage/util/idb-storage';

export type RecorderOutputProviderProps = {
  name: string;
  startDate: Date;
}

export const Name = () => {
  const ctx = useContext(RecorderOutputContext);
  
  return <>{ctx.output?.getName()}</>
}

export const Start = () => {
  const ctx = useContext(RecorderOutputContext);
  const output = ctx.output;
  if(!output) return <></>;

  return <Button onClick={() => output.start()}><MdStart /></Button>
}

export const Stop = () => {
  const ctx = useContext(RecorderOutputContext);
  const output = ctx.output;
  if(!output) return <></>;

  return <Button onClick={() => output.stop()}><MdStop /></Button>
}

export const Timeout = () => {
  const ctx = useContext(RecorderOutputContext);
  const output = ctx.output;

  const [seconds, setSeconds] = useState(-2);
  useEffect(() => {
    const interval = setInterval(() => {
      if(!output) return;
      const duration = output.startDate.getTime() - Date.now();
      if(duration < 0) {
        setSeconds(-1);
        clearInterval(interval);
      } else setSeconds(duration / 1000)
    }, +Duration.seconds(1));
    return () => {
      clearInterval(interval);
    }
  }, [ctx.output])

  if(!output) return <></>;
  if(seconds === -2) return <></>;
  if(seconds === -1) return <></>;

  return <>{Math.round(seconds)}</>
}

export const Visualizer = (props: {width: number, height: number, style?: CSSProperties}) => {
  const ctx = useContext(RecorderOutputContext);
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    if(ctx?.output && canvasRef.current) {
      ctx.output.connectCanvas(canvasRef.current)
    }
  }, [ctx])

  return <canvas ref={canvasRef} {...props} />
}


class RecorderProvider {
  protected instances: Record<string, RecorderOutput> = {};
  getRecorder(startDate: Date, name: string, ctx: AudioContext, idb: IdbStorage) {
    const key = [startDate.toISOString(), name].join('--');
    if(!this.instances[key]) this.instances[key] = new RecorderOutput(name, ctx, idb, startDate);

    return this.instances[key];
  }
}

const recorderProvider = new RecorderProvider();


export const Provider = (props: PropsWithChildren<RecorderOutputProviderProps>) => {
  const ctx = useContext(AudioRecorderContext);
  const idb = useContext(IdbContext)
  const inputRef = recorderProvider.getRecorder(props.startDate, props.name, ctx.recorder!.getContext(), idb)

  useEffect(() => {
    ctx.recorder!.addOutput(inputRef)
    const onEnd = () => inputRef.stop();
    window.addEventListener('pod-record-end', onEnd)
    return () => {
      inputRef.stop();
      ctx.recorder!.removeOutput(inputRef)
      window.removeEventListener('pod-record-end', onEnd)
    }
  }, [inputRef])
  if(!inputRef) return <></>
  return (
    <RecorderOutputContext.Provider value={{output: inputRef}}>
      {props.children}
    </RecorderOutputContext.Provider>
  );
}