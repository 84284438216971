import { ValidationError } from '../../error/validation-error.js';
export var HostingPodcastEpisodeType;
(function (HostingPodcastEpisodeType) {
    HostingPodcastEpisodeType["EPISODIC"] = "episodic";
    HostingPodcastEpisodeType["SERIAL"] = "serial";
    HostingPodcastEpisodeType["UNKNOWN"] = "unknown";
})(HostingPodcastEpisodeType || (HostingPodcastEpisodeType = {}));
export const assertHostingPodcastCreatePayloadValid = (org) => {
    // if(!org.name) throw new ValidationError(1, 'org name is required');
    // if(typeof org.name !== 'string') throw new ValidationError(1714474306, 'org name must be string');
};
export const assertHostingPodcastValid = (org) => {
    if (!org.id)
        throw new ValidationError(1, 'org id is required');
    assertHostingPodcastCreatePayloadValid(org);
};
