import { useDeviceListInput, useDeviceListOutput } from '@/modules/audio-recorder/hooks/use-device-list';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Modal } from '@/modules/layout/components/modal';
import { Button } from '@nimey/react-ui';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export type DefaultConnectionType = {
  mic: string;
  speaker: string;
}

export const DefaultSelection = (props: {defaultConnections: DefaultConnectionType, setDefaultConnections: Dispatch<SetStateAction<DefaultConnectionType>>}) => {
  const { defaultConnections, setDefaultConnections } = props;
  const [ forceShow, setForceShow ] = useState<boolean>(false);
  const { devices: inputDevices, reload: reloadInput } = useDeviceListInput();
  const { devices: outputDevices, reload: reloadOutput } = useDeviceListOutput();

  useEffect(() => {
    const onShowEvent = () => {
      setForceShow(true);
      reloadInput();
      reloadOutput();
    }

    window.addEventListener('show-default-selection', onShowEvent);
    return () => {
      window.removeEventListener('show-default-selection', onShowEvent);
    }
  }, [])

  return (
    <Modal
      isOpen={!defaultConnections.mic || !defaultConnections.speaker || forceShow}
      close={() => {
        setForceShow(false);
      }}
    >
      <form onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        setDefaultConnections({
          mic: formData.get('mic') as string,
          speaker: formData.get('speaker') as string,
        })
        setForceShow(false);
      }}>
        <p><strong>Wähle deine Audiogeräte</strong></p>
        <p>Welches Mikrofon möchtest du verwenden?</p>
        <FlexRow gap='.5em' style={{flexDirection: 'column', paddingLeft: '1em'}}>
          <label><input required type='radio' name='mic' value='none' />Keins</label>
          {inputDevices.map(i => (
            <label key={i.deviceId}><input required type='radio' name='mic' value={i.deviceId} />{i.label}</label>
          ))}
        </FlexRow>

        <p>Welche Lautsprecher / Kopfhörer möchtest du verwenden?</p>
        <FlexRow gap='.5em' style={{flexDirection: 'column', paddingLeft: '1em'}}>
          <label><input required type='radio' name='speaker' value='none' />Keins</label>
          {outputDevices.map(i => (
            <label key={i.deviceId}><input required type='radio' name='speaker' value={i.deviceId} />{i.label}</label>
          ))}
        </FlexRow>
        <FlexRow spaceBetween>
          <div />
          <Button type='submit' primary>Weiter</Button>
        </FlexRow>
      </form>
    </Modal>
  );
}