import { InternalError, NotFoundError } from '@nimey/podcast-global-entity';
import { IdbStorage, Store } from './idb-storage';
import { NotFound } from '@/modules/layout/components/not-found';

export class IdbFileReader {
  protected initPromise?: Promise<void>;

  constructor(
    protected fileName: string,
    protected idb: IdbStorage
  ) {
    this.init();
  }

  public get name() {
    return this.fileName;
  }

  init() {
    this.initPromise = this.initPromise || (async () => {
      await this.idb.init();
      const fileEntry = await this.idb.transaction(Store.FILE, 'readonly', (trx) => trx.objectStore(Store.FILE).get(this.fileName));
      if(!fileEntry) {
        throw new NotFoundError(1, 'file not found')
      }
    })()

    return this.initPromise;
  }

  async readBlob() {
    const chunks = await this.idb.transaction(Store.CHUNK, 'readonly', (trx) => trx.objectStore(Store.CHUNK).getAll()) as {name: string, idx: number, chunk: Blob}[];
    if(chunks.length <= 0) return new Blob();

    const firstBlob = chunks[0].chunk;

    return new Blob(chunks.filter(c => c.name === this.fileName).map(c => c.chunk), { type: firstBlob.type });
  }
}