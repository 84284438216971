import { ExternalState } from '../external-state';
import { DeviceOutput } from '../output/device';
import { BaseInput } from './base';

export class DeviceInput extends BaseInput {
  protected micro!: MediaStreamAudioSourceNode;
  protected analyser!: AnalyserNode;

  protected initPromise?: Promise<void>;
  protected internalDestination: MediaStreamAudioDestinationNode;
  protected gainNode: GainNode;
  public readonly isMuted = new ExternalState<boolean>(false);
  protected ended: boolean = false;

  
  constructor(
    protected name: string,
    protected audioContext: AudioContext,
    protected device: MediaDeviceInfo,
  ) {
    super(name, audioContext);
    this.init()
    this.analyser = audioContext.createAnalyser();
    
    this.internalDestination = audioContext.createMediaStreamDestination();
    
    this.gainNode = audioContext.createGain();
    this.gainNode.connect(this.internalDestination)
    this.gainNode.gain.value = 1

    this.micro = audioContext.createMediaStreamSource(this.internalDestination.stream);
  }

  protected async connectMediaDevice() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {deviceId: {exact: this.device.deviceId}}
    })

    const audioContext = this.audioContext
    const micro = audioContext.createMediaStreamSource(stream);

    const tracks = stream.getAudioTracks()
    if(tracks[0]) {
      const track = tracks[0];
      track.addEventListener('ended', () => {
        this.ended = true;
        micro.disconnect(this.gainNode);
        micro.disconnect(this.analyser);
      })
    }
    micro.connect(this.gainNode);
    micro.connect(this.analyser);
  }

  protected async init() {
    if(!this.initPromise) this.initPromise = (async () => {
       await this.connectMediaDevice();
    })()

    return this.initPromise; 
  }

  toggleMute() {
    if(this.gainNode.gain.value === 1) {
      this.gainNode.gain.value = 0;
      this.isMuted.set(true);
    }
    else {
      this.gainNode.gain.value = 1;
      this.isMuted.set(false);
    }
  }

  async connectCanvas(canvas: HTMLCanvasElement) {
    await this.init();
    const canvasContext = canvas.getContext('2d')!;
    const analyser = this.analyser;
    analyser.fftSize = 32;
    let frequencyData = new Uint8Array(analyser.frequencyBinCount);
    const update = () => {
      requestAnimationFrame(update);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      analyser.getByteFrequencyData(frequencyData);

      let barLength = frequencyData.reduce((acc, cur) => acc + cur, 0) / frequencyData.length;
      canvasContext.fillStyle = 'rgb(' + (barLength-50) + ',126,0)';
      canvasContext.fillRect(0, 0, barLength / 255 * canvas.width, canvas.height);
    }

    update();
  }

  getDevice() {
    return this.device;
  }

  connectOutput(output: DeviceOutput) {
    this.micro.connect(output.getDestination());
    output.finishConnection(this);
    if(this.ended) {
      this.connectMediaDevice();
    }
  }

  disconnectOutput(output: DeviceOutput) {
    try {
      this.micro.disconnect(output.getDestination());
    } catch {}
  }
}