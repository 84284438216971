import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { RtcSignaling } from '../util/rtc/signaling'
import { RtcSignalingContext } from '../context/rtc-signaling-context';
import { RtcConnection } from '../util/rtc/connection';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { Modal } from '@/modules/layout/components/modal';
import { Button } from '@nimey/react-ui';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { RecordingEntitiesContext } from '../context/recording-entities-context';
import { MdCallEnd, MdConnectingAirports, MdOutlineWavingHand } from 'react-icons/md';
import { TbRecordMail, TbRecordMailOff } from 'react-icons/tb';
import { Duration } from '@nimey/units';
import { useExternalState } from '../hooks/use-external-state';

type ProviderProps = {
  channelId: string;
  token: string;
  url: string;
  userId: string;
  userName: string;
  onPeerConnection?: (connection: RtcConnection) => void;
  onPeerDisconnection?: (connection: RtcConnection) => void;
}

export const RequestAttention = () => {
  const [clicked, setClicked] = useState<boolean>(false);
  const ctx = useContext(RtcSignalingContext);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if(clicked) timeout = setTimeout(() => setClicked(false), +Duration.seconds(3));

    return () => {
      if(timeout) clearTimeout(timeout);
    }
  }, [clicked])

  if(!ctx.signaling) return <></>;

  return <><button style={{...(clicked ? {color: 'red'} : {})}} onClick={() => {
    ctx.signaling?.requestAttention();
    setClicked(true)
  }}><MdOutlineWavingHand /></button></>
}

export const StopRecording = () => {
  const ctx = useContext(RtcSignalingContext);
  const { isGuest } = useContext(RecordingEntitiesContext);
  if(isGuest) return <></>;
  if(!ctx.signaling) return <></>;

  return <><button onClick={() => {
    ctx.signaling?.stopRecording();
  }}><TbRecordMailOff /></button></>
}

export const StartRecording = () => {
  const ctx = useContext(RtcSignalingContext);
  const { isGuest } = useContext(RecordingEntitiesContext);
  if(isGuest) return <></>;
  if(!ctx.signaling) return <></>;

  return <><button onClick={() => {
    ctx.signaling?.startRecording();
  }}><TbRecordMail /></button></>
}

function msToTime(duration: number) {
  const milliseconds = Math.round((duration%1000)/10)
  const seconds = Math.round((duration/1000)%60)
  const minutes = Math.round((duration/(1000*60))%60)
  const hours = Math.round((duration/(1000*60*60))%24);

  const strHours = (hours < 10) ? "0" + hours : hours;
  const strMinutes = (minutes < 10) ? "0" + minutes : minutes;
  const strSeconds = (seconds < 10) ? "0" + seconds : seconds;

  return strHours + ":" + strMinutes + ":" + strSeconds + "." + String(milliseconds).padStart(2, '0');
}

export const ServerTime = () => {
  const ctx = useContext(RtcSignalingContext);
  const offset = useExternalState(ctx.signaling!.timeOffset);
  const clientElementRef = useRef<HTMLPreElement>(null)
  const serverElementRef = useRef<HTMLPreElement>(null)
  useEffect(() => {
    const interval = setInterval(() => {
      const localNow = Date.now()
      const serverNow = localNow + offset;

      if(serverElementRef.current) serverElementRef.current.innerText = msToTime(serverNow);
      if(clientElementRef.current) clientElementRef.current.innerText = msToTime(localNow);
    }, +Duration.millis(100));

    return () => {
      clearInterval(interval);
    }
  }, [offset])

  return (
    <FlexRow style={{flexDirection: 'column', justifyContent: 'flex-start'}} center>
      <small><FlexRow center gap='1em'>C<pre style={{margin: 0}} ref={clientElementRef} /></FlexRow></small>
      <small><FlexRow center gap='1em'>S<pre style={{margin: 0}} ref={serverElementRef} /></FlexRow></small>
    </FlexRow>
  )

}

export const ConnectionState = () => {
  const ctx = useContext(RtcSignalingContext);
  const status = useExternalState(ctx.signaling!.connectionState);
  if(!ctx.signaling) return <></>;

  return <>{status}</>
}

export const Reconnect = () => {
  const ctx = useContext(RtcSignalingContext);
  if(!ctx.signaling) return <></>;

  return <><button onClick={() => {
    ctx.signaling?.reconnect();
  }}><MdConnectingAirports /></button></>
}

export const EndSession = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const ctx = useContext(RtcSignalingContext);
  const { isGuest } = useContext(RecordingEntitiesContext);
  if(isGuest) return <></>;
  const signaling = ctx.signaling;
  if(!signaling) return <></>;

  return (
    <>
      <Modal isOpen={showModal} close={() => setShowModal(false)}>
        <p><strong>Möchtest du die Sitzung wirklich für alle Teilnehmer beenden?</strong></p>
        <p>Nach dem Ende werden alle Verbindungen beendet und die Aufnahmen von allen hochgeladen</p>
        <FlexRow center spaceBetween>
          <Button onClick={() => setShowModal(false)}>Abbrechen</Button>
          <Button primary onClick={() => {
            setShowModal(false);
            signaling.endSession();
          }}>Jetzt beenden</Button>
        </FlexRow>
      </Modal>
      <button onClick={() => setShowModal(true)}><MdCallEnd /></button>
    </>
  )
}

class SignalingObjProvider {
  protected instances: Record<string, RtcSignaling> = {};
  getSignaling(channelId: string, token: string, url: string, userId: string, userName: string, audioContext: AudioContext) {
    const key = [channelId, token, url, userId, userName].join('--');
    if(!this.instances[key]) this.instances[key] = new RtcSignaling({channelId, token, url, userId, userName, audioContext});

    return this.instances[key];
  }
}

const signalingProvider = new SignalingObjProvider();


export const RtcSignalingProvider = (props: PropsWithChildren<ProviderProps>) => {
  const ctx = useContext(AudioRecorderContext);
  const signaling = signalingProvider.getSignaling(props.channelId, props.token, props.url, props.userId, props.userName, ctx.recorder!.getContext());
  useEffect(() => {
    if(props.onPeerConnection) {
      signaling.addListener('peer-connect', props.onPeerConnection);
    }

    if(props.onPeerDisconnection) {
      signaling.addListener('peer-disconnect', props.onPeerDisconnection);
    }

    return () => {
      if(props.onPeerConnection && signaling) {
        signaling.removeListener('peer-connect', props.onPeerConnection);
      }

      if(props.onPeerDisconnection && signaling) {
        signaling.removeListener('peer-disconnect', props.onPeerDisconnection);
      }
    }
  }, [props.onPeerConnection])

  if(!signaling) return <></>

  return (
    <RtcSignalingContext.Provider value={{signaling: signaling}}>
      {props.children}
    </RtcSignalingContext.Provider>
  );
}