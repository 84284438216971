import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { HostingEpisode, HostingEpisodeCreatePayload, HostingPodcast, HostingPodcastCreatePayload, InternalError } from '@nimey/podcast-global-entity';

export const useHostingEpisode = (orgId?: string, podcastId?: string, episodeId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext);
  const state = useContext(BackendStateContext);

  const entityInfo = state.entities['hosting-episode'] ? state.entities['hosting-episode'][episodeId || ''] : undefined;
  const episode = entityInfo?.entity as HostingEpisode | undefined;

  const reload = (force: boolean = false) => {
    if((!episode || force) && orgId && podcastId && episodeId && asyncActions) {
      asyncActions?.loadHostingEpisode(orgId, podcastId, episodeId).catch((e) => {
        console.error(e)
      });
    }
  };

  useEffect(() => {
    reload();
  }, [])

  const add = useCallback(async (p: HostingEpisodeCreatePayload) => {
    if(episodeId) {
      throw new InternalError(1, 'can not add episode with id');
    }

    return await asyncActions?.createHostingEpisode(p);
  }, [episodeId])

  const remove = useCallback(async () => {
    if(!podcastId || !episodeId) {
      throw new InternalError(1, 'can not delete episode without id');
    }

    return await asyncActions?.deleteHostingEpisode(podcastId, episodeId);
  }, [episodeId])

  const update = useCallback(async (p: HostingEpisode) => {
    if(!episodeId) {
      throw new InternalError(1, 'can not update podcast without id');
    }

    await asyncActions?.updateHostingEpisode(p);
  }, [episodeId])

  return {
    add,
    update,
    episode,
    reload,
    remove,
  }
}