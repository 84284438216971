export enum QuestionType {
    YES_NO = 'yes_no',
    SELECTION = 'selection',
    FEED_URL = 'feed_url',
    TEXT_INPUT = 'text_input',
}

export interface Question {
    name: string;
    label: string;
    type: QuestionType;
    options?: Record<any, string>;
}

export type TreeChild = {
    tree: TreeEntry;
    condition: (v: AssistantState) => boolean;
}

export type TreeEntry = {
    name: string;
    children: Array<TreeChild>;
    questions: Array<Question>;
}

export type QuestionProps = {
    question: Question;
    path: string;
}

export type AssistantProps = {
    tree: TreeEntry
    path?: string
    setResult?: (state: AssistantState) => void
}

export type AssistantState = Record<string, any>;