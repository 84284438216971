import { useEffect } from 'react';
import { ManagerHome } from '../../manager/components/home';
import { withMotion } from '../hoc/with-motion';

const FlushPage = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return <></>
}

const AnimatedPage = withMotion(FlushPage);

export const roles: string[] = [];
export const path = '/flush';
export const element = <AnimatedPage />