import { BaseInput } from '@/modules/audio-recorder/util/input/base';
import { ConnectionType } from '../viewer'
import * as RecordingInput from '@/modules/audio-recorder/components/recording-input'
import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { useInputList } from '@/modules/audio-recorder/hooks/use-input-list';
import { useContext, useEffect, useMemo } from 'react';
import { RecorderOutputContext } from '@/modules/audio-recorder/context/recorder-output-context';
import { DefaultConnectionType } from './default-selection';
import styles from '../recording.module.scss';
import { MdFilePresent } from 'react-icons/md';
import { RecordingInputContext } from '@/modules/audio-recorder/context/recording-input-context';

const ConnectionManager: typeof IdbFileRenderer = (props) => {
  const { input } = useContext(RecordingInputContext);
  const connectedOutputs: BaseOutput[] = useMemo(() => props.connections.filter(([i, o]) => i === input).map(([i, o]) => o), [props.connections])

  return (
    <ul style={{display: 'none'}}>
      {(connectedOutputs || []).map((o) => (
        <li key={o.getName()}>{o.getName()}</li>
      ))}
    </ul>
  );
}

const Content: typeof IdbFileRenderer = (props) => {
  return (
    <div className={`${styles.idbFileRenderer}`}>
      <FlexRow center gap='1em'>
        <div className={styles.icon}><MdFilePresent /></div>
        <div className={styles.content}>
          <div><RecordingInput.Name /></div>
          <ConnectionManager {...props} />
          <FlexRow gap='1em' style={{width: '100%'}} spaceBetween center>
            <RecordingInput.Toggle />
            <div className={styles.progress}>
              <RecordingInput.Progress />

              <FlexRow style={{marginTop: -10, fontSize: 12, width: '100%'}} spaceBetween>
                <span><RecordingInput.CurrentPosition /></span>
                <span><RecordingInput.Duration /></span>
              </FlexRow>
            </div>
          </FlexRow>
          <div><RecordingInput.Visualizer width={500} height={5} /></div>
        </div>
      </FlexRow>
    </div>
  );
}

export const IdbFileRenderer = (props: {fileName: string, outputs: Array<BaseOutput>, defaultConnections: DefaultConnectionType, connections: Array<ConnectionType>, addConnection: (i: BaseInput, o: BaseOutput) => void, removeConnection: (i: BaseInput, o: BaseOutput) => void}) => {
  const { fileName } = props;

  return (
      <RecordingInput.Provider name={fileName}>
        <Content {...props} />
      </RecordingInput.Provider>
  )
}