import { useHostingPodcast } from '@/modules/backend/hooks/use-hosting-podcast';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { HostingPodcast, Podcast } from '@nimey/podcast-global-entity';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ProdcastProviderContext = createContext<{podcast: HostingPodcast | undefined}>({podcast: undefined});

export const useLocalHostingPodcast = () => {
  return useContext(ProdcastProviderContext);
}

type HostingProdcastProviderProps = PropsWithChildren<{
  orgId: string;
  podcastId: string;
}>;

export const Title = () => {
  const { podcast } = useLocalHostingPodcast();

  return <>{podcast?.title || ''}</>
}

export const Status = () => {
  const { podcast } = useLocalHostingPodcast();
  const { t } = useTranslation('manager');

  const published = !!podcast?.publishedPodcast;
  if(published) return <>{t('published')}</>
  return <>{t('draft')}</>
}

export const DetailLink = (props: PropsWithChildren) => {
  const { podcast } = useLocalHostingPodcast();

  return <Link to={`/manager/${podcast?.orgId}/hosting-podcast/${podcast?.id}`}>{props.children}</Link>
}

export const HostingPodcastProvider = (props: HostingProdcastProviderProps) => {
  const { podcast } = useHostingPodcast(props.orgId, props.podcastId);

  return (
    <ProdcastProviderContext.Provider value={{podcast}}>
      {props.children}
    </ProdcastProviderContext.Provider>
  );
}