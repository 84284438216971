import { ExternalState } from './external-state';
import { BaseInput } from './input/base';
import { BaseOutput } from './output/base';

export class AudioRecorder {
  protected input: Set<BaseInput> = new Set();
  protected output: Set<BaseOutput> = new Set();
  protected connections: Map<BaseInput, Set<BaseOutput>> = new Map();

  public readonly inputState: ExternalState<Array<BaseInput>>;
  public readonly outputState: ExternalState<Array<BaseOutput>>;
  public readonly connectionState: ExternalState<Array<[BaseInput, BaseOutput]>>;
  public readonly audioDevices: ExternalState<Array<MediaDeviceInfo>>;

  protected audioContext: AudioContext;
  constructor(
    protected sessionId: string
  ) {
    this.audioContext = new AudioContext();
    this.inputState = new ExternalState(Array.from(this.input));
    this.outputState = new ExternalState(Array.from(this.output));
    this.connectionState = new ExternalState<Array<[BaseInput, BaseOutput]>>([]);
    this.audioDevices = new ExternalState<Array<MediaDeviceInfo>>([]);
  }

  getContext() {
    return this.audioContext;
  }

  getSessionId() {
    return this.sessionId;
  }

  addInput(input: BaseInput) {
    this.input.add(input);
    this.inputState.set(Array.from(this.input));
  }

  removeInput(input: BaseInput) {
    const connectedOutputs = this.connections.get(input) || new Set();
    for(const out of connectedOutputs) {
      this.disconnect(input, out);
    }

    this.input.delete(input);
    this.inputState.set(Array.from(this.input));
  }

  getInputArray() {
    return Array.from(this.input);
  }

  addOutput(output: BaseOutput) {
    this.output.add(output);
    this.outputState.set(Array.from(this.output));
  }

  removeOutput(output: BaseOutput) {
    for(const input of this.connections.keys()) {
      for(const out of this.connections.get(input) || []) {
        if(output === out) {
          this.disconnect(input, out);
        }
      }
    }

    this.output.delete(output);
    this.outputState.set(Array.from(this.output));
  }

  getOutputArray() {
    return Array.from(this.output);
  }

  async loadMediaDevices() {
    await navigator.mediaDevices.getUserMedia({audio: true})
    const devices = await navigator.mediaDevices.enumerateDevices();

    const outputs = devices.filter(d => d.kind === 'audiooutput');
    if(outputs.length === 0) {
      const defaultDevice = {
        deviceId: 'rec_default',
        groupId: 'default',
        kind: 'audiooutput' as 'audiooutput',
        label: 'System Default',
      }
      this.audioDevices.set([...devices, {
        ...defaultDevice,
        toJSON: () => defaultDevice,
      }]);
    } else {
      this.audioDevices.set(devices);
    }
  }

  async listAudioInputDevices() {
    await navigator.mediaDevices.getUserMedia({audio: true})
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(d => d.kind === 'audioinput');
  }

  async listAudioOutputDevices() {
    await navigator.mediaDevices.getUserMedia({audio: true})
    const devices = await navigator.mediaDevices.enumerateDevices();
    const filteredDevices = devices.filter(d => d.kind === 'audiooutput');

    if(filteredDevices.length === 0) {
      const defaultDevice = {
        deviceId: 'rec_default',
        groupId: 'default',
        kind: 'audiooutput' as 'audiooutput',
        label: 'System Default',
      }
      return [{
        ...defaultDevice,
        toJSON: () => defaultDevice,
      }]
    }

    return filteredDevices;
  }

  connect(input: BaseInput, output: BaseOutput) {
    input.connectOutput(output);
    const set = this.connections.get(input) || new Set<BaseOutput>();
    set.add(output);
    this.connections.set(input, set);
    this._updateConnectionState();
  }

  disconnect(input: BaseInput, output: BaseOutput) {
    input.disconnectOutput(output);
    const set = this.connections.get(input) || new Set<BaseOutput>();
    set.delete(output);
    this.connections.set(input, set);
    this._updateConnectionState();
  }

  private _updateConnectionState() {
    const newState: Array<[BaseInput, BaseOutput]> = []
    for(const input of this.connections.keys()) {
      for(const output of this.connections.get(input) || []) {
        newState.push([input, output])
      }
    }

    this.connectionState.set(newState);
  }
}