import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { HostingPodcast, HostingPodcastCreatePayload, InternalError } from '@nimey/podcast-global-entity';

export const useHostingPodcast = (orgId?: string, podcastId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext);
  const state = useContext(BackendStateContext);

  const podcastInfo = state.entities['hosting-podcast'] ? state.entities['hosting-podcast'][podcastId || ''] : undefined;
  const podcast = podcastInfo?.entity as HostingPodcast | undefined;

  const reload = (force: boolean = false) => {
    if((!podcast || force) && orgId && podcastId && asyncActions) {
      asyncActions?.loadHostingPodcast(orgId, podcastId).catch((e) => {
        console.error(e)
      });
    }
  };

  useEffect(() => {
    reload();
  }, [orgId, podcastId])

  const add = useCallback(async (p: HostingPodcastCreatePayload) => {
    if(podcastId) {
      throw new InternalError(1724006954, 'can not add podcast with id');
    }

    return await asyncActions?.createHostingPodcast(p);
  }, [podcastId])

  const update = useCallback(async (p: HostingPodcast) => {
    if(!podcastId) {
      throw new InternalError(1724007055, 'can not update podcast without id');
    }

    await asyncActions?.updateHostingPodcast(p);
  }, [podcastId])

  return {
    add,
    update,
    podcast,
    reload,
  }
}