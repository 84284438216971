import { BaseMessage } from './base.js';
export class IceCandidateMessage extends BaseMessage {
    payload;
    constructor(payload) {
        super(payload);
        this.payload = payload;
    }
    get senderId() {
        return this.payload.senderId;
    }
    get candidateJson() {
        return this.payload.candidateJson;
    }
}
