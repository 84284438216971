import { useEffect } from 'react';
import { useValueByPath } from '..';
import { QuestionProps, QuestionType } from '../types'
import { QuestionYesNo } from './yes-no';
import { QuestionSelection } from './selection';
import { QuestionFeedUrl } from './feed-url';
import { QuestionText } from './text';

const questionTypeMap = {
  [QuestionType.YES_NO]: QuestionYesNo,
  [QuestionType.SELECTION]: QuestionSelection,
  [QuestionType.FEED_URL]: QuestionFeedUrl,
  [QuestionType.TEXT_INPUT]: QuestionText,
}

export const Question = (props: QuestionProps) => {
  const { question } = props;
  const [ value, setValue ] = useValueByPath(props.path);
  useEffect(() => {
    return () => setValue(undefined);
  }, [])

  let QuestionRenderer = (props: QuestionProps) => <></>;

  if(questionTypeMap[question.type]) QuestionRenderer = questionTypeMap[question.type]


  return (
      <QuestionRenderer {...props} />
  );
}
