import { useHostingEpisode } from '@/modules/backend/hooks/use-hosting-episode';
import { HostingEpisode } from '@nimey/podcast-global-entity';
import { PropsWithChildren, createContext, useContext } from 'react';
import Markdown from 'react-markdown';
import { DateDuration } from '../date-duration';
import { Link as RouterLink } from 'react-router-dom';
import { useLocalHostingPodcast } from './hosting-podcast-provider';

export const ProdcastProviderContext = createContext<{episode: HostingEpisode | undefined}>({episode: undefined});

export const useLocalHostingEpisode = () => {
  return useContext(ProdcastProviderContext);
}

type HostingEpisodeProviderProps = PropsWithChildren<{
  orgId: string;
  podcastId: string;
  episodeId: string;
}>;

export const Description = () => {
  const { episode } = useLocalHostingEpisode();

  return <Markdown>{episode?.description || ''}</Markdown>
}

export const Title = () => {
  const { episode } = useLocalHostingEpisode();

  return <>{episode?.title || ''}</>
}

export const PubDate = () => {
  const { episode } = useLocalHostingEpisode();

  if(!episode) return <></>;

  return <DateDuration fromTimestamp={episode.pubDate} />
}

export const PublishedText = () => {
  const { episode } = useLocalHostingEpisode();

  if(!episode) return <></>;
  if(episode.published) return <>Öffentlich</>;
  return <>Entwurf</>;
}

export const Link = ({ children }: PropsWithChildren) => {
  const { episode } = useLocalHostingEpisode();
  const { podcast } = useLocalHostingPodcast();

  if(!episode) return <></>;

  return <RouterLink to={`/manager/${podcast?.orgId}/hosting-podcast/${podcast?.id}/episode/${episode.id}`}>{children}</RouterLink>
}

export const HostingEpisodeProvider = (props: HostingEpisodeProviderProps) => {
  const { episode } = useHostingEpisode(props.orgId, props.podcastId, props.episodeId);
  return (
    <ProdcastProviderContext.Provider value={{episode}}>
      {props.children}
    </ProdcastProviderContext.Provider>
  );
}