import { useEffect, useRef, useState } from 'react'
import { CheckProps, CheckStatus } from './main'
import { IdbStorage } from '@/modules/idb-file-storage/util/idb-storage';

const runIdbTest = async (addMesage: (s: string) => void) => {
  const prefix = `check-${Date.now()}`
  const idb = new IdbStorage('rec-test-db', prefix)
  await idb.init();
  addMesage('Datenbank wurde erstellt');
  const testFileName = 'test';
  const fileWriter = await idb.getFileWriter(testFileName);
  const messages = [
    'hello', 'world', 'test', '123', '456'
  ];
  addMesage('Daten in die Datenbank schreiben');
  for(const m of messages) {
    await fileWriter.addChunk(new Blob([m, ' '], {type: 'text/plain'}))
  }

  addMesage('Daten aus der Datenbank lesen');
  const reader = await idb.getFileReader(testFileName);
  const data = await reader.readBlob();
  const blobText =  await data.text()

  if(blobText.trim() !== messages.join(' ').trim()) throw 'Abweichung zwischen Lesen / Schreiben'

  indexedDB.deleteDatabase('rec-test-db')
}

export const CheckIdb = (props: CheckProps) => {
  const {check, setCheckStatus} = props;
  const [ error, setError ] = useState<string>('');
  const [ messages, setMessages ] = useState<Array<string>>([]);
  const started = useRef(false);

  useEffect(() => {
    if(check.status === CheckStatus.RUNNING) {
      if(started.current) return;
      started.current = true;
      const addMesage = (m: string) => { setMessages(ms => [...ms, m]) };
      const runCheck = async () => {
        await runIdbTest(addMesage)
      }

      runCheck()
        .then(() => {
          setCheckStatus(CheckStatus.PASSED)
        })
        .catch((e) => {
          if(e instanceof Error) {
            console.log(e.name)
            if(e.name === 'NotAllowedError') {
              setError('Keine Berechtigung für den Zugriff auf deine Geräte');
              setCheckStatus(CheckStatus.FAILED);
              return;
            }
          }
          setError(String(e))
          setCheckStatus(CheckStatus.FAILED);
        })

    }
  })

  return (
    <>
      {check.status === CheckStatus.RUNNING ? <p>Dein Browser wird getestet</p> : ''}
      <div style={{fontSize: 'smaller', marginLeft: '1em', maxHeight: check.status === CheckStatus.RUNNING ? 1000 : 0, transition: 'max-height .2s ease', overflow: 'hidden'}}>
        {messages.map((m, i) => <p style={{margin: 0}} key={i}>{m}</p>)}
      </div>
      <p style={{color: 'red'}}>{error}</p>
    </>
  )
}