import { Variants, motion } from 'framer-motion';
import { useHistory } from '../hooks/use-history';
import { useLocation } from 'react-router-dom';

const defaultVariants: Record<string, Variants> = {
  forward: {
    initial: {
      x: '100vw'
    },
    final: {
        x: '0vw',
        transition: {
          type: 'spring',
          mass: .4,
        }
    },
  },
  back: {
    initial: {
      x: '-100vw'
  },
    final: {
        x: '0vw',
        transition: {
          type: 'spring',
          mass: .4,
        }
    },
  },
  load: {
    initial: {
      opacity: 0,
      scale: .8,
    },
    final: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: .3
      }
    }
  }
}

export function withMotion<T extends object>(Component: React.ComponentType<T>) {
  return (props: Omit<T, keyof T>)  => {
    const history = useHistory();
    const mode = !history.hasNavigated ? 'load' : (history.action === 'PUSH' ? 'forward' : 'back');

    return (
      <motion.div
        variants={defaultVariants[mode]}
        initial='initial'
        animate='final'
        style={{height: '100%'}}
      >
        <Component {...(props as T)} />
      </motion.div>
    );
  }
}