import { ValidationError } from '../../error/validation-error.js';
export var ParticipantType;
(function (ParticipantType) {
    ParticipantType["GUEST"] = "guest";
    ParticipantType["USER"] = "user";
})(ParticipantType || (ParticipantType = {}));
export const assertRecordingSessionCreatePayloadValid = (org) => {
    // if(!org.name) throw new ValidationError(1, 'org name is required');
    // if(typeof org.name !== 'string') throw new ValidationError(1714474306, 'org name must be string');
};
export const assertRecordingSessionValid = (org) => {
    if (!org.id)
        throw new ValidationError(1, 'org id is required');
    assertRecordingSessionCreatePayloadValid(org);
};
