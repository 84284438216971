import { HostingPodcast, HostingPodcastOwner, Organization, Podcast, PodcastCategory, PodcastCategoryMap, PodcastLanguage } from '@nimey/podcast-global-entity';
import { withCurrentOrg } from '../../hoc/with-current-org';
import { useParams } from 'react-router-dom';
import * as HostingPodcastProvider from '@/modules/global/components/podcast/hosting-podcast-provider';
import * as HostingEpisode from '@/modules/global/components/podcast/hosting-episode-provider';
import { Gutter } from '@/modules/layout/components/gutter';
import { Spinner } from '@/modules/layout/components/spinner';
import { Fragment } from 'react/jsx-runtime';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Button } from '@nimey/react-ui';
import styles from './hosting-podcast.module.scss';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from '@/modules/layout/components/modal';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Hr } from '@/modules/layout/components/hr';
import { useHostingPodcast } from '@/modules/backend/hooks/use-hosting-podcast';
import { useHostingPodcastImage } from '@/modules/backend/hooks/use-hosting-podcast-image';
import { MdAdd, MdDetails, MdEdit, MdInfo } from 'react-icons/md';
import { useHostingEpisode } from '@/modules/backend/hooks/use-hosting-episode';
import { useHostingEpisodeList } from '@/modules/backend/hooks/use-hosting-episode-list';
import { LoadMore } from '@/modules/layout/components/load-more';
import { useHashStateFlag } from '@/modules/global/hooks/use-hash-state-flag';
import { DateDuration } from '@/modules/global/components/date-duration';

const FormContext = createContext<{
  hasChanges: boolean,
  setHasChanges: Dispatch<SetStateAction<boolean>>,
  podcast?: HostingPodcast,
  setPodcast: Dispatch<SetStateAction<HostingPodcast | undefined>>,
  image: {buffer?: ArrayBuffer, preview?: string} | undefined,
  setImage: Dispatch<SetStateAction<{buffer?: ArrayBuffer, preview?: string, changed?: boolean} | undefined>>,


} | undefined>(undefined)

function useFormPodcastField<T extends keyof HostingPodcast>(fieldName: T): [
  HostingPodcast[T] | undefined,
  (v: HostingPodcast[T]) => void
] {
  const ctx = useContext(FormContext);
  return [
    ctx?.podcast ? ctx.podcast[fieldName] : undefined,
    (newValue: HostingPodcast[T]) => {
      if(!ctx?.podcast) return;
      ctx?.setPodcast(p => (p ? {...p, [fieldName]: newValue} : undefined));
      if(ctx.podcast) ctx.setHasChanges(true);
    } 
  ]
}

function useFormPodcastOwnerField<T extends keyof HostingPodcastOwner>(fieldName: T): [
  HostingPodcastOwner[T] | undefined,
  (v: HostingPodcastOwner[T]) => void
] {
  const ctx = useContext(FormContext);
  return [
    ctx?.podcast?.owner ? ctx.podcast.owner[fieldName] : undefined,
    (newValue: HostingPodcastOwner[T]) => {
      if(!ctx?.podcast) return;
      ctx?.setPodcast(p => (p ? {...p, owner: {...p.owner, [fieldName]: newValue}} : undefined));
      if(ctx.podcast) ctx.setHasChanges(true);
    } 
  ]
}

const EpisodeList = (props: {podcast: HostingPodcast}) => {
  const { podcast } = props;
  const { allIds, hasMore, loadMore } = useHostingEpisodeList(podcast.orgId, podcast.id);
  return (
    <HostingPodcastProvider.HostingPodcastProvider orgId={podcast.orgId} podcastId={podcast.id}>
      <ul className={styles['episode-list']}>
        {allIds.map((episodeId => 
          <li key={episodeId} className={styles.episode}>
            <HostingEpisode.HostingEpisodeProvider orgId={podcast.orgId} podcastId={podcast.id} episodeId={episodeId}>
              <HostingEpisode.Link>
                <FlexRow center spaceBetween>
                  <HostingEpisode.Title />
                  <div>
                    <HostingEpisode.PublishedText />
                    {' '}
                    <HostingEpisode.PubDate />
                  </div>
                </FlexRow>
              </HostingEpisode.Link>
            </HostingEpisode.HostingEpisodeProvider>
          </li>
        ))}
      </ul>
      <LoadMore {...{hasMore, loadMore}} />
    </HostingPodcastProvider.HostingPodcastProvider>
  );
}


const AddEpisode = (props: {podcast: HostingPodcast}) => {
  const { podcast } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [ show, setShow ] = useState<boolean>(false);
  const { add } = useHostingEpisode(podcast.orgId, podcast.id)
  const [ title, setTitle ] = useState<string>('');
  const [ description, setDescription ] = useState<string>('');
  const [ error, setError ] = useState<string>('');

  return (
    <Fragment>
      <Button onClick={_ => setShow(true)}><MdAdd /></Button>
      <Modal
        isOpen={show}
        close={() => setShow(false)}
      >
        <p>{error}</p>
        <form onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          setError('')
          add({
            hostingPodcastId: podcast.id,
            title,
            pubDate: Date.now(),
            description
          }).then(() => {
            setLoading(false);
            setTitle('')
            setDescription('')
            setShow(false);
          }).catch(e => {
            setError(e.toString());
            setLoading(false);
          })
        }}>
        <div style={{margin: '1em 0'}}><input required value={title} onChange={(e) => setTitle(e.currentTarget.value)} className={styles['input-element']} type='text' name='title' placeholder='Title' /></div>
        <div style={{margin: '1em 0'}}><textarea rows={10} required value={description} onChange={(e) => setDescription(e.currentTarget.value)} className={styles['input-element']} name='description' placeholder='Beschreibung' /></div>
        <FlexRow style={{justifyContent: 'flex-end', gap: '1em'}}>
          <Button disabled={loading} onClick={() => {
            setTitle('')
            setDescription('')
            setShow(false);
          }}>abbrechen</Button>
          <Button disabled={loading} type='submit' primary>Hinzufügen</Button>
        </FlexRow>
        </form>
      </Modal>
    </Fragment>
  )
}

const FieldWithEditor = (props: {fieldName: keyof HostingPodcast}) => {
  const [ value, setValue ] = useFormPodcastField(props.fieldName);
  const [ edit, setEdit ] = useState<boolean>(false);

  if(typeof value !== 'string' && typeof value !== 'undefined') return <></>

  return (
    <Fragment>
      <span onClick={(e => setEdit(true))}>{value || 'Nicht angegeben'}</span>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
        fitContent={true}
      >
        <div style={{height: 100}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            setEdit(false);
          }}>
            <input
              className={styles['input-element']}
              autoFocus={true}
              type='text'
              value={value || ''}
              onChange={ e => setValue(e.currentTarget.value) }
            />
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

const OwnerFieldWithEditor = (props: {fieldName: keyof HostingPodcastOwner}) => {
  const [ value, setValue ] = useFormPodcastOwnerField(props.fieldName);
  const [ edit, setEdit ] = useState<boolean>(false);

  if(typeof value !== 'string' && typeof value !== 'undefined') return <></>

  return (
    <Fragment>
      <span onClick={(e => setEdit(true))}>{value || 'Nicht angegeben'}</span>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
        fitContent={true}
      >
        <div style={{height: 100}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            setEdit(false);
          }}>
            <input
              className={styles['input-element']}
              autoFocus={true}
              type='text'
              value={value || ''}
              onChange={ e => setValue(e.currentTarget.value) }
            />
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

const LanguageSelector = () => {
  const [ value, setValue ] = useFormPodcastField('languages');
  const [ edit, setEdit ] = useState<boolean>(false);
  const selectedLangauges = value || [];

  const sortedLanguages = useMemo(() => {
    const l = Object.values(PodcastLanguage).filter(e => !selectedLangauges.includes(e));
    l.sort()

    return l
  }, [selectedLangauges])
  return (
    <Fragment>
      <div onClick={_ => setEdit(true)}>{selectedLangauges.length > 0 ? selectedLangauges.join(', ') : 'Nicht angegeben'}</div>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
      >
        <div style={{minHeight: '4em'}}>
          <select
            onChange={(e) => {
              setValue(Array.from(new Set([...selectedLangauges, e.currentTarget.value])) as PodcastLanguage[])
            }}
          >
            <option></option>
            {sortedLanguages.map(l => (
              <option key={l} value={l}>{l}</option>
            ))}
          </select>
          <ul>
            {selectedLangauges.map(lang => (
              <li onClick={() => {
                setValue(selectedLangauges.filter(l => l !== lang))
              }} key={lang}>{lang}</li>
            ))}
          </ul>
        </div>
      </Modal>
    </Fragment>
  );
}
const CategorySelector = () => {
  const [ value, setValue ] = useFormPodcastField('categories');
  const [ edit, setEdit ] = useState<boolean>(false);
  const selectedCategories = value || [];
  const categoryTranslation = useTranslation('category');
  const categoryTree = useMemo(() => {
    const cats: Record<string, {
      category: PodcastCategory,
      children: Array<PodcastCategory>
    }> = {};
    for(const cat of Object.keys(PodcastCategoryMap)) {
      const [category, subCategory] = cat.split('>').map(c => c.trim());
      if(category && PodcastCategoryMap[category]) {
        cats[category] = cats[category] || {
          category: PodcastCategoryMap[category],
          children: []
        }
      }

      if(subCategory && PodcastCategoryMap[category]) {
        cats[category] = cats[category] || {
          category: PodcastCategoryMap[category],
          children: []
        }
        cats[category].children.push(PodcastCategoryMap[cat])
      }
    }

    const result: Array<{category: PodcastCategory, children: Array<PodcastCategory>}> = []
    for(const key in cats) {
      const cat = cats[key]
      result.push({
        category: cat.category,
        children: cat.children || []
      })
    }

    return result;
  }, [selectedCategories])
  return (
    <Fragment>
      <div onClick={_ => setEdit(true)}>{selectedCategories.length > 0 ? selectedCategories.map(c => categoryTranslation.t(c)).join(', ') : 'Nicht angegeben'}</div>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
      >
        <div style={{minHeight: '4em'}}>
          <ul>
            {categoryTree.map(row => (
              <li key={row.category}>
                <label><input type='checkbox' checked={selectedCategories.includes(row.category)} onChange={e => setValue(e.target.checked ? [...selectedCategories, row.category] : selectedCategories.filter(c => c !== row.category))} />{categoryTranslation.t(row.category)}</label>
                {row.children && row.children.length > 0 ? (
                  <ul>
                    {row.children.map(child => (
                      <li key={child}>
                        <label><input type='checkbox' checked={selectedCategories.includes(child)} onChange={e => setValue(e.target.checked ? [...selectedCategories, child] : selectedCategories.filter(c => c !== child))} />{categoryTranslation.t(child)}</label>
                      </li>
                    ))}
                  </ul>
                ) : ''}
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </Fragment>
  );
}

const Description = () => {
  const [ edit, setEdit ] = useState(false);
  const [ value, setValue ] = useFormPodcastField('description');
  return (
    <Fragment>
      <div onClick={_ => setEdit(true)}><Markdown>{value || 'Nicht angegeben'}</Markdown></div>
      <Modal
        close={() => setEdit(false)}
        isOpen={edit}
        fitContent={false}
      >
        <div>
          <form onSubmit={(e) => {
            e.preventDefault();
            setEdit(false);
          }}>
            <textarea
              className={`${styles['input-element']} ${styles['textarea-element']}`}
              autoFocus={true}
              value={value || ''}
              onChange={ e => setValue(e.currentTarget.value) }
            />
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}

const Artwork = () => {
  const ctx = useContext(FormContext);

  return (
    <>
      <label className={styles['artwork-label']}>
        <input type='file'
          style={{width: 0, height: 0, visibility: 'hidden'}}
          onChange={(e) => {
            if(!e.target.files) return;
            const file = e.target.files[0];
            const bufferPromise = new Promise<ArrayBuffer>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = (data) => {
                resolve(reader.result as ArrayBuffer);
              }
              reader.readAsArrayBuffer(file);
            })
            
            const previewPromise = new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = (data) => {
                resolve(reader.result as string);
              }
              reader.readAsDataURL(file);
            })

            Promise.all([bufferPromise, previewPromise]).then(([buffer, preview]) => {
              ctx?.setImage({buffer, preview, changed: true})
              ctx?.setHasChanges(true);
            })
          }}
        />
        {ctx?.image?.preview ? (
          <img className={styles['artwork-image']} src={ctx?.image?.preview} />
        ) : ''}
      </label>
    </>
  );
}

const Detail = () => {
  const { podcast } = HostingPodcastProvider.useLocalHostingPodcast();
  const hostingPodcast = useHostingPodcast(podcast?.orgId, podcast?.id)
  const [ statePodcast, setStatePodcast ] = useState<HostingPodcast | undefined>(podcast);
  const [ statePodcastImage, setStatePodcastImage ] = useState<{buffer?: ArrayBuffer, preview?: string, changed?: boolean} | undefined>();
  const [ hasChanges, setHasChanges ] = useState<boolean>(false);
  const podcastImage = useHostingPodcastImage(podcast?.orgId, podcast?.id);

  useEffect(() => { setStatePodcastImage(s => ({...(s || {}), preview: podcastImage.preview})) }, [podcastImage.preview]);
  useEffect(() => { setStatePodcastImage(s => ({...(s || {}), buffer: podcastImage.buffer})) }, [podcastImage.buffer]);
  useEffect(() => { if(podcast?.id) podcastImage.load() }, [podcast]);

  useEffect(() => {
    setStatePodcast(podcast?.id ? {...podcast} : undefined);
  }, [podcast?.id])

  return (
    <FormContext.Provider value={{hasChanges, setHasChanges, podcast: statePodcast, setPodcast: setStatePodcast, image: statePodcastImage, setImage: setStatePodcastImage}}>
      <div className={styles.content}>
        <div className={styles.main}>
          <h1><FieldWithEditor fieldName='title' /></h1>
          <h2>Allgemeine Informationen</h2>
          <div className={styles['artwork-cols']}>
            <div className={styles['artwork-wrapper']}><Artwork /></div>
            <div className={styles.general}>
              <div className={`${styles.cell} ${styles.info}`}>Author</div>
              <div className={`${styles.cell} ${styles.input}`}><FieldWithEditor fieldName='author' /></div>
              <div className={`${styles.cell} ${styles.info}`}>Link</div>
              <div className={`${styles.cell} ${styles.input}`}><FieldWithEditor fieldName='link' /></div>
              <div className={`${styles.cell} ${styles.info}`}>Katgorien</div>
              <div className={`${styles.cell} ${styles.input}`}><CategorySelector /></div>
              <div className={`${styles.cell} ${styles.info}`}>Sprachen</div>
              <div className={`${styles.cell} ${styles.input}`}><LanguageSelector /></div>

              <div className={`${styles.cell} ${styles.info}`}>Inhaber Name</div>
              <div className={`${styles.cell} ${styles.input}`}><OwnerFieldWithEditor fieldName='name' /></div>
              <div className={`${styles.cell} ${styles.info}`}>Inhaber E-Mail</div>
              <div className={`${styles.cell} ${styles.input}`}><OwnerFieldWithEditor fieldName='email' /></div>
            </div>
          </div>
          <div>
            <h2>Beschreibung</h2>
            <Description />
          </div>

          <Hr />

          <FlexRow center spaceBetween>
            <h2>Episoden</h2>
            {hostingPodcast.podcast ? <AddEpisode {...{podcast: hostingPodcast.podcast}} /> : ''}
          </FlexRow>
          {hostingPodcast.podcast ? <EpisodeList {...{podcast: hostingPodcast.podcast}} /> : ''}
        </div>
        <div className={styles.sidebar}>
          <FlexRow style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Button onClick={() => {
              if(!statePodcast) return;
              (async () => {
                if(statePodcastImage?.changed && statePodcastImage?.buffer) {
                  await podcastImage.save(statePodcastImage.buffer);
                }
                await hostingPodcast.update(statePodcast);
                setStatePodcastImage(s => ({...s, changed: false}))
                setHasChanges(false)
              })()
            }} disabled={!hasChanges} primary>Speichern</Button>
            <Button disabled={!hasChanges}>Veröffentlichen</Button>
          </FlexRow>
        </div>
      </div>
    </FormContext.Provider>
  );
}

const OrgHostingPodcastWithOrg = (props: {org: Organization}) => {
  const { podcastId } = useParams();

  if(!props.org || !podcastId) return <Gutter><Spinner size='2em' /></Gutter>

  return (
    <Gutter>
      <HostingPodcastProvider.HostingPodcastProvider orgId={props.org.id} podcastId={podcastId}>
        <Detail />
      </HostingPodcastProvider.HostingPodcastProvider>
    </Gutter>
  )
}

export const OrgHostingPodcast = withCurrentOrg<{}>(OrgHostingPodcastWithOrg);