import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';

export const useHostingEpisodeList = (orgId: string, podcastId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = podcastId;
  
  return useEntityList({orgId, podcastId}, 'hosting-episode', async (filter: {orgId: string, podcastId: string}, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.loadHostingEpisodeList(filter.orgId, filter.podcastId, pageNumber, pageSize)
  }, filterKey)
}