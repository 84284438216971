import { useRecordingSession } from '@/modules/backend/hooks/use-recording-session';
import { useRecordingSessionList } from '@/modules/backend/hooks/use-recording-session-list';
import { Gutter } from '@/modules/layout/components/gutter';
import { LoadMore } from '@/modules/layout/components/load-more';
import { withCurrentOrg } from '@/modules/manager/hoc/with-current-org';
import { Organization, Participant, ParticipantType } from '@nimey/podcast-global-entity';
import { Button, DateInput, DateTimeInput, TextInput } from '@nimey/react-ui';
import * as RS from './provider';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { Duration } from '@nimey/units';
import { UserNameInfo } from '@/modules/global/components/user/user-name-info';

import styles from './list.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { useCurrentAccount } from '@/modules/auth/hooks/use-current-account';
import { Modal } from '@/modules/layout/components/modal';
import { useUserSearch } from '@/modules/backend/hooks/use-user-search';
import { useDebounce } from '@/modules/global/hooks/use-debounce';
import { MdAccountBox, MdAdd, MdDelete, MdNoAccounts, MdRemove } from 'react-icons/md';

const RecordingRenderer = () => {
  const ctx = useContext(RS.RecordingSessionContext);
  const [due, setDue] = useState<'due' | 'past' | 'expired'>('expired');
  useEffect(() => {
    const check = () => {
      const startsIn = (ctx.recordingSession?.startDate || Date.now()) - Date.now();
      let mode: 'due' | 'past' | 'expired' = 'due';
      if(startsIn > 0) {
        mode = 'due';
      } else {
        if((startsIn * -1) < +Duration.minutes(30)) mode = 'due';
        else if((startsIn * -1) < +Duration.hours(4)) mode = 'past';
        else mode = 'expired';
      }

      if(due !== mode) setDue(mode);
    }

    check();
    const inter = setInterval(check, +Duration.seconds(30));
    return () => {
      if(inter) clearInterval(inter);
    }
  }, [ctx.recordingSession])

  return (
    <>
      <li className={`${styles.recording} ${styles[due]}`}>
        <FlexRow center spaceBetween>
          <p><strong><RS.Title /></strong></p>
          <div className={styles.startDate}>
            <RS.StartDate format={RS.StartDateFormat.DURATION} />
          </div>
        </FlexRow>

        <div className={styles.participantList}>
          <ul>
            <RS.Participants row={({ participant }) => (
              <li>
                {participant.type === ParticipantType.USER ? (
                  <UserNameInfo userId={participant.identifier} />
                ) : ''}

                {participant.type === ParticipantType.GUEST ? (
                  <span>{participant.name ? `${participant.name} (${participant.identifier})` : participant.identifier}</span>
                ) : ''}
              </li>
            )} />
          </ul>
        </div>
        <FlexRow spaceBetween>
          <span />
          <RS.Link><Button primary><RS.IsFinished>Zum Download</RS.IsFinished><RS.IsOpen>Zur Session</RS.IsOpen></Button></RS.Link>
        </FlexRow>
      </li>
      {due === 'due' ? <hr style={{width: '100%'}} /> : ''}
    </>
  );
}

const CreateSession: typeof OrgRecordingListWithOrg = (props) => {
  const acc = useCurrentAccount();
  const { add } = useRecordingSession(props.org.id);
  const [ participants, setParticipants ] = useState<Array<Participant>>([
    {identifier: acc!.id, name: acc?.name, type: ParticipantType.USER, isHost: true}
  ]);
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const [ name, setName] = useState<string>('');
  const [ email, setEmail] = useState<string>('');
  const [ error, setError ] = useState<string>('');
  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Session planen</Button>
      <Modal {...{isOpen, close: () => setIsOpen(false)}} title='Neue Session planen'>
        <div style={{padding: '1em'}}>
          Teilnehmer
          <ul>
            {participants.map(participant => (
              <li>
                <FlexRow center gap='.5em'>
                  {participant.type === ParticipantType.USER ? (
                    <>
                      <MdAccountBox />
                      <UserNameInfo userId={participant.identifier} />
                    </>
                  ) : (
                    <>
                      <MdNoAccounts />
                      <span>{participant.name} ({participant.identifier})</span>
                    </>
                  )}
                  <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => {
                    setParticipants((parti) => [...parti.filter(p => p.identifier !== participant.identifier)])
                  }}><MdDelete /></button>
                </FlexRow>
              </li>
            ))}
          </ul>
          <p>Gäste einladen:</p>
          <form onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const { name, email } = Object.fromEntries(formData.entries());
            setParticipants(parti => [...parti.filter(p => p.identifier !== email), {
              identifier: email as string, name: name as string, isHost: false, type: ParticipantType.GUEST,
            }])

            setName('');
            setEmail('');
          }}>
            <FlexRow gap='1em' center>
              <TextInput value={name} onChange={(e) => setName(e)} required name='name' placeholder='Name' />
              <TextInput value={email} onChange={(e) => setEmail(e)} required name='email' placeholder='E-Mail' />
              <button style={{backgroundColor: 'transparent', border: 'none', fontSize: '1.5em'}}><MdAdd /></button>
            </FlexRow>
          </form>
          <div style={{height: '1em'}} /><hr /><div style={{height: '1em'}} />
          <form onSubmit={(e) => {
            e.preventDefault();

            if(name || email) {
              setError('Du hast noch nicht alle Gäste vollständig hinzugefügt')
              return;
            }

            const formData = Object.fromEntries(new FormData(e.currentTarget).entries());
            const session = {
              orgId: props.org.id,
              participants,
              startDate: new Date(formData.startDate as string).getTime(),
              title: formData.title as string,
            };
            e.currentTarget.reset();
            add(session).then(() => {
              setParticipants([{identifier: acc!.id, name: acc?.name, type: ParticipantType.USER, isHost: true}]);
              setIsOpen(false);
            });
            
          }}>
            <div className={styles.createRecordingSession}>
              <div style={{padding: '.5em 0'}}>
                <TextInput required name='title' placeholder='Title der Aufnahme'  />
              </div>
              <div style={{padding: '.5em 0'}}>
                <DateTimeInput required name='startDate' placeholder='Beginn der Aufnahme' />
              </div>
            </div>
            <FlexRow spaceBetween center>
              <span style={{color: 'red'}}>{error}</span>
              <Button type='submit' primary>Einladung verschicken</Button>
            </FlexRow>
          </form>
        </div>

      </Modal>
    </>
  )
}


const OrgRecordingListWithOrg = (props: {org: Organization}) => {
  
  const { allIds, hasMore, loadMore} = useRecordingSessionList(props.org.id)
  const { add } = useRecordingSession(props.org.id);
  return (
    <Gutter>
      <FlexRow spaceBetween center style={{marginTop: '1.5em'}}>
        <h1 style={{margin: 0}}>Aufnahmen verwalten</h1>
        <CreateSession {...props} />
      </FlexRow>

      <ul className={styles.recordingList}>
        {allIds.map(sessionId => (
          <RS.Provider key={sessionId} orgId={props.org.id} sessionId={sessionId}>
            <RecordingRenderer />
          </RS.Provider>
        ))}
        <LoadMore {...{hasMore, loadMore}} />
      </ul>
    </Gutter>
  );
}

export const OrgRecordingList = withCurrentOrg(OrgRecordingListWithOrg);