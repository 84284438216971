import { CSSProperties, PropsWithChildren } from 'react';

export type FlexRowProps = {
  style?: CSSProperties;
  className?: string;
  spaceBetween?: boolean;
  center?: boolean;
  gap?: string | number;
}

export const FlexRow = (props: PropsWithChildren<FlexRowProps>) => (
  <div
    className={props.className}
    style={{
      ...(props.style || {}),
      display: 'flex',
      ...(props.spaceBetween ? {justifyContent: 'space-between'} : {}),
      ...(props.center ? {alignItems: 'center'} : {}),
      ...(props.gap ? {gap: props.gap} : {}),
    }}
  >
    {props.children}
  </div>
)