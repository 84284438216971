import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { AudioRecorder } from '../util/audio-recorder';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { Button } from '@nimey/react-ui';
import { useRecordingSession } from '@/modules/backend/hooks/use-recording-session';
import { useCurrentOrg } from '@/modules/manager/components/hooks/use-current-org';
import { SystemCheck } from './system-check/main';

export type AudioRecorderProviderProps = {
  sessionId: string;
}
class RecorderProvider {
  protected instances: Record<string, AudioRecorder> = {};
  getRecorder(sessionId: string) {
    const key = sessionId;
    if(!this.instances[key]) this.instances[key] = new AudioRecorder(sessionId);

    return this.instances[key];
  }
}

const recorderProvider = new RecorderProvider();

const AudioRecorderProviderStarted = ({ sessionId, children }: PropsWithChildren<AudioRecorderProviderProps>) => {
  const recorder = recorderProvider.getRecorder(sessionId);
  if(!sessionId || !recorder) return <></>;

  return (
    <AudioRecorderContext.Provider value={{recorder: recorder}}>{children}</AudioRecorderContext.Provider>
  );
}

export const AudioRecorderProvider = (props: PropsWithChildren<AudioRecorderProviderProps>) => {
  const [started, setStarted] = useState<boolean>(false);

  if (!started) return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
      <SystemCheck>
        <Button onClick={() => setStarted(true)}><span style={{fontSize: '2em'}}>Jetzt teilnehmen</span></Button>
      </SystemCheck>
    </div>
  );

  return <AudioRecorderProviderStarted {...props} />
}