import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { Gutter } from '@/modules/layout/components/gutter';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { FeatureGuard } from '@/modules/backend/components/feature-guard';
import { FeatureFlag } from '@nimey/podcast-global-entity';

const AppTile = (props: {title: string, href: string}) => {
  return (
    <Link to={props.href} style={{display: 'block', boxShadow: '2px 2px 10px -5px var(--text-color)', padding: '1em', width: '100%', minWidth: 200}}>
      <h2>{props.title}</h2>
    </Link>
  );
}

export const ManagerOrg = () => {
  const { orgId } = useParams();
  const { organization, load } = useOrganization(orgId!);
  useEffect(() => { load() }, [orgId, load]);
  return (
    <Gutter>
      <FlexRow style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <h1>{organization?.name}</h1>
      </FlexRow>
      <p>Was möchtest du verwalten?</p>
      <div style={{display: 'flex', gap: '1em', flexWrap: 'wrap', flexDirection: 'column'}}>
        <FeatureGuard flag={FeatureFlag.MANAGER_HOSTING}>
          <AppTile title='Podcast Hosting' href='hosting' />
        </FeatureGuard>
        <AppTile title='Podcast (Extern)' href='podcast' />
        <FeatureGuard flag={FeatureFlag.MANAGER_RECORDING}>
          <AppTile title='Aufnahmen' href='recording' />
        </FeatureGuard>
      </div>
      {/* <OrgPodcastList /> */}
    </Gutter>
  );
}