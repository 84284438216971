import { BaseController } from './base.controller.js';
export class HostingController extends BaseController {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    getPodcastById(orgId, podcastId) {
        return this.client.get(`/hosting/org/${orgId}/podcast/${podcastId}`);
    }
    getPodcastList(orgId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/hosting/org/${orgId}/podcast`, params);
    }
    createPodcast(podcast) {
        return this.client.post(`/hosting/org/${podcast.orgId}/podcast`, podcast);
    }
    updatePodcast(podcast) {
        return this.client.put(`/hosting/org/${podcast.orgId}/podcast/${podcast.id}`, podcast);
    }
    getEpisodeById(orgId, podcastId, episodeId) {
        return this.client.get(`/hosting/org/${orgId}/podcast/${podcastId}/episode/${episodeId}`);
    }
    getEpisodeList(orgId, podcastId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/hosting/org/${orgId}/podcast/${podcastId}/episode`, params);
    }
    createEpisode(episode) {
        return this.client.post(`/hosting/org/---/podcast/${episode.hostingPodcastId}/episode`, episode);
    }
    updateEpisode(episode) {
        return this.client.put(`/hosting/org/---/podcast/${episode.hostingPodcastId}/episode/${episode.id}`, episode);
    }
    deleteEpisode(podcastId, episodeId) {
        return this.client.delete(`/hosting/org/---/podcast/${podcastId}/episode/${episodeId}`);
    }
    uploadPodcastImage(orgId, podcastId, payload) {
        return this.client.postFile(`/hosting/org/${orgId}/podcast/${podcastId}/artwork`, payload);
    }
    downloadPodcastImage(orgId, podcastId) {
        return this.client.getFile(`/hosting/org/${orgId}/podcast/${podcastId}/artwork`);
    }
    uploadEpisodeMedia(orgId, podcastId, episodeId, payload) {
        return this.client.postFile(`/hosting/org/${orgId}/podcast/${podcastId}/episode/${episodeId}/media`, payload);
    }
    downloadEpisodeMedia(orgId, podcastId, episodeId) {
        return this.client.getFile(`/hosting/org/${orgId}/podcast/${podcastId}/episode/${episodeId}/media`);
    }
    getRecordingSessionById(orgId, sessionId) {
        return this.client.get(`/hosting/org/${orgId}/recording/${sessionId}`);
    }
    listRecordingSessions(orgId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/hosting/org/${orgId}/recording`, params);
    }
    createRecordingSession(orgId, session) {
        return this.client.post(`/hosting/org/${orgId}/recording`, session);
    }
    updateRecordingSession(orgId, session) {
        return this.client.put(`/hosting/org/${orgId}/recording/${session.id}`, session);
    }
    deleteRecordingSession(podcastId, episodeId) {
        return this.client.delete(`/hosting/org/---/podcast/${podcastId}/episode/${episodeId}`);
    }
    async uploadRecordingMedia(orgId, sessionId, userId, userName, fileName, payload, sessionToken) {
        const token = await this.client.put(`/hosting/org/${orgId}/recording/${sessionId}/${userId}/${userName}/${fileName}`, {}, {
            ...(sessionToken ? ({ 'x-nimey-signed-token': sessionToken }) : {})
        });
        return this.client.postFile(`/hosting/org/${orgId}/recording/${sessionId}/${userId}/${userName}/${fileName}`, payload, {}, {
            'x-nimey-signed-token': token.response
        });
    }
    listRecordingSessionMedia(orgId, sessionId, skip = 0, limit = 5) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });
        return this.client.get(`/hosting/org/${orgId}/recording/${sessionId}/media`, params);
    }
    downloadRecordingSessionMedia(orgId, sessionId, mediaId) {
        return this.client.getFile(`/hosting/org/${orgId}/recording/${sessionId}/media/${mediaId}`);
    }
}
