import { ExternalState } from '../external-state';
import { BaseInput } from '../input/base';
import { RtcConnection } from '../rtc/connection';
import { BaseOutput } from './base';

export class RtcOutput extends BaseOutput {
  protected initPromise?: Promise<void>;
  protected analyser: AnalyserNode;
  protected destination: MediaStreamAudioDestinationNode;
  protected gainNode: GainNode;
  public readonly isMuted = new ExternalState<boolean>(false);

  constructor(
    protected name: string,
    protected audioContext: AudioContext,
    protected rtcConection: RtcConnection,
  ) {
    super(name, audioContext);

    this.destination = this.audioContext.createMediaStreamDestination();
    
    this.analyser = this.audioContext.createAnalyser();

    const input = this.audioContext.createMediaStreamSource(this.destination.stream);
    this.gainNode = this.audioContext.createGain();
    input.connect(this.analyser);
    input.connect(this.gainNode);

    this.gainNode.connect(this.rtcConection.inputStream);

    this.gainNode.gain.value = 1;

    this.init()
  }

  protected async init() {
    if(!this.initPromise) this.initPromise = (async () => {
    })()

    return this.initPromise; 
  }

  toggleMute() {
    if(this.gainNode.gain.value === 1) {
      this.gainNode.gain.value = 0;
      this.isMuted.set(true);
    }
    else {
      this.gainNode.gain.value = 1;
      this.isMuted.set(false);
    }
  }

  async connectCanvas(canvas: HTMLCanvasElement) {
    await this.init();
    const canvasContext = canvas.getContext('2d')!;
    const analyser = this.analyser;
    analyser.fftSize = 32;
    let frequencyData = new Uint8Array(analyser.frequencyBinCount);
    const update = () => {
      requestAnimationFrame(update);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      analyser.getByteFrequencyData(frequencyData);

      let barLength = frequencyData.reduce((acc, cur) => acc + cur, 0) / frequencyData.length;
      canvasContext.fillStyle = 'rgb(' + (barLength-50) + ',126,0)';
      canvasContext.fillRect(0, 0, barLength / 255 * canvas.width, canvas.height);
    }

    update();
  }

  getDestination() {
    return this.destination;
  }

  finishConnection(input: BaseInput) {
  } 
}