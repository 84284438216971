import { BaseInput } from '@/modules/audio-recorder/util/input/base';
import { ConnectionType, RecordingType } from '../viewer'
import * as RecorderOutput from '@/modules/audio-recorder/components/recorder-output'
import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { useInputList } from '@/modules/audio-recorder/hooks/use-input-list';
import { useContext, useEffect, useMemo } from 'react';
import { RecorderOutputContext } from '@/modules/audio-recorder/context/recorder-output-context';
import { DefaultConnectionType } from './default-selection';
import { RtcInput } from '@/modules/audio-recorder/util/input/rtc';
import styles from '../recording.module.scss';
import { MdFiberSmartRecord } from 'react-icons/md';
import { useExternalState } from '@/modules/audio-recorder/hooks/use-external-state';

const ConnectionManager: typeof RecordingRenderer = (props) => {
  const { connections, recording, defaultConnections } = props;
  const ctx = useContext(RecorderOutputContext);
  const output = ctx.output;
  const inputList = useInputList();


  const localConnections = useMemo(() => {
    if(!output) return;
    
    return connections.filter(([cI, cO]) => cO.getName() === output.getName());
    
  }, [connections, output])
  const defaultMic = useMemo(() => {
    // @ts-ignore
    return inputList.filter(o => 'getDevice' in o).find(o => o.getDevice().deviceId === defaultConnections.mic);
  }, [inputList, defaultConnections.mic])

  useEffect(() => {
    if(!output) return;
    const selectedInputs: Array<BaseInput> = [];

    for(const selection of recording.inputSelection) {
      if(selection.type === 'device') {
        if(!selection.selection && defaultMic) {
          selectedInputs.push(defaultMic)
        }
      }
      if(selection.type === 'rtc') {
        if(!selection.selection) {
          for(const i of inputList) {
            if(i instanceof RtcInput) {
              selectedInputs.push(i)
            }
          }
        }
      }
    }

    for(const sI of selectedInputs) {
      const foundConnection = connections.find(([cI, cO]) => cI === sI && cO === output);
      if(!foundConnection) {
        props.addConnection(sI, output)
      }
    }

  }, [inputList, defaultMic, ctx.output])
  return (
    <ul>
      {(localConnections || []).map(([i]) => (
        <li key={i.getName()}>{i.getName()}</li>
      ))}
    </ul>
  );
}

const Content: typeof RecordingRenderer = (props) => {
  const ctx = useContext(RecorderOutputContext);
  const isRecording = useExternalState(ctx.output!.isRecording);

  return (
    <div className={`${styles.recordingRenderer} ${isRecording ? styles.rec : ''}`}>
      <FlexRow center gap='1em'>
        <div className={styles.icon}><MdFiberSmartRecord /></div>
        <div className={styles.content}>
          <div><RecorderOutput.Name /></div>
          <ConnectionManager {...props} />
          <div><RecorderOutput.Visualizer width={500} height={5} /></div>
        </div>
        <div className={styles.timeout}><RecorderOutput.Timeout /></div>
      </FlexRow>
    </div>
  );
}

export const RecordingRenderer = (props: {startDate: Date, startDateWithOffset: Date, recording: RecordingType, defaultConnections: DefaultConnectionType, connections: Array<ConnectionType>, addConnection: (i: BaseInput, o: BaseOutput) => void, removeConnection: (i: BaseInput, o: BaseOutput) => void}) => {
  const { recording, startDate, startDateWithOffset } = props;
  return (
      <RecorderOutput.Provider name={`${startDate.toLocaleString()} - ${recording.name}`} startDate={startDateWithOffset}>
        <Content {...props} />
      </RecorderOutput.Provider>
  )
}