import { IdbStorage } from '@/modules/idb-file-storage/util/idb-storage';
import { DeviceOutput } from '../output/device';
import { BaseInput } from './base';
import { IdbFileReader } from '@/modules/idb-file-storage/util/idb-file-reader';
import { ExternalState } from '../external-state';

export class IdbFileInput extends BaseInput {

  protected initPromise?: Promise<void>;
  protected internalDestination: MediaStreamAudioDestinationNode;
  protected analyser: AnalyserNode;
  protected playbackSource: MediaStreamAudioSourceNode;
  protected fileReader!: IdbFileReader;
  public readonly audioElement: HTMLAudioElement;

  public readonly isPlaying = new ExternalState<boolean>(false);
  public readonly length = new ExternalState<number>(0);
  public readonly position = new ExternalState<number>(0);


  constructor(
    protected name: string,
    protected audioContext: AudioContext,
    protected idb: IdbStorage,
  ) {
    super(name, audioContext);
    this.init()

    this.internalDestination = audioContext.createMediaStreamDestination();

    const analyserInput = audioContext.createMediaStreamSource(this.internalDestination.stream);
    this.analyser = audioContext.createAnalyser();
    analyserInput.connect(this.analyser);
    
    this.playbackSource = audioContext.createMediaStreamSource(this.internalDestination.stream);

    this.audioElement = new Audio();
    this.audioElement.addEventListener('playing', (e) => this.isPlaying.set(!this.audioElement.paused))
    this.audioElement.addEventListener('pause', (e) => this.isPlaying.set(!this.audioElement.paused))

    this.audioElement.addEventListener('loadedmetadata', () => {
      if(this.audioElement.duration !== Infinity)
        this.length.set(this.audioElement.duration);
    })

    this.audioElement.addEventListener('timeupdate', () => {
      if(this.audioElement.duration !== Infinity) {
        this.length.set(this.audioElement.duration)
      }

      
      this.position.set(this.audioElement.currentTime)
    })
  }

  protected async init() {
    if(!this.initPromise) this.initPromise = (async () => {
      this.fileReader = await this.idb.getFileReader(this.name);
      const mediaBlob = await this.fileReader.readBlob();
      const audioElementNode = this.audioContext.createMediaElementSource(this.audioElement);
      audioElementNode.connect(this.internalDestination);
      const blobUrl = URL.createObjectURL(mediaBlob);
      this.audioElement.src = blobUrl;
      try {
        const audioBuffer = await this.audioContext.decodeAudioData(await mediaBlob.arrayBuffer())
        this.length.set(audioBuffer.duration);
      } catch(e) {
        this.length.set(0);
      }
    })()

    return this.initPromise; 
  }

  async play() {
    this.audioElement.play();
  }

  async pause() {
    this.audioElement.pause();
  }

  setPosition(pos: number) {
    this.audioElement.currentTime = pos;
  }

  async resume() {
    this.audioElement.play();
  }

  async stop() {
    this.audioElement.pause();
  }

  async download() {
    const mediaBlob = await this.fileReader.readBlob();
    const url = URL.createObjectURL(mediaBlob);
    window.open(url);
  }

  async connectCanvas(canvas: HTMLCanvasElement) {
    await this.init();
    const canvasContext = canvas.getContext('2d')!;
    const analyser = this.analyser;
    analyser.fftSize = 32;
    let frequencyData = new Uint8Array(analyser.frequencyBinCount);
    const update = () => {
      requestAnimationFrame(update);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      analyser.getByteFrequencyData(frequencyData);

      let barLength = frequencyData.reduce((acc, cur) => acc + cur, 0) / frequencyData.length;
      canvasContext.fillStyle = 'rgb(' + (barLength-50) + ',126,0)';
      canvasContext.fillRect(0, 0, barLength / 255 * canvas.width, canvas.height);
    }

    update();
  }

  connectOutput(output: DeviceOutput) {
    this.playbackSource.connect(output.getDestination());
    output.finishConnection(this);
  }

  disconnectOutput(output: DeviceOutput) {
    try {
      this.playbackSource.disconnect(output.getDestination());
    } catch {}
  }
}