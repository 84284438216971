import { BaseOutput } from '../output/base.js';

export abstract class BaseInput {
  constructor(
    protected name: string,
    protected audioContext: AudioContext,
  ) {}

  getName() {
    return this.name;
  }

  abstract connectOutput(output: BaseOutput): void;
  abstract disconnectOutput(output: BaseOutput): void;
}