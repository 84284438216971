import { OrganizationName, OrganizationProvider } from '@/modules/global/components/organization/organization-provider'
import { TokenRecordingSessionPayload } from '../hooks/use-token-payload'
import { useContext, useEffect, useState } from 'react'
import { BackendDispatchContext } from '@/modules/backend/context/backend-context'
import { Organization, RecordingSession } from '@nimey/podcast-global-entity'
import { RecordingGuest } from '@/modules/audio-recorder/context/recording-entities-context'
import { RecordingEntitiesProvider } from '@/modules/audio-recorder/components/recording-entities-provider'
import { RecordingSessionViewerWithEntities } from '@/modules/manager/components/org/recording-session/viewer'
import { AudioRecorderProvider } from '@/modules/audio-recorder/components/audio-recorder-provider'
import { Gutter } from '@/modules/layout/components/gutter'
import { DateDuration } from '@/modules/global/components/date-duration'
import { DownloadSessionMedia } from '@/modules/manager/components/org/recording-session/download'

export const Recording = (props: {token: string, payload: TokenRecordingSessionPayload}) => {
  const { asyncActions} = useContext(BackendDispatchContext);
  const [org, setOrg] = useState<Organization>()
  const [recordingSession, setRecordingSession] = useState<RecordingSession>()
  const [user, setUser] = useState<RecordingGuest>({
    id: props.payload.email,
    email: props.payload.email,
    name: props.payload.name,
  })
  useEffect(() => {
    const load = async () => {
      const result = await asyncActions?.getRecordingSessionFromToken(props.token);
      const response = result?.response;
      if(response) {
        setOrg(response.org);
        setRecordingSession(response.recordingSession)
      }
    }

    load();
    
  }, []);

  if(!org || !recordingSession || !user) return <>pending</>

  if(recordingSession.finishedAt) {
    return (
      <Gutter>
        <h1>{recordingSession.title}</h1>
        <p>Diese Sitzung wurde <DateDuration fromTimestamp={recordingSession.finishedAt} /> beendet. Eine Teilnahme an dieser Sitzung ist nicht mehr möglich.</p>
      </Gutter>
    )
  }

  return (
    <RecordingEntitiesProvider state={{org, user, recordingSession, isGuest: true, sessionToken: props.token}}>
      <AudioRecorderProvider sessionId={recordingSession.id}>
        <RecordingSessionViewerWithEntities />
      </AudioRecorderProvider>
    </RecordingEntitiesProvider>
  )
}