import { Duration, DurationUnit } from '@nimey/units'
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

export const Debug = (props: PropsWithChildren) => {
  const [active, setActive] = useState<boolean>(false);
  useEffect(() => {
    const i = setInterval(() => {
      if(localStorage.getItem('recording-debug') && !active) setActive(true);
      if(!localStorage.getItem('recording-debug') && active) setActive(false);
    }, +Duration.seconds(5))
    return () => {
      clearInterval(i);
    }
  }, [active]);

  if(!active) return <></>;

  return <>{props.children}</>
}

export const DebugClickCount = (props: PropsWithChildren) => {
  const [clickCounter, setClickCounter] = useState<number>(0)
  useEffect(() => {
    if(clickCounter >= 5) {
      localStorage.setItem('recording-debug', 'true')
    }
    const t = setTimeout(() => {
      if(clickCounter) setClickCounter(0);
    }, +Duration.seconds(1))

    return () => {
      clearTimeout(t);
    }
  }, [clickCounter])

  return (
    <span onMouseDown={(e) => {
      e.preventDefault();
      setClickCounter(c => c + 1)
    }}>{props.children}<Debug><button onClick={() => {localStorage.removeItem('recording-debug')}}>Disable Debug</button></Debug></span>
  )
}