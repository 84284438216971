import { useCallback, useContext, useEffect, useState } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { RecordingSessionMedia } from '@nimey/podcast-global-entity';

export const useRecordingSessionMedia = (orgId?: string, sessionId?: string, mediaId?: string) => {
  const [downloadState, setDownloadState] = useState<'pending' | 'downloading' | 'done'>('pending');
  const { asyncActions } = useContext(BackendDispatchContext);
  const state = useContext(BackendStateContext);

  const sessionInfo = state.entities['recording-session-media'] ? state.entities['recording-session-media'][mediaId || ''] : undefined;
  const recordingSessionMedia = sessionInfo?.entity as RecordingSessionMedia | undefined;

  const download = useCallback(async () => {
    setDownloadState('downloading');
    const result = await asyncActions!.downloadRecordingSessionMedia(orgId!, sessionId!, mediaId!)
    const contentType = result.headers.get('content-type') as string;

    const dataUrl = URL.createObjectURL(new Blob([result.response], {type: contentType}));
    setDownloadState('done');
    return {url: dataUrl, contentType};
  }, [recordingSessionMedia])

  return {
    recordingSessionMedia,
    download,
    downloadState
  }
}