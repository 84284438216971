import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { RtcConnection } from '../util/rtc/connection';
import { RtcOutputContext } from '../context/rtc-output-context';
import { RtcOutput } from '../util/output/rtc';
import { RtcSignalingContext } from '../context/rtc-signaling-context';
import { useExternalState } from '../hooks/use-external-state';
import { Duration } from '@nimey/units';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';

export type RtcOutputProviderProps = {
  name: string;
  rtcConnection: RtcConnection;
}

export const Name = () => {
  const ctx = useContext(RtcOutputContext);
  const rtcContext = useContext(RtcSignalingContext);

  const nameMap = useExternalState(rtcContext.signaling!.nameMap)
  if(ctx.output && nameMap[ctx.output.getName()]) return <>{ nameMap[ctx.output.getName()] }</>
  
  return <>{ctx.output?.getName()}</>
}


export const Visualizer = (props: {width: number, height: number, style?: CSSProperties}) => {
  const ctx = useContext(RtcOutputContext);
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    setTimeout(() => {
      if(ctx?.output && canvasRef.current) {
        ctx.output.connectCanvas(canvasRef.current)
      }
    }, 1000);
  }, [])

  return <canvas ref={canvasRef} {...props} />
}

export const ToogleMute = () => {
  const ctx = useContext(RtcOutputContext);
  const isMuted = useExternalState(ctx.output!.isMuted);

  return (
    <div onClick={(e) => ctx.output?.toggleMute()} className={isMuted ? 'muted' : ''}>
        {isMuted ? <MdVolumeOff /> : <MdVolumeUp/>}
    </div>
  )
}


class RtcProvider {
  protected instances: Record<string, RtcOutput> = {};
  getRtc(userId: string, name: string, ctx: AudioContext, rtc: RtcConnection) {
    const key = [userId, name].join('--');
    if(!this.instances[key]) this.instances[key] = new RtcOutput(name, ctx, rtc);

    return this.instances[key];
  }
}

const rtcProvider = new RtcProvider();

export const Provider = (props: PropsWithChildren<RtcOutputProviderProps>) => {
  const ctx = useContext(AudioRecorderContext);
  const output = rtcProvider.getRtc(props.rtcConnection.peerUserId, props.name, ctx.recorder!.getContext(), props.rtcConnection);
  useEffect(() => {
    ctx.recorder!.addOutput(output)
    return () => {
      ctx.recorder!.removeOutput(output)
    }
  }, [])
  return (
    <RtcOutputContext.Provider value={{output}}>
      {props.children}
    </RtcOutputContext.Provider>
  );
}