import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';

export const useRecordingSessionMediaList = (orgId: string, sessionId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = JSON.stringify({orgId, sessionId});
  
  return useEntityList({orgId, sessionId}, 'recording-session-media', async (filter: {orgId: string, sessionId: string}, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.loadRecordingSessionMediaList(filter.orgId, filter.sessionId, pageNumber, pageSize || 20)
  }, filterKey)
}