import { useCallback, useEffect, useState } from 'react';
import { ExternalState } from '../util/external-state';

export function useExternalState<T extends unknown>(external: ExternalState<T>) {
  const [ value, setValue ] = useState<T>(external.get());
  const stateListener = (v: T) => {
    setValue(v);
  };

  useEffect(() => {
    external.addListener(stateListener);
    return () => {
      external.removeListener(stateListener);
    }
  }, [external])

  // @ts-ignore
  return value;
}