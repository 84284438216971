import { useContext, useEffect, useState } from 'react';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { useExternalState } from './use-external-state';

export const useDeviceListInput = () => {
  const [ loading, setLoading ] = useState<boolean>(true);
  const ctx = useContext(AudioRecorderContext);

  const devices = useExternalState(ctx.recorder!.audioDevices);
  const reload = () => {
    if(ctx.recorder) {
      setLoading(true);
      ctx.recorder.loadMediaDevices().then((newDevices) => {
        setLoading(false);
        return;
      })
    }
  }

  useEffect(() => {
    reload();
  }, [ctx.recorder])

  return {
    devices: devices.filter(d => d.kind === 'audioinput'),
    loading,
    reload,
  }
}

export const useDeviceListOutput = () => {
  const [ loading, setLoading ] = useState<boolean>(true);
  const ctx = useContext(AudioRecorderContext);

  const devices = useExternalState(ctx.recorder!.audioDevices);
  const reload = () => {
    if(ctx.recorder) {
      setLoading(true);
      ctx.recorder.loadMediaDevices().then((newDevices) => {
        setLoading(false);
        return;
      })
    }
  }

  useEffect(() => {
    reload();
  }, [ctx.recorder])

  return {
    devices: devices.filter(d => d.kind === 'audiooutput'),
    loading,
    reload,
  }
}