import { BaseInput } from '@/modules/audio-recorder/util/input/base';
import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { ConnectionType } from '../viewer';
import { useInputList } from '@/modules/audio-recorder/hooks/use-input-list';
import { useOutputList } from '@/modules/audio-recorder/hooks/use-output-list';
import { RtcInput } from '@/modules/audio-recorder/util/input/rtc';
import { DefaultConnectionType } from '../renderer/default-selection';
import { RtcOutput } from '@/modules/audio-recorder/util/output/rtc';
import { AudioRecorderContext } from '@/modules/audio-recorder/context/audio-recorder-context';
import { useExternalState } from '@/modules/audio-recorder/hooks/use-external-state';
import { DeviceInput } from '@/modules/audio-recorder/util/input/device';
import { DeviceOutput } from '@/modules/audio-recorder/util/output/device';
import { Debug } from './debug';

export const DefaultConnections = (props: {defaultConnections: DefaultConnectionType,connections: Array<ConnectionType>, addConnection: (i: BaseInput, o: BaseOutput) => void, removeConnection: (i: BaseInput, o: BaseOutput) => void}) => {
  const { connections, addConnection, removeConnection, defaultConnections } = props;
  const ctx = useContext(AudioRecorderContext);
  const connected = useExternalState(ctx.recorder!.connectionState);
  const inputList = useInputList();
  const outputList = useOutputList();
  const lastDefaultConnections = useRef(defaultConnections);

  useEffect(() => {
    if(lastDefaultConnections.current.mic !== defaultConnections.mic) {
      connected.forEach(([i, o]) => {
        if('getDevice' in i && (i as DeviceInput).getDevice().deviceId === lastDefaultConnections.current.mic) {
          removeConnection(i, o);
        } 
      })
    }

    if(lastDefaultConnections.current.speaker !== defaultConnections.speaker) {
      connected.forEach(([i, o]) => {
        if('getDevice' in o && (o as DeviceOutput).getDevice().deviceId === lastDefaultConnections.current.speaker) {
          removeConnection(i, o);
        } 
      })
    }

    lastDefaultConnections.current = defaultConnections;
  }, [defaultConnections])

  const defaultSpeaker = useMemo(() => {
    // @ts-ignore
    return outputList.filter(o => 'getDevice' in o).find(o => o.getDevice().deviceId === defaultConnections.speaker);
  }, [outputList, defaultConnections.speaker])

  const defaultMic = useMemo(() => {
    // @ts-ignore
    return inputList.filter(o => 'getDevice' in o).find(o => o.getDevice().deviceId === defaultConnections.mic);
  }, [inputList, defaultConnections.mic])


  useEffect(() => {
    if(defaultSpeaker) {
      const rtcInputs = inputList.filter(i => i instanceof RtcInput)
      for(const rtcIn of rtcInputs) {
        if(!connections.find(([cI, cO]) => cI === rtcIn && cO === defaultSpeaker)) {
          addConnection(rtcIn, defaultSpeaker);
        }
      }
    }

    if(defaultMic) {
      const rtcOutputs = outputList.filter(i => i instanceof RtcOutput)
      for(const rtcOut of rtcOutputs) {
        if(!connections.find(([cI, cO]) => cI === defaultMic && cO === rtcOut)) {
          addConnection(defaultMic, rtcOut);
        }
      }
    }

    const rtcOutputs = outputList.filter(i => i instanceof RtcOutput)
    const disconnectedRtcOutput = rtcOutputs.filter(rtcI => !connections.find(([cI, cO]) => cO === rtcI));
    for(const rtc of disconnectedRtcOutput) {
      if(defaultMic) addConnection(defaultMic, rtc)
    }
  }, [connections, inputList, outputList, defaultMic, defaultSpeaker])

  return <Debug><pre style={{display: 'block'}}>{JSON.stringify(connected.map(([i, o]) => `${i.getName()} => ${o.getName()}`), null, 2)}</pre></Debug>
}

export const Connection = (props: {input: BaseInput, output: BaseOutput}) => {
  const { input, output } = props;
  const ctx = useContext(AudioRecorderContext);
  
  useEffect(() => {
    ctx.recorder?.connect(input, output)
    return () => {
      ctx.recorder?.disconnect(input, output)
    }
  }, [input, output])
  return <></>;
}