import { AudioRecorderContext } from '@/modules/audio-recorder/context/audio-recorder-context'
import { useDeviceListOutput } from '@/modules/audio-recorder/hooks/use-device-list'
import { Dispatch, SetStateAction, useEffect } from 'react'
import * as DeviceOutut from '@/modules/audio-recorder/components/device-output'

export const OutputWatcher = (props: {
  outputs: Array<DeviceOutut.DeviceOutputProviderProps>,
  setOutputs: Dispatch<SetStateAction<Array<DeviceOutut.DeviceOutputProviderProps>>>,
}) => {
  const { loading, devices, reload } = useDeviceListOutput()
  useEffect(() => {
    const newOutputs: typeof props['outputs'] = [...props.outputs];
    for(const device of devices) {
      if(!props.outputs.find(i => i.device.deviceId === device.deviceId)) {
        newOutputs.push({device, name: device.label})
      }
    }

    if(newOutputs.map(i => i.name).join('.') !== props.outputs.map(i => i.name).join('.')) {
      props.setOutputs(newOutputs);
    }
  }, [devices])
  
  return <></>
}