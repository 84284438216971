import { BaseInput } from '@/modules/audio-recorder/util/input/base'
import { DefaultConnectionType } from '../renderer/default-selection'
import { ConnectionType, RecordingType } from '../viewer'
import { BaseOutput } from '@/modules/audio-recorder/util/output/base'
import { useEffect, useState } from 'react'
import { RecordingRenderer } from '../renderer/recording-renderer'
import { FlexRow } from '@/modules/layout/components/grid/flex-row'

import styles from '../recording.module.scss';

type Props = {
  recordings: Array<RecordingType>,
  defaultConnections: DefaultConnectionType,
  connections: Array<ConnectionType>,
  addConnection: (i: BaseInput, o: BaseOutput) => void,
  removeConnection: (i: BaseInput, o: BaseOutput) => void
}

export const RecordingManager = (props: Props) => {
  const { recordings } = props;
  const [ startDate, setStartDate ] = useState<{orig: Date, withOffset: Date} | undefined>(undefined);
  useEffect(() => {
    const onStartRecordingEvent = (e: Event) => {
      if(!('detail' in e)) return;
      setStartDate(undefined);
      setStartDate({
        // @ts-ignore
        orig: e.detail!.startDate, withOffset: e.detail!.startDateWithOffset,
      })
    }
    window.addEventListener('pod-record-start', onStartRecordingEvent);
    return () => {
      window.removeEventListener('pod-record-start', onStartRecordingEvent);
    }
  }, [setStartDate])


  return (
    <div>
      
      {startDate ? (
        <>
          <FlexRow className={styles.recRow} style={{width: '100%'}} gap='1em'>
            {recordings.map(recording => (
              <RecordingRenderer key={recording.name} {...props} {...{recording, startDate: startDate.orig, startDateWithOffset: startDate.withOffset}} />
            ))}
          </FlexRow>
        </>
      ) : ''}
    </div>
  )
}