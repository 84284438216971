import { RecordingServerMessage } from '@nimey/podcast-global-entity';
import { RtcSignaling } from './signaling';
import EventEmitter from 'events';
import { ExternalState } from '../external-state';

export enum RtcConnectionMode {
  ACTIVE = 'active',
  PASSIVE = 'passive',
}

export class RtcConnection extends EventEmitter {
  public rtc: RTCPeerConnection;
  protected dataChannel?: RTCDataChannel;
  inputStream: MediaStreamAudioDestinationNode;
  outputStream: MediaStreamAudioSourceNode;

  public readonly attention = new ExternalState(false)

  constructor(
    public readonly peerUserId: string,
    public readonly peerUserName: string,
    protected signaling: RtcSignaling,
    protected mode: RtcConnectionMode,
  ) {
    super();
    const configuration = {iceServers: [
      { urls: 'stun:stun.l.google.com:19302'},
      { 
        urls: 'turn:turn.nimey.de',
        username: 'test',
        password: 'test123',
        credential: 'test123',
      },
    ]}

    this.inputStream = this.signaling.audioContext.createMediaStreamDestination();
    const internalDestination = this.signaling.audioContext.createMediaStreamDestination();
    this.outputStream = this.signaling.audioContext.createMediaStreamSource(internalDestination.stream)


    this.rtc = new RTCPeerConnection(configuration)
    this.rtc.addEventListener('negotiationneeded', () => {console.log('negotia')})

    this.rtc.addEventListener('connectionstatechange', (e) => {
      if(this.rtc.connectionState === 'disconnected') {
        this.emit('peer-disconnect', this);
      }
    })

    for(const track of this.inputStream.stream.getTracks()) {
      this.rtc.addTrack(track, this.inputStream.stream);
    }
    this.rtc.ontrack = (e) => {
      if(e.streams[0]) {
        const node = this.signaling.audioContext.createMediaStreamSource(e.streams[0]);
        node.connect(internalDestination);
        let a: HTMLAudioElement | undefined = new Audio();
        a.muted = true;
        a.srcObject = e.streams[0];
        a.addEventListener('canplaythrough', () => {
            a = undefined;
        });
      }
    }

    this.rtc.addEventListener('icecandidate', (event) => {
      this.signaling.send(new RecordingServerMessage.IceCandidateMessage({
        requestId: window.crypto.randomUUID(),
        candidateJson: JSON.stringify(event.candidate),
        senderId: this.signaling.userId,
        receiver: this.peerUserId,
      }))
    })

    this._init();

    this.rtc.addEventListener('connectionstatechange', event => {
      if (this.rtc.connectionState === 'connected') {
        console.log(this.signaling.userId, this.rtc.connectionState, 'to', this.peerUserId)
      }
    });

    this.rtc.addEventListener('datachannel', (event) => {
      this.dataChannel = event.channel;
      this.dataChannel.addEventListener('message', this._onDataChannelMessage.bind(this));
    })
    
  }

  _onDataChannelMessage(event: MessageEvent<string>) {
    console.log('received datachannel', event.data)
  }

  renewTrack() {
    // console.log(this.inputStream!.stream.getTracks())
  }

  private async _init() {
    if(this.mode === RtcConnectionMode.ACTIVE) {
      this.dataChannel = this.rtc.createDataChannel('main');
      this.dataChannel.addEventListener('message', this._onDataChannelMessage.bind(this));
      const offer = await this.rtc.createOffer({
        offerToReceiveAudio: true,
      });
      await this.rtc.setLocalDescription(offer);
      this.signaling.send(new RecordingServerMessage.SdpOfferMessage({
        senderId: this.signaling.userId,
        senderName: this.signaling.userName,
        requestId: window.crypto.randomUUID(),
        receiver: this.peerUserId,
        sdp: offer.sdp!,
        type: offer.type,
      }))
    }

    this.rtc.addEventListener('negotiationneeded', async () => {
      const offer = await this.rtc.createOffer({
        offerToReceiveAudio: true,
      });
      await this.rtc.setLocalDescription(offer);
      this.signaling.send(new RecordingServerMessage.SdpOfferMessage({
        senderId: this.signaling.userId,
        senderName: this.signaling.userName,
        requestId: window.crypto.randomUUID(),
        receiver: this.peerUserId,
        sdp: offer.sdp!,
        type: offer.type,
      }))
    })
  }

  async setSdpOffer(sdp: string, type: string) {
    this.rtc.setRemoteDescription(new RTCSessionDescription({
      type: 'offer',
      sdp,
    }))

    const answer = await this.rtc.createAnswer();
    await this.rtc.setLocalDescription(answer);
    this.signaling.send(new RecordingServerMessage.SdpAnswerMessage({
      senderId: this.signaling.userId,
      requestId: window.crypto.randomUUID(),
      receiver: this.peerUserId,
      sdp: answer.sdp!,
      type: answer.type,
    }))
  }

  async setSdpAnswer(sdp: string, type: string) {
    this.rtc.setRemoteDescription(new RTCSessionDescription({
      type: 'answer',
      sdp,
    }))
  }

  async addIceCandidate(candidate: RTCIceCandidate) {
    return this.rtc.addIceCandidate(candidate);
  }
}