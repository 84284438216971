import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IdbStorage } from '../util/idb-storage';
import { IdbContext } from '../context/idb-context';


export type ProviderProps = {
  dbName: string;
  prefix: string;
}

export const Provider = (props: PropsWithChildren<ProviderProps>) => {
  const [isInit, setIsInit] = useState<boolean>(false);
  const storage = useRef<IdbStorage>();
  useEffect(() => {
    if(!storage.current) {
      storage.current = new IdbStorage(props.dbName, props.prefix);
      setIsInit(true);
    } 
  }, [props])

  if(!isInit) return <></>;

  return <IdbContext.Provider value={storage.current!}>{props.children}</IdbContext.Provider>
}