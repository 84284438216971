import { InternalError } from '@nimey/podcast-global-entity';
import { IdbStorage, Store } from './idb-storage';

export class IdbFileWriter {
  protected initPromise?: Promise<void>;
  protected idx: number = 0;

  constructor(
    protected fileName: string,
    protected idb: IdbStorage
  ) {
    this.init();
  }

  public get name() {
    return this.fileName;
  }

  init() {
    this.initPromise = this.initPromise || (async () => {
      await this.idb.init();
      const fileEntry = await this.idb.transaction(Store.FILE, 'readonly', (trx) => trx.objectStore(Store.FILE).get(this.fileName));
      if(!fileEntry) {
        this.idb.transaction(Store.FILE, 'readwrite', (trx) => trx.objectStore(Store.FILE).add({name: this.fileName}))
      }
    })()

    return this.initPromise;
  }

  async addChunk(chunk: Blob | Uint8Array | string) {
    await this.init();
    const idx = this.idx++;
    const exisingChunk = await this.idb.transaction(Store.CHUNK, 'readonly', (trx) => trx.objectStore(Store.CHUNK).get([this.fileName, idx]));
    if(exisingChunk) throw new InternalError(1, 'can not override existing chunk')
    await this.idb.transaction(Store.CHUNK, 'readwrite', (trx) => {
      const objectStore = trx.objectStore(Store.CHUNK);
      return objectStore.add({name: this.fileName, idx, chunk})
    })
  }
}