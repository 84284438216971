import { useEffect, useRef, useState } from 'react'
import { CheckProps, CheckStatus } from './main'
import { InternalError } from '@nimey/podcast-global-entity';
import { Duration } from '@nimey/units';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdDone, MdErrorOutline } from 'react-icons/md';
import { Button } from '@nimey/react-ui';

const runMicrophoneTest = async (stream: MediaStream) => {
  const tracks = stream.getAudioTracks();
  if(tracks.length === 0) throw new InternalError(1730633404, 'Mikrofon hat keine Kanäle');
  
  const track = tracks[0];
  if(track.muted) throw new InternalError(1730633518, 'Dieses Mikrofon ist auf deinem Gerät stummgeschaltet');
  if(!track.enabled) throw new InternalError(1730633518, 'Dieses Mikrofon ist auf deinem Gerät deaktiviert');
  if(track.readyState === 'ended') throw new InternalError(1730633518, 'Dieses Mikrofon ist auf deinem Gerät deaktiviert');

  const rec = new MediaRecorder(stream);

  await new Promise<void>((resolve, reject) => {
    track.onended = () => {
      clearTimeout(t);
      reject(new InternalError(1730633659, 'Dein System hat das Mikro nach weniger als 2 Sekunden deaktivert'));
    }
    const t = setTimeout(() => {
      track.onended = () => {};
      resolve();
    }, +Duration.seconds(2));
  })

  await new Promise<void>(async (resolve, reject) => {
    const audioContext = new AudioContext();
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 512;
    analyzer.smoothingTimeConstant = 0.1;
    const sourceNode = audioContext.createMediaStreamSource(stream);
    sourceNode.connect(analyzer);
    const meterValues: Array<number> = [];
    for(let i = 0; i < 100; i++) {
      await new Promise(resolve => setTimeout(resolve, +Duration.millis(50)))

      const fftBins = new Float32Array(analyzer.frequencyBinCount); // Number of values manipulated for each sample
      analyzer.getFloatFrequencyData(fftBins);
        // audioPeakDB varies from -Infinity up to 0
      const audioPeakDB = Math.max(...fftBins);

      // Compute a wave (0...)
      const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
      analyzer.getByteFrequencyData(frequencyRangeData);
      const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
        // audioMeter varies from 0 to 10
      const audioMeter = Math.sqrt(sum / frequencyRangeData.length);
      meterValues.push(audioMeter < 0 ? audioMeter * -1 : audioMeter);
    }

    const result = meterValues.reduce((acc, cur) => {
      if(acc.last === -1) return {...acc, last: cur};
      return {last: cur, offsetSum: acc.offsetSum + (acc.last + cur)};
    }, {last: -1, offsetSum: 0})

    if(result.offsetSum < 20) return reject(new InternalError(1730635256, 'Über dein Mikro wurden keine Geräusche ekannt ' + result.offsetSum));
    resolve();
  })

  rec.stop();
}

export const CheckMicrophone = (props: CheckProps) => {
  const {check, setCheckStatus} = props;
  const [ manualPass, setManualPass ] = useState<boolean | undefined>(undefined);
  const [ error, setError ] = useState<string>('');
  const [ messages, setMessages ] = useState<Array<string>>([]);
  const [deviceStatus, setDeviceStatus] = useState<Array<{label: string, passed: boolean, reason?: string}>>([]);
  const started = useRef(false);

  useEffect(() => {
    if(check.status === CheckStatus.RUNNING) {
      if(started.current) return;
      started.current = true;
      let localDeviceStatus: typeof deviceStatus =  [];
      const addMesage = (m: string) => setMessages(ms => [...ms, m]);
      const runCheck = async () => {
        await navigator.mediaDevices.getUserMedia({audio: true})
        const devices = (await navigator.mediaDevices.enumerateDevices()).filter(d => d.kind === 'audioinput');
        addMesage('Zugriff auf Geräte erlaubt')
        if(devices.length === 0) throw new InternalError(1730632648, 'Keine Eingabegeräte gefunden');
        addMesage(`${devices.length} Mikrofon${devices.length > 1? 'e' : ''} gefunden`);
        for(const deviceInfo of devices) {
          addMesage(`"${deviceInfo.label}" wird überprüft`)
          try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: {deviceId: {exact: deviceInfo.deviceId}}});
            await runMicrophoneTest(stream);
            addMesage(`"${deviceInfo.label}" OK`)
            setDeviceStatus(ds => [...ds, {label: deviceInfo.label, passed: true}]);
            localDeviceStatus = [...localDeviceStatus, {label: deviceInfo.label, passed: true}];
          } catch(e) {
            addMesage(e instanceof InternalError ? e.message : String(e))
            setDeviceStatus(ds => [...ds, {label: deviceInfo.label, passed: false, reason: String(e)}]);
            localDeviceStatus = [...localDeviceStatus, {label: deviceInfo.label, passed: false}];
          }
        }
      }

      runCheck()
        .then(() => {
          const allPassed = localDeviceStatus.filter(d => !d.passed).length === 0;
          const allFailed = localDeviceStatus.filter(d => d.passed).length === 0;


          if (allPassed) setCheckStatus(CheckStatus.PASSED);
          else if (allFailed) throw 'Keines deiner Mikrofone hat den Test bestanden';
          else setManualPass(false);
        })
        .catch((e) => {
          if(e instanceof Error) {
            console.log(e.name)
            if(e.name === 'NotAllowedError') {
              setError('Keine Berechtigung für den Zugriff auf deine Geräte');
              setCheckStatus(CheckStatus.FAILED);
              return;
            }
          }
          setError(String(e))
          setCheckStatus(CheckStatus.FAILED);
        })

    }
  })

  return (
    <>
      {check.status === CheckStatus.RUNNING ? <p>Deine Mikrofone werden getestet</p> : ''}
      <div style={{fontSize: 'smaller', marginLeft: '1em', maxHeight: check.status === CheckStatus.RUNNING ? 1000 : 0, transition: 'max-height .2s ease', overflow: 'hidden'}}>
        {messages.map((m, i) => <p style={{margin: 0}} key={i}>{m}</p>)}
      </div>
      <ul>
        {deviceStatus.map(ds => (
          <li key={ds.label}>
            <FlexRow center spaceBetween>
              <span>{ds.label}</span>
              {ds.passed ? <MdDone /> : <span style={{color: 'red'}}><MdErrorOutline /></span>}
            </FlexRow>
            <small>{ds.reason}</small>
          </li>
        ))}
      </ul>
      <p style={{color: 'red'}}>{error}</p>
      {manualPass === false ? (
        <FlexRow center spaceBetween>
          <p>Nicht alle Mikrofone funktionieren. Möchtest du trotzdem fortfahren?</p>
          <Button onClick={() => {
            setManualPass(true);
            setCheckStatus(CheckStatus.PASSED)
          }}>Weiter</Button>
        </FlexRow>
      ) : ''}
    </>
  )
}