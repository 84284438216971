import { Organization, Podcast, PodcastEpisodeListType } from '@nimey/podcast-global-entity';
import { withCurrentOrg } from '../../hoc/with-current-org';
import { usePodcast } from '@/modules/backend/hooks/use-podcast';
import { useParams } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import { Gutter } from '@/modules/layout/components/gutter';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import styles from './podcast.module.scss';
import { usePodcastFeed } from '@/modules/backend/hooks/use-podcast-feed';
import { usePodcastEpisodeList } from '@/modules/backend/hooks/use-podcast-episode-list';
import { EpisodeProvider } from '@/modules/global/components/podcast/episode/episode-provider';
import { EpisodeTitle } from '@/modules/global/components/podcast/episode/title';
import { LoadMore } from '@/modules/layout/components/load-more';
import { EpisodeDescription } from '@/modules/global/components/podcast/episode/description';
import { RequestPodcastRefetch } from './request-podcast-refetch';
import { PodcastProvider } from '@/modules/global/components/podcast/podcast-provider';
import { EpisodePubDate } from '@/modules/global/components/podcast/episode/pub-date';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdAdd } from 'react-icons/md';
import { Button } from '@nimey/react-ui';

const DateFormat = ({timestamp}: {timestamp: number}) => {
  const date = new Date(timestamp);
  return <>{date.toLocaleString()}</>
}

const EpisodeList = (props: {podcast: Podcast}) => {
  const { allIds, hasMore, loadMore, meta } = usePodcastEpisodeList({podcastId: props.podcast.id}, PodcastEpisodeListType.RECENT);

  return (
    <div>
      <h2>{meta?.totalCount} Episoden</h2>

      <div className={styles.episodes}>
        {(allIds || []).map(id => (
          <div key={id} className={styles.episode}>
            <EpisodeProvider episodeId={id}>
              <FlexRow><EpisodeTitle /><small style={{whiteSpace: 'nowrap'}}><EpisodePubDate /></small></FlexRow>
              <div className={styles.description}><EpisodeDescription maxLines={20} /></div>
            </EpisodeProvider>
          </div>
        ))}
        <LoadMore {...{hasMore, loadMore}} />
      </div>
    </div>
  );
}


const OrgPodcastWithOrgAndPodcast = (props: {org: Organization, podcast: Podcast}) => {
  const { org, podcast } = props;

  const {feed, load} = usePodcastFeed(podcast.id);
  useEffect(() => {
    load()
  }, [ load ])
  const transCategory = useTranslation('category')

  if(!podcast) return <></>;

  return (
    <Gutter>
      <h1>{podcast.title}</h1>
      <table className={styles['pod-table']}>
        <tr>
          <th>Author</th>
          <td>{podcast.author}</td>
        </tr>
        <tr>
          <th>Besitzer</th>
          <td>{podcast.owner?.name} / {podcast.owner?.email}</td>
        </tr>
        <tr>
          <th>Kategorien</th>
          <td>{podcast.categories.map(c => transCategory.t(c)).join(', ')}</td>
        </tr>
        <tr>
          <th>Link</th>
          <td className={styles.url}>{podcast.link}</td>
        </tr>
        <tr>
          <th>Sprachen</th>
          <td>{Array.isArray(podcast.languages) ? podcast.languages.join(', ') : '---'}</td>
        </tr>
        <tr>
          <th>Feed-URL</th>
          <td className={styles.url}>{podcast.feedUrl}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <Markdown>{podcast.description}</Markdown>
          </td>
        </tr>

        {feed ? (
          <Fragment>
            <tr>
              <th colSpan={2}>Fetch Info</th>
            </tr>
            <tr>
              <th>Cache Strategie</th>
              <td>{feed.cacheStrategy}{feed.cacheValue ? ` / ${feed.cacheValue}` : ''}</td>
            </tr>
            <tr>
              <th>Fetch Interval</th>
              <td>{feed.fetchInterval}{feed.lastFetch ? (
                <>{' / '}<DateFormat timestamp={feed.lastFetch} /></>
              ) : '' }</td>
            </tr>
          </Fragment>
        ) : ''}

      </table>
      {podcast ? (
        <PodcastProvider podcastId={podcast.id}><RequestPodcastRefetch /></PodcastProvider>
      ) : ''}
      {podcast ? <EpisodeList {...{podcast}} /> : ''}
      
    </Gutter>
  );

}

const OrgPodcastWithOrg = (props: {org: Organization}) => {
  const { podcastId } = useParams();
  const {podcast, load} = usePodcast(podcastId)

  useEffect(() => {
    load();
  }, [load])

  if(podcast) return <OrgPodcastWithOrgAndPodcast {...{org: props.org, podcast}} />

  return <></>
}

export const OrgPodcast = withCurrentOrg<{}>(OrgPodcastWithOrg);