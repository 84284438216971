import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';

export const useHostingPodcastList = (orgId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = orgId;
  
  return useEntityList(orgId, 'hosting-podcast', async (orgId: string, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.loadHostingPodcastList(orgId, pageNumber, pageSize)
  }, filterKey)
}