import { useContext } from 'react';
import { BackendDispatchContext } from '../context/backend-context';
import { useEntityList } from './use-entity-list';

export const useRecordingSessionList = (orgId: string) => {
  const { asyncActions } = useContext(BackendDispatchContext)
  const filterKey = orgId;
  
  return useEntityList(orgId, 'recording-session', async (orgId: string, pageNumber?: number, pageSize?: number) => {
    return asyncActions!.loadRecordingSessionList(orgId, pageNumber, pageSize)
  }, filterKey)
}