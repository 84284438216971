import { PropsWithChildren, useContext } from 'react';
import { RecordingEntitiesContext, RecordingEntitiesContextType } from '../context/recording-entities-context';


export const IsRecordingUser = (props: PropsWithChildren) => {
  const { isGuest } = useContext(RecordingEntitiesContext);

  if(isGuest) return <></>

  return <>{props.children}</>
}

export const RecordingEntitiesProvider = (props: PropsWithChildren<{state: RecordingEntitiesContextType}>) => {
  return <RecordingEntitiesContext.Provider value={props.state}>{props.children}</RecordingEntitiesContext.Provider>
}