import { useCallback, useContext, useEffect } from 'react';
import { BackendDispatchContext, BackendStateContext } from '../context/backend-context';
import { InternalError, RecordingSession, RecordingSessionCreatePayload } from '@nimey/podcast-global-entity';

export const useRecordingSession = (orgId?: string, sessionId?: string) => {
  const { asyncActions } = useContext(BackendDispatchContext);
  const state = useContext(BackendStateContext);

  const sessionInfo = state.entities['recording-session'] ? state.entities['recording-session'][sessionId || ''] : undefined;
  const recordingSession = sessionInfo?.entity as RecordingSession | undefined;

  const reload = (force: boolean = false) => {
    if((!recordingSession || force) && orgId && sessionId && asyncActions) {
      asyncActions?.loadRecordingSession(orgId, sessionId).catch((e) => {
        console.error(e)
      });
    }
  };

  useEffect(() => {
    reload();
  }, [orgId, sessionId])

  const add = useCallback(async (p: RecordingSessionCreatePayload) => {
    if(sessionId) {
      throw new InternalError(1725651876, 'can not add recording-session with id');
    }

    return await asyncActions?.createRecordingSession(p);
  }, [sessionId])

  const update = useCallback(async (p: RecordingSession) => {
    if(!sessionId) {
      throw new InternalError(1725651895, 'can not update recording-session without id');
    }

    await asyncActions?.updateRecordingSession(p);
  }, [sessionId])

  return {
    add,
    update,
    recordingSession,
    reload,
  }
}