import { PropsWithChildren } from 'react';

import { DeviceOutputProviderProps } from '@/modules/audio-recorder/components/device-output';
import * as DeviceOutput from '@/modules/audio-recorder/components/device-output'

import styles from '../recording.module.scss';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdSpeaker } from 'react-icons/md';

export const OutputDeviceRenderer = (props: DeviceOutputProviderProps) => {
  const {...passProps} = props;
  return (
    <div className={styles.outputRenderer}>
      <DeviceOutput.Provider {...passProps}>
        <FlexRow center gap='1em'>
          <div className={styles.icon}><MdSpeaker /></div>
          <div className={styles.content}>
            <FlexRow spaceBetween center className={styles.info}>
              <DeviceOutput.Name />
            </FlexRow>
            <FlexRow center gap='.25em'><div className={styles.mute}><DeviceOutput.ToogleMute /></div><DeviceOutput.Visualizer width={500} height={5} /></FlexRow>
          </div>
        </FlexRow>
      </DeviceOutput.Provider>
    </div>
  );
}