import * as allMessagesModules from './index.js';
const allMessages = {};
for (const key in allMessagesModules) {
    // @ts-ignore
    allMessages[key] = allMessagesModules[key];
}
export const deserializeMessage = (input) => {
    const messageData = JSON.parse(input);
    if (!messageData.__type)
        throw new Error('unknown message');
    if (!allMessages[messageData.__type])
        throw new Error('unknown message type');
    // @ts-ignore
    return new allMessages[messageData.__type](messageData.payload);
};
