import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { DeviceInputContext } from '../context/device-input-context';
import { DeviceInput } from '../util/input/device';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { useExternalState } from '../hooks/use-external-state';
import { MdSpeaker, MdSpeakerPhone, MdSurroundSound, MdVolumeMute, MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { IconContext } from 'react-icons';

export type DeviceInputProviderProps = {
  name: string;
  device: MediaDeviceInfo;
}

export const Name = () => {
  const ctx = useContext(DeviceInputContext);

  return <>{ctx.input?.getName()}</>
}

export const DeviceLabel = () => {
  const ctx = useContext(DeviceInputContext);

  return <>{ctx.input?.getDevice().label}</>
}

export const ToogleMute = () => {
  const ctx = useContext(DeviceInputContext);
  const isMuted = useExternalState(ctx.input!.isMuted);

  return (
    <div onClick={(e) => ctx.input?.toggleMute()} className={isMuted ? 'muted' : ''}>
        {isMuted ? <MdVolumeOff /> : <MdVolumeUp />}
    </div>
  )
}

export const Visualizer = (props: {width: number, height: number}) => {
  const ctx = useContext(DeviceInputContext);
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    setTimeout(() => {
      if(ctx?.input && canvasRef.current) {
        ctx.input.connectCanvas(canvasRef.current)
      }
    }, 1000);
  }, [])

  return <canvas ref={canvasRef} width={props.width} height={props.height} />
}

class DeviceProvider {
  protected instances: Record<string, DeviceInput> = {};
  getDevice(name: string, ctx: AudioContext, rtc: MediaDeviceInfo) {
    const key = [name].join('--');
    if(!this.instances[key]) this.instances[key] = new DeviceInput(name, ctx, rtc);

    return this.instances[key];
  }
}

const deviceProvider = new DeviceProvider();

export const Provider = (props: PropsWithChildren<DeviceInputProviderProps>) => {
  const ctx = useContext(AudioRecorderContext);
  const deviceInput = deviceProvider.getDevice(props.name, ctx.recorder!.getContext(), props.device);
  useEffect(() => {
    ctx.recorder!.addInput(deviceInput)
    return () => {
      ctx.recorder!.removeInput(deviceInput)
    }
  }, [])
  return (
    <DeviceInputContext.Provider value={{input: deviceInput}}>
      {props.children}
    </DeviceInputContext.Provider>
  );
}