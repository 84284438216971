import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import styles from '../recording.module.scss';
import { DefaultConnectionType } from '../renderer/default-selection';
import { ConnectionType } from '../viewer';
import { BaseInput } from '@/modules/audio-recorder/util/input/base';
import { useIdbFileList } from '@/modules/idb-file-storage/hooks/use-idb-file-list';
import { IdbFileRenderer } from '../renderer/idb-file-renderer';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdCompareArrows, MdReplay } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import { useOutputList } from '@/modules/audio-recorder/hooks/use-output-list';
import { DeviceOutput } from '@/modules/audio-recorder/util/output/device';
import { IdbFileInput } from '@/modules/audio-recorder/util/input/idb-file';
import { useInputList } from '@/modules/audio-recorder/hooks/use-input-list';
import { Modal } from '@/modules/layout/components/modal';

type Props = {
  defaultConnections: DefaultConnectionType,
  connections: Array<ConnectionType>,
  addConnection: (i: BaseInput, o: BaseOutput) => void,
  removeConnection: (i: BaseInput, o: BaseOutput) => void
}

export const IdbFileManager = (props: Props) => {
  const [deviceSelectionModal, setDeviceSelectionModal] = useState<boolean>(false);
  const [files, reload] = useIdbFileList();
  const inputs = useInputList();
  const outputs = useOutputList();
  const defaultSpeaker = useMemo(() => {
    return outputs.find(o => o instanceof DeviceOutput && o.getDevice().deviceId === props.defaultConnections.speaker)
  }, [outputs, props.defaultConnections.speaker])

  const idbInputs = useMemo(() => inputs.filter(i => i instanceof IdbFileInput), [inputs])

  const idbOutputs = useMemo(() => {
    return props.connections.filter(([i]) => i instanceof IdbFileInput).map(([i, o]) => o)
  }, [props.connections])


  useEffect(() => {
    if(defaultSpeaker) idbInputs.forEach(i => props.addConnection(i, defaultSpeaker));
    return () => {
      if(defaultSpeaker) return idbInputs.forEach(i => props.removeConnection(i, defaultSpeaker))
    }
  }, [defaultSpeaker, idbInputs])


  return (
    <div className={styles.idbFileList}>
      <FlexRow center spaceBetween>
        <p className={styles.title}><strong>Deine Aufnahmen</strong></p>
        <div className={styles.buttons}>
          <button onClick={() => setDeviceSelectionModal(true)}><MdCompareArrows /></button>
          <button onClick={() => reload()}><MdReplay /></button>
        </div>
      </FlexRow>
      <Modal isOpen={deviceSelectionModal} close={() => setDeviceSelectionModal(false)}>
        <ul>
          {outputs.map((out) => (
            <li key={out.getName()}><label><input type='checkbox' onChange={(e) => {
              idbInputs.forEach(i => e.target.checked ? props.addConnection(i, out) : props.removeConnection(i, out))
            }} checked={!!idbOutputs.find(o => o === out)} />{out.getName()}</label></li>
          ))}
        </ul>  
      </Modal>
      
      <FlexRow className={styles.recRow} style={{width: '100%'}} gap='1em'>
        {files.map(file => (
          <IdbFileRenderer {...props} fileName={file} key={file} outputs={[]} />
        ))}
      </FlexRow>
    </div>
 )
}