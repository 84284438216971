import { CSSProperties, PropsWithChildren } from 'react';

export type GapProps = {
  style?: CSSProperties;
  className?: string;
  h?: string;
  v?: string;
}

export const Gap = (props: PropsWithChildren<GapProps>) => (
  <div
    className={props.className}
    style={{
      ...(props.style || {}),
      height: props.v,
      width: props.h,
    }}
  >
    {props.children}
  </div>
)