import { BaseMessage } from './base.js';
export class PongMessage extends BaseMessage {
    payload;
    constructor(payload) {
        super(payload);
        this.payload = payload;
    }
    get serverTs() {
        return this.payload.serverTs;
    }
    get clientTs() {
        return this.payload.clientTs;
    }
}
