import { BaseMessage } from './base.js';
export class ChannelLogMessage extends BaseMessage {
    payload;
    constructor(payload) {
        super(payload);
        this.payload = payload;
    }
    get message() {
        return this.payload.message;
    }
}
