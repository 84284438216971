import { useRecordingSessionMedia } from '@/modules/backend/hooks/use-recording-session-media';
import { useRecordingSessionMediaList } from '@/modules/backend/hooks/use-recording-session-media-list';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { LoadMore } from '@/modules/layout/components/load-more';
import { Organization, RecordingSession } from '@nimey/podcast-global-entity';
import { MdDone, MdDownload } from 'react-icons/md';
import styles from './recording.module.scss';
import { Spinner } from '@/modules/layout/components/spinner';

export const Media = (props: {org: Organization, session: RecordingSession, mediaId: string}) => {
  const { recordingSessionMedia, download, downloadState } = useRecordingSessionMedia(props.org.id, props.session.id, props.mediaId);
  if(!recordingSessionMedia) return <></>
  return (
    <FlexRow center gap='1em'>
      <button onClick={() => download().then(({url, contentType}) => {
        const a = document.createElement('a');
        a.href = url
        a.download = `${recordingSessionMedia.userId}-${recordingSessionMedia.fileName}.${contentType.replace(/^.*\//, '')}`;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })} disabled={downloadState !== 'pending'}>
        {downloadState === 'done' ? <MdDone /> : ''}
        {downloadState === 'pending' ? <MdDownload /> : ''}
        {downloadState === 'downloading' ? <Spinner size='1em' /> : ''}
      </button>
      <span>{recordingSessionMedia.userName}</span>
      <span>{recordingSessionMedia.fileName}</span>
    </FlexRow>
  );
}

export const DownloadSessionMedia = (props: {org: Organization, session: RecordingSession}) => {
  const {allIds, loadMore, hasMore} = useRecordingSessionMediaList(props.org.id, props.session.id);
  return (
    <div className={styles.sessionDownloadList}>
      {allIds.length === 0 ? (<p>Es wurden keine Aufnahmen gespeichert</p>): (
        <p>Folgende Aufnahmen wurden gespeichert:</p>
      )}
      
      <ul>
        {allIds.map(id =>
          <li key={id}><Media {...props} mediaId={id} /></li>
        )}
        <LoadMore {...{hasMore, loadMore}} />  
      </ul>

    </div>
  );
}