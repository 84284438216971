import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { DeviceInputContext } from '../context/device-input-context';
import { DeviceOutput } from '../util/output/device';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { DeviceOutputContext } from '../context/device-output-context';
import { RtcSignalingContext } from '../context/rtc-signaling-context';
import { useExternalState } from '../hooks/use-external-state';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';

export type DeviceOutputProviderProps = {
  name: string;
  device: MediaDeviceInfo;
}

export const Name = () => {
  const ctx = useContext(DeviceOutputContext);

  return <>{ctx.output?.getName()}</>
}

export const Playing = () => {
  const ctx = useContext(DeviceOutputContext);
  const isPlaying = useExternalState(ctx.output!.playing);
  return <>{isPlaying ? 'PLAYING' : 'PAUSED'}</>
}

export const Muted = () => {
  const ctx = useContext(DeviceOutputContext);
  const isMuted = useExternalState(ctx.output!.muted);
  return <>{isMuted ? 'MUTED' : 'OK'}</>
}

export const Debug = () => {
  const ctx = useContext(DeviceOutputContext);
  const streamState = useExternalState(ctx.output!.streamState);

  return (
    <>
      <button onClick={() => ctx.output?.toggle()}>PLAY / PAUSE</button>
      <pre>{JSON.stringify(streamState, null, 2)}</pre>
    </>
  )
}

export const DeviceLabel = () => {
  const ctx = useContext(DeviceOutputContext);

  return <>{ctx.output?.getDevice().label}</>
}

export const Visualizer = (props: {width: number, height: number}) => {
  const ctx = useContext(DeviceOutputContext);
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    if(ctx?.output && canvasRef.current) {
      ctx.output.connectCanvas(canvasRef.current)
    }
  }, [])

  return <canvas ref={canvasRef} width={props.width} height={props.height} />
}

export const ToogleMute = () => {
  const ctx = useContext(DeviceOutputContext);
  const isMuted = useExternalState(ctx.output!.isMuted);

  return (
    <div onClick={(e) => ctx.output?.toggleMute()} className={isMuted ? 'muted' : ''}>
        {isMuted ? <MdVolumeOff /> : <MdVolumeUp />}
    </div>
  )
}

class DeviceProvider {
  protected instances: Record<string, DeviceOutput> = {};
  getDevice(name: string, ctx: AudioContext, rtc: MediaDeviceInfo) {
    const key = [name].join('--');
    if(!this.instances[key]) this.instances[key] = new DeviceOutput(name, ctx, rtc);

    return this.instances[key];
  }
}

const deviceProvider = new DeviceProvider();

export const Provider = (props: PropsWithChildren<DeviceOutputProviderProps>) => {
  const ctx = useContext(AudioRecorderContext);
  const deviceOutput = deviceProvider.getDevice(props.name, ctx.recorder!.getContext(), props.device);
  useEffect(() => {
    ctx.recorder!.addOutput(deviceOutput)
    return () => {
      ctx.recorder!.removeOutput(deviceOutput)

    }
  }, [])
  return (
    <DeviceOutputContext.Provider value={{output: deviceOutput}}>
      {props.children}
    </DeviceOutputContext.Provider>
  );
}