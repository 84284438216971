import { Modal } from '@/modules/layout/components/modal'
import { useContext, useEffect, useRef, useState } from 'react'
import { RtcSignalingContext } from '../context/rtc-signaling-context';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { IdbContext } from '@/modules/idb-file-storage/context/idb-context';
import { Spinner } from '@/modules/layout/components/spinner';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { MdDone, MdOutlineSmsFailed } from 'react-icons/md';
import { BackendDispatchContext } from '@/modules/backend/context/backend-context';
import { Organization, RecordingServerMessage, RecordingSession } from '@nimey/podcast-global-entity';
import { RecordingEntitiesContext } from '../context/recording-entities-context';

export const SessionController = (props: {org: Organization, recordingSession: RecordingSession}) => {
  const { org, recordingSession } = props;
  const { sessionToken } = useContext(RecordingEntitiesContext);
  const { asyncActions } = useContext(BackendDispatchContext);
  const [ inititator, setInitiator ] = useState<{name: string, id: string}>();
  const [ remoteUploads, setRemoteUploads ] = useState<Array<{id: string, name: string, fileCount: number, ready: boolean}>>([]);
  const [localRecordings, setLocalRecordings] = useState<Array<{name: string, ready: boolean, started: boolean, failed: boolean}>>([]);
  const singalingCtx = useContext(RtcSignalingContext);
  const signaling = singalingCtx.signaling;
  const audioRecorderCtx = useContext(AudioRecorderContext);
  const recorder = audioRecorderCtx.recorder;


  recorder?.getSessionId()

  const idb = useContext(IdbContext);
  const endRef = useRef<boolean>(false);

  useEffect(() => {
    const onEndSession = (e: any) => {
      setInitiator(e.initiator);
    }

    const onUploadBegin = (e: any) => {
      setRemoteUploads(ru => [...ru, {id: e.initiator.id, name: e.initiator.name, fileCount: e.fileCount, ready: false}])
    }

    const onUploadFinish = (e: any) => {
      setRemoteUploads(ru => [...ru.map((r => r.id === e.initiator.id ? {...r, ready: true} : r))]);
    }

    if(signaling) {
      signaling.addListener('session-end', onEndSession);
      signaling.addListener('upload-begin', onUploadBegin);
      signaling.addListener('upload-finish', onUploadFinish);
    }

    return () => {
      if(signaling) {
        signaling.removeListener('session-end', onEndSession);
        signaling.removeListener('upload-begin', onUploadBegin);
        signaling.removeListener('upload-finish', onUploadFinish);
      }
    }
  }, [signaling])

  useEffect(() => {
    if(!inititator || !recorder || !idb || !signaling ||  endRef.current) return;
    endRef.current = true;

    const endSession = async () => {
      recorder.inputState.get().forEach(i => recorder.removeInput(i));
      recorder.outputState.get().forEach(o => recorder.removeOutput(o));
      const files = await idb.listFiles()
      setLocalRecordings(files.map(f => ({name: f, ready: false, started: false, failed: false})));
      signaling?.beginUpload(files.length)

      for(const fileName of files) {
        setLocalRecordings(lr => lr.map((r,i) => r.name === fileName ? ({...r, started: true}) : r));
        const reader = await idb.getFileReader(fileName);
        const blob = await reader.readBlob();
        const buffer = await blob.arrayBuffer();
        let saved = false;
        let retryCount = 0;
        while (!saved || retryCount > 5) {
          try {
            await asyncActions!.saveRecordingMedia(org!.id, recorder.getSessionId(), signaling.userId, signaling.userName, fileName, buffer, sessionToken);
            setLocalRecordings(lr => lr.map((r,i) => r.name === fileName ? ({...r, ready: true}) : r));
            saved = true;
          } catch {
            retryCount++;
            setLocalRecordings(lr => lr.map((r,i) => r.name === fileName ? ({...r, failed: true}) : r));
          }
        }
      }


      await signaling.send(new RecordingServerMessage.FinishUploadMessage({
        channelId: recorder.getSessionId(),
        requestId: crypto.randomUUID(),
        userId: signaling.userId,
        userName: signaling.userName,
      }))
    }

    endSession();

  }, [inititator, recorder, idb, sessionToken])

  useEffect(() => {
    if(!inititator || !signaling || !recordingSession) return;

    if(localRecordings.length === 0) return;
    if(remoteUploads.length === 0) return;

    if(localRecordings.find(l => l.ready === false)) return;
    if(remoteUploads.find(l => l.ready === false)) return;

    if(inititator.id === signaling.userId && recordingSession) {
      asyncActions!.updateRecordingSession({
        ...recordingSession,
        finishedAt: Date.now(),
      })
    }
  }, [inititator, signaling ,localRecordings, remoteUploads])

  if(!inititator) return <></>;

  return (
    <Modal isOpen={!!inititator} close={() => {}}>
      <p>Die Sitzung wurde von {inititator.name} beendet.</p>
      <p>Die folgenden Aufnahmen werden jetzt auf dem Server gespeichert</p>
      <p><strong>Bitte lass das Fenster geöffnet, bis der Upload abgeschlossen ist</strong></p>
      <ul>
        {localRecordings.map(lr => (
          <li key={lr.name}>
            <FlexRow center gap='.5em'>
              <div style={{width: '1em'}}>
                {lr.started ? (
                  <>
                    {lr.ready ? <MdDone /> : ( lr.failed ? <MdOutlineSmsFailed /> : <Spinner size='1em' />)}
                  </>
                ) : <span />}
              </div>
            { lr.name}
            </FlexRow>
          </li>
        ))}
      </ul>
      <p>Andere Benutzer</p>
      <ul>
        {remoteUploads.map(rr => (
          <li key={rr.name}><FlexRow center gap='.5em'><div style={{width: '1em'}}>{rr.ready ? <MdDone /> : <Spinner size='1em' />}</div>{rr.name}</FlexRow></li>

        ))}
      </ul>
    </Modal>
  )
}