import { useRecordingSession } from '@/modules/backend/hooks/use-recording-session';
import { DateDuration } from '@/modules/global/components/date-duration';
import { Participant } from '@nimey/podcast-global-entity';
import { PropsWithChildren, createContext, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type CtxType = ReturnType<typeof useRecordingSession>;
// @ts-ignore
export const RecordingSessionContext = createContext<CtxType>();

function withCtx<P extends {}>(comp: React.ComponentType<P & CtxType>) {
  return (props: P) => {
    const Component = comp;
    const ctx = useContext(RecordingSessionContext);
    return (<Component {...props} {...ctx} />)
  }
}

export const IsOpen = withCtx(({recordingSession, children}: PropsWithChildren<CtxType>) => (!recordingSession?.finishedAt ? <>{children}</> : <></>));
export const IsFinished = withCtx(({recordingSession, children}: PropsWithChildren<CtxType>) => (recordingSession?.finishedAt ? <>{children}</> : <></>));
export const Debug = withCtx(({recordingSession}: CtxType) => <pre>{JSON.stringify(recordingSession, null, 2)}</pre>)
export const Title = withCtx(({recordingSession}: CtxType) => <>{recordingSession?.title}</>)
export const Link = withCtx(({recordingSession, children, className}: PropsWithChildren<CtxType & {className?: string}>) => (
  <RouterLink to={`/manager/${recordingSession?.orgId}/recording/${recordingSession?.id}`} className={className}>{children}</RouterLink>
))

export enum StartDateFormat {
  LOCALE_STRING = 'localeString',
  DURATION = 'duration',
};
export const StartDate = withCtx(({recordingSession, format}: CtxType & { format: StartDateFormat }) => {
  if(!recordingSession?.startDate) return <></>;

  const sessionDate = recordingSession.finishedAt || recordingSession.startDate;

  if (format === StartDateFormat.LOCALE_STRING) {
    return <>{(new Date(sessionDate)).toLocaleString()}</>
  }
  return <>{recordingSession.finishedAt ? 'beendet ' : ''}<DateDuration fromTimestamp={sessionDate} /></>
})
export const Participants = withCtx(({recordingSession, row: Row}: CtxType & {row: React.ComponentType<{participant: Participant}>}) => (
  <>{(recordingSession?.participants || []).map(p => <Row key={p.identifier} participant={p}  />)}</>
))



export const Provider = (props: PropsWithChildren<{orgId: string, sessionId: string}>) => {
  const { orgId, sessionId, children } = props;
  const ctxValue = useRecordingSession(orgId, sessionId);

  return <RecordingSessionContext.Provider value={ctxValue}>{children}</RecordingSessionContext.Provider>;
}