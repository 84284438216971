import { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { AudioRecorderContext } from '../context/audio-recorder-context';
import { IdbFileInput } from '../util/input/idb-file';
import { IdbContext } from '@/modules/idb-file-storage/context/idb-context';
import { RecordingInputContext } from '../context/recording-input-context';
import { useExternalState } from '../hooks/use-external-state';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import { IdbStorage } from '@/modules/idb-file-storage/util/idb-storage';

export type RecordingInputProviderProps = {
  name: string;
}

export const Name = () => {
  const ctx = useContext(RecordingInputContext);

  return <>{ctx.input?.getName()}</>
}

export const Play = () => {
  const ctx = useContext(RecordingInputContext);

  return <button onClick={() => ctx.input?.play()}>PLAY</button>
}

export const Toggle = () => {
  const ctx = useContext(RecordingInputContext);
  const isPlaying = useExternalState(ctx.input!.isPlaying);
  if (isPlaying) return <button onClick={() => ctx.input?.pause()}><MdPause /></button>
  return <button onClick={() => ctx.input?.play()}><MdPlayArrow /></button>
}

export const Progress = () => {
  const ctx = useContext(RecordingInputContext);
  const duration = useExternalState(ctx.input!.length);
  const pos = useExternalState(ctx.input!.position);

  return <input type='range' min={0} step={0.1} max={duration} value={pos} onChange={(e) => {
    ctx.input?.setPosition(parseFloat(e.target.value))
  }} />
}

export const CurrentPosition = () => {
  const ctx = useContext(RecordingInputContext);
  const pos = useExternalState(ctx.input!.position);

  const seconds = Math.floor((pos) % 60),
  minutes = Math.floor((pos / (60)) % 60),
  hours = Math.floor((pos / (60 * 60)) % 24);

  const parts = [];
  if(hours) parts.push(hours.toString().padStart(2, '0'));
  if(minutes) parts.push(minutes.toString().padStart(2, '0'));
  if(seconds) parts.push(seconds.toString().padStart(2, '0'));

  if(parts.length === 0) parts.push('00')

  return <>{parts.join(':')}</>
}

export const Duration = () => {
  const ctx = useContext(RecordingInputContext);
  const duration = useExternalState(ctx.input!.length);

  const seconds = Math.floor((duration) % 60),
  minutes = Math.floor((duration / (60)) % 60),
  hours = Math.floor((duration / (60 * 60)) % 24);

  const parts = [];
  if(hours) parts.push(hours.toString().padStart(2, '0'));
  if(minutes) parts.push(minutes.toString().padStart(2, '0'));
  if(seconds) parts.push(seconds.toString().padStart(2, '0'));

  if(parts.length === 0) parts.push('00')


  return <>{parts.join(':')}</>
}

export const Stop = () => {
  const ctx = useContext(RecordingInputContext);

  return <button onClick={() => ctx.input?.stop()}>STOP</button>
}

export const Download = () => {
  const ctx = useContext(RecordingInputContext);

  return <button onClick={() => ctx.input?.download()}>DOWNLOAD</button>
}

export const Visualizer = (props: {width: number, height: number}) => {
  const ctx = useContext(RecordingInputContext);
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    setTimeout(() => {
      if(ctx?.input && canvasRef.current) {
        ctx.input.connectCanvas(canvasRef.current)
      }
    }, 1000);
  }, [])

  return <canvas ref={canvasRef} width={props.width} height={props.height} />
}

class IdbFileProvider {
  protected instances: Record<string, IdbFileInput> = {};
  getIdbFile(name: string, ctx: AudioContext, idb: IdbStorage) {
    const key = [name].join('--');
    if(!this.instances[key]) this.instances[key] = new IdbFileInput(name, ctx, idb);

    return this.instances[key];
  }
}

const idbFileProvider = new IdbFileProvider();

export const Provider = (props: PropsWithChildren<RecordingInputProviderProps>) => {
  const ctx = useContext(AudioRecorderContext);
  const idb = useContext(IdbContext);
  const recordingInput = idbFileProvider.getIdbFile(props.name, ctx.recorder!.getContext(), idb)
  useEffect(() => {
    ctx.recorder!.addInput(recordingInput)
    return () => {
      ctx.recorder!.removeInput(recordingInput)
    }
  }, [])
  return (
    <RecordingInputContext.Provider value={{input: recordingInput}}>
      {props.children}
    </RecordingInputContext.Provider>
  );
}