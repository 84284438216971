import { withCurrentUserId } from '@/modules/backend/hoc/with-current-userid';
import { useUser } from '@/modules/backend/hooks/use-user';
import { useContext, useEffect, useRef, useState } from 'react';
import { Gutter } from '@/modules/layout/components/gutter';
import { useParams } from 'react-router-dom';
import { useRecordingSession } from '@/modules/backend/hooks/use-recording-session';
import { WakeLock } from '@/modules/audio-recorder/components/wake-lock';
import { AudioRecorderProvider } from '@/modules/audio-recorder/components/audio-recorder-provider';
import { ConnectionState, EndSession, Reconnect, RequestAttention, RtcSignalingProvider, ServerTime, StartRecording, StopRecording } from '@/modules/audio-recorder/components/rtc-signaling-provider';
import { RtcConnection } from '@/modules/audio-recorder/util/rtc/connection';
import Idb from '@/modules/idb-file-storage/components';
import { RtcPeerRenderer } from './renderer/rtc-peer';
import { FlexRow } from '@/modules/layout/components/grid/flex-row';
import { InputWatcher } from './helper/input-watcher';
import * as DeviceInput from '@/modules/audio-recorder/components/device-input'
import * as DeviceOutput from '@/modules/audio-recorder/components/device-output'
import { InputDeviceRenderer } from './renderer/input-renderer';
import { OutputWatcher } from './helper/output-watcher';
import { OutputDeviceRenderer } from './renderer/output-renderer';
import { BaseInput } from '@/modules/audio-recorder/util/input/base';
import { BaseOutput } from '@/modules/audio-recorder/util/output/base';
import {  DefaultConnections } from './helper/connection';
import { DefaultConnectionType, DefaultSelection } from './renderer/default-selection';
import { RecordingManager } from './helper/recording-manager';

import styles from './recording.module.scss';
import { IdbFileManager } from './helper/idb-file-manager';
import { AudioRecorderContext } from '@/modules/audio-recorder/context/audio-recorder-context';
import { useExternalState } from '@/modules/audio-recorder/hooks/use-external-state';
import { SessionController } from '@/modules/audio-recorder/components/session-controller';
import { useOrganization } from '@/modules/backend/hooks/use-organization';
import { DateDuration } from '@/modules/global/components/date-duration';
import { DownloadSessionMedia } from './download';
import { RecordingEntitiesContext } from '@/modules/audio-recorder/context/recording-entities-context';
import { IsRecordingUser, RecordingEntitiesProvider } from '@/modules/audio-recorder/components/recording-entities-provider';
import { RECORDING_SERVER_URL } from '@/modules/backend/config/recording';
import { MdCompareArrows } from 'react-icons/md';
import { Debug, DebugClickCount } from './helper/debug';

export type ConnectionType = [BaseInput, BaseOutput];
export type RecordingType = {
  name: string,
  inputSelection: Array<{type: 'rtc' | 'device', selection?: string}>
  parts: Array<string>,
}
export const RecordingSessionViewerWithEntities = () => {
  const {user, recordingSession, org} = useContext(RecordingEntitiesContext);
  const session = recordingSession;
  
  const userId = useRef(`user-${user.id}-${Math.round(Math.random() * 100)}`)
  const [ peers, setPeers ] = useState<Array<RtcConnection>>([])
  const [ inputs, setInputs] = useState<Array<DeviceInput.DeviceInputProviderProps>>([]);
  const [ outputs, setOutputs] = useState<Array<DeviceOutput.DeviceOutputProviderProps>>([]);
  const [ defaultConnections, setDefaultConnections ] = useState<DefaultConnectionType>({mic: '', speaker: ''});
  const [recordings, setRecordings] = useState<Array<RecordingType>>([
    { name: `Aufnahme ${user.name}`, inputSelection: [{type: 'device'}], parts: ['0']},
    { name: 'Aufnahme alle', inputSelection: [{type: 'device'}, {type: 'rtc'}], parts: ['0']},
  ])

  const audioRecorderCtx = useContext(AudioRecorderContext);
  const connections = useExternalState(audioRecorderCtx.recorder!.connectionState);

  const onPeerConnection = (con: RtcConnection) => {
    setPeers(p => [...p.filter(pe => pe.peerUserId !== con.peerUserId), con])
  }

  const onPeerDisconnection = (con: RtcConnection) => {
    setPeers(p => [...p.filter(pe => pe.peerUserId !== con.peerUserId)])
  }

  const addConnection = (i: BaseInput, o: BaseOutput) => {
    audioRecorderCtx.recorder?.connect(i, o);
  };
  const removeConnection = (i: BaseInput, o: BaseOutput) => {
    audioRecorderCtx.recorder?.disconnect(i, o);
  }

  useEffect(() => {
    const onEnd = () => {
      alert('recording end!!!')
      setRecordings(rec => rec.map(r => ({...r, parts: [...r.parts, r.parts.length.toString()]})))
    }
    
    return () => {
      window.removeEventListener('pod-record-end', onEnd)
    }
  }, [])

  return (
    <Gutter>
      <h1 style={{marginTop: '1.5em'}}><DebugClickCount>{session.title}</DebugClickCount></h1>

        <WakeLock />
        <Idb.Provider dbName='nimey-pod-recordings' prefix={`${session.id}/`}>
          <RtcSignalingProvider
            channelId={session.id}
            token='123'
            userId={userId.current}
            userName={user.name}
            url={RECORDING_SERVER_URL}
            onPeerConnection={onPeerConnection}
            onPeerDisconnection={onPeerDisconnection}
          >
            <SessionController org={org} recordingSession={session} />
            <div className={styles.actionBar}>
              <RequestAttention />
              <button onClick={() => window.dispatchEvent(new CustomEvent('show-default-selection'))}><MdCompareArrows /></button>
              <IsRecordingUser>
                <div className={styles.ruler} />
                <StartRecording />
                <StopRecording />
              </IsRecordingUser>

              <div className={styles.ruler} />
              <ConnectionState />
              <Reconnect />
              <IsRecordingUser>
                <EndSession />
              </IsRecordingUser>
              <Debug>
                <ServerTime />
              </Debug>
            </div>

            <RecordingManager {...{recordings, connections, defaultConnections, addConnection, removeConnection}} />
            <hr />
            <FlexRow center style={{flexWrap: 'wrap', justifyContent: 'center'}} gap='1em'>
              {peers.map(peer => <RtcPeerRenderer key={peer.peerUserId} {...{peer, connections, addConnection, removeConnection}} />)}
            </FlexRow>

            <hr />
            <div className={styles.inOutWrapper}>
              <InputWatcher {...{inputs, setInputs}} />
              <FlexRow style={{flexDirection: 'column', width: '100%'}} gap='1em'>
                {inputs.map(input => (
                  <InputDeviceRenderer key={input.device.deviceId} {...input}
                    connections={connections}
                    {...{addConnection, removeConnection}}
                  />
                ))}
              </FlexRow>

              <OutputWatcher {...{outputs, setOutputs}} />
              <FlexRow style={{flexDirection: 'column', width: '100%'}} gap='1em'>
                {outputs.map(output => <OutputDeviceRenderer key={output.device.deviceId} {...output} />)}
              </FlexRow>
            </div>
            <hr />
            <IdbFileManager {...{connections, defaultConnections, addConnection, removeConnection}} />



            <hr />

            <DefaultSelection {...{defaultConnections, setDefaultConnections}} />
            <DefaultConnections {...{defaultConnections, connections, addConnection, removeConnection}} />
            {/* <Preview {...{connections, addConnection, removeConnection}} /> */}
          </RtcSignalingProvider>
        </Idb.Provider>
    </Gutter>
  );
}


export const RecordingSessionViewer = withCurrentUserId((props: {userId: string}) => {
  const { orgId, sessionId } = useParams();
  const userHandler = useUser(props.userId)
  const orgHandler = useOrganization(orgId);
  const { recordingSession } = useRecordingSession(orgId, sessionId);

  const { user } = userHandler;
  const { organization: org } = orgHandler;

  useEffect(() => {
    userHandler.load();
  }, [props.userId]);

  useEffect(() => {
    orgHandler.load();
  }, [orgId]);

  if(!user || !org || !recordingSession) return <></>;

  if(recordingSession.finishedAt) {
    return (
      <Gutter>
        <h1>{recordingSession.title}</h1>
        <p>Diese Sitzung wurde <DateDuration fromTimestamp={recordingSession.finishedAt} /> beendet. Eine Teilnahme an dieser Sitzung ist nicht mehr möglich.</p>
        <DownloadSessionMedia {...{org, session: recordingSession}} />
      </Gutter>
    )
  }

  return (
    <RecordingEntitiesProvider state={{
      org, recordingSession, user, isGuest: false
    }}>
      <AudioRecorderProvider sessionId={recordingSession.id}>
        <RecordingSessionViewerWithEntities/>
      </AudioRecorderProvider>
    </RecordingEntitiesProvider>
  )
})